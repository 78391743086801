import React from 'react';
import { Button, Empty, message, Modal, Progress, Tooltip } from 'antd';
import { ReportModule } from 'types';
import { useApolloClient } from '@apollo/react-hooks';
import removeNull from 'utils/removeNull';
import { useInterval } from 'react-use';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import { useReportsTimezone } from 'contexts/ReportsTimezone';
import { useNextParam } from 'hooks/useIsNext';
import { get } from 'lodash';

type DownloadCsvModalNextProps = {
  visible: boolean;
  onClose: () => void;
  module: ReportModule;
  filter: Record<string, any>;
  allowedGenerateCSV?: boolean;
  allowedDownloadCSV?: boolean;
  permissionErrorMsg?: string;
  reportName?: string;
};

type QueryResponseType = {
  status: 'GENERATING' | 'GENERATED' | 'FAILED';
  report: {
    uri: string;
  };
  processedRows: number;
  totalRows: number;
};

const DownloadCsvModalNext: React.FC<DownloadCsvModalNextProps> = ({
  visible,
  onClose,
  module,
  filter,
  allowedDownloadCSV,
  allowedGenerateCSV,
  permissionErrorMsg,
  reportName,
}) => {
  const [reportDownloadLink, setReportDownloadLink] = React.useState('');
  const [intervalRunning, setIntervalRunning] = React.useState(false);
  const [reportGenerationStarted, setReportGenerationStarted] = React.useState(
    false
  );

  const [generatePrevState, setGeneratePrevState] = React.useState({
    isPrevReport: false,
    generating: true,
    isFailedReport: false,
    downloadReady: false,
    prevReportDownloadLink: '',
    rows: {
      processRows: 0,
      totalRows: 0,
    },
  });

  const handleGeneratePrevStateReset = () =>
    setGeneratePrevState({
      isPrevReport: false,
      isFailedReport: false,
      downloadReady: false,
      generating: false,
      prevReportDownloadLink: '',
      rows: {
        processRows: 0,
        totalRows: 0,
      },
    });

  const [rows, setRows] = React.useState({
    processedRows: 0,
    totalRows: 0,
  });

  const client = useApolloClient();

  const checkPrevGenerateStatus = async () => {
    try {
      const res = await client.query({
        query: module.query,
        fetchPolicy: 'network-only',
      });

      const resObject = Object.values(res.data)[0] as QueryResponseType;

      if (!resObject) {
        setGeneratePrevState((prev) => ({
          ...prev,
          generating: false,
        }));
        return;
      }

      if (resObject.status === 'GENERATING') {
        setGeneratePrevState((prev) => ({
          ...prev,
          isPrevReport: true,
        }));
      }

      setGeneratePrevState((prev) => ({
        ...prev,
        rows: {
          processRows: resObject.processedRows,
          totalRows: resObject.totalRows,
        },
      }));

      if (resObject.status === 'FAILED') {
        setGeneratePrevState((prev) => ({
          ...prev,
          isFailedReport: true,
          generating: false,
        }));
        message.error('Error in generating report. Please try again');
        return;
      }

      if (resObject.status === 'GENERATED') {
        setGeneratePrevState((prev) => ({
          ...prev,
          generating: false,
          downloadReady: true,
          prevReportDownloadLink: resObject.report.uri,
        }));
      }
    } catch (error) {
      if (error) {
        console.log({ error });
        handleGeneratePrevStateReset();
      }
    }
  };

  const timezone = useReportsTimezone()?.currentTimezone?.timezone;
  const isNext = useNextParam();
  const onCallQuery = async () => {
    try {
      const queryResponse = await client.query({
        query: module.query,
        fetchPolicy: 'network-only',
      });

      const responseObject = Object.values(
        queryResponse.data
      )[0] as QueryResponseType;

      if (!responseObject) {
        setIntervalRunning(false);
        return;
      }

      setRows({
        processedRows: responseObject.processedRows,
        totalRows: responseObject.totalRows,
      });

      if (responseObject.status === 'FAILED') {
        setIntervalRunning(false);
        message.error('Error in generating report. Please try again');
        return;
      }

      if (responseObject.status === 'GENERATED') {
        setIntervalRunning(false);
        setReportDownloadLink(responseObject.report.uri);
      }
    } catch (error) {
      if (isNext) {
        setIntervalRunning(false);
        message.open({
          content: (
            <div>
              <p className="text-left">{`Error exporting ${reportName}`}</p>
              <p className="text-left">{`We encountered an error while
                trying to export the report. Please try again later or contact
                support if the issue persists.`}</p>
            </div>
          ),
          type: 'error',
          style: { color: 'red' },
          duration: 3,
        });
        onClose();
      }
    }
  };

  useInterval(
    async () => {
      await checkPrevGenerateStatus();
    },
    generatePrevState.generating ? 300 : null
  );

  useInterval(
    async () => {
      await onCallQuery();
    },
    intervalRunning ? 500 : null
  );
  const { context } = useOperatorHeader();

  const onGenerateReport = async () => {
    setReportGenerationStarted(true);
    try {
      const response = await client.mutate({
        mutation: module.mutation,
        variables: {
          input: {
            filter: removeNull(filter),
            timezone,
          },
        },
        context,
      });

      const mutationSuccess = Object.values(response.data || {})[0];
      if (!mutationSuccess && isNext) {
        message.open({
          content: (
            <div>
              <p className="text-left">{`Error exporting ${reportName}`}</p>
              <p className="text-left">{`We encountered an error while
                trying to export the report. Please try again later or contact
                support if the issue persists.`}</p>
            </div>
          ),
          type: 'error',
          style: { color: 'red' },
          duration: 3,
        });
        setIntervalRunning(false);
        onClose();
        return;
      }
      if (!mutationSuccess) {
        message.error('Error generating report. Please try again');
        setIntervalRunning(false);
      }

      await onCallQuery();

      setIntervalRunning(true);
    } catch (error) {
      const err = get(error.graphQLErrors[0], 'original');

      message.error(err.message);
      setReportGenerationStarted(false);

      if (isNext) {
        message.open({
          content: (
            <div>
              <p className="text-left">{`Error exporting ${reportName}`}</p>
              <p className="text-left">{`We encountered an error while
                trying to export the report. Please try again later or contact
                support if the issue persists.`}</p>
            </div>
          ),
          type: 'error',
          style: { color: 'red' },
          duration: 3,
        });
        setIntervalRunning(false);
        onClose();
      }
    }
  };

  const getPercentage = () =>
    Number(((rows.processedRows / rows.totalRows) * 100).toFixed());

  return (
    <Modal
      destroyOnClose
      visible={visible}
      onCancel={onClose}
      footer={[<Button onClick={onClose}>Close</Button>]}
    >
      {!reportGenerationStarted && !generatePrevState.isPrevReport && (
        <div>
          <Empty description="No Data" />
          <div className="d-flex justify-content-center mt-3">
            <Tooltip
              title={
                !allowedDownloadCSV || !allowedGenerateCSV
                  ? permissionErrorMsg
                  : ''
              }
            >
              <Button
                disabled={
                  !allowedDownloadCSV ||
                  !allowedGenerateCSV ||
                  generatePrevState.generating
                }
                type="primary"
                onClick={onGenerateReport}
              >
                Generate New
              </Button>
            </Tooltip>
          </div>
        </div>
      )}

      {reportGenerationStarted && !generatePrevState.isPrevReport && (
        <div>
          <div className="d-flex mt-3 justify-content-center">
            <Progress type="circle" percent={getPercentage()} />
          </div>
          <div className="text-center mt-3">
            Processing: {rows.processedRows}/{rows.totalRows}
          </div>

          {reportDownloadLink && (
            <div className="mt-5 text-center">
              <Button href={reportDownloadLink} type="primary">
                Download
              </Button>
            </div>
          )}
        </div>
      )}

      {generatePrevState.isPrevReport && !reportGenerationStarted && (
        <div>
          <div className="d-flex mt-3 justify-content-center">
            <Progress
              type="circle"
              percent={Number(
                (
                  (generatePrevState.rows.processRows /
                    generatePrevState.rows.totalRows) *
                  100
                ).toFixed()
              )}
            />
          </div>
          <div className="text-center mt-3">
            Processing: {generatePrevState.rows.processRows}/
            {generatePrevState.rows.totalRows}
          </div>

          {generatePrevState.prevReportDownloadLink && (
            <div className="mt-5 text-center">
              <Button
                href={generatePrevState.prevReportDownloadLink}
                type="primary"
              >
                Download
              </Button>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

export default DownloadCsvModalNext;
