import React, { createContext, useCallback } from 'react';
import { GraphQLError } from 'graphql';
import { message } from 'antd';
import { capitalize, template, startCase, includes } from 'lodash';
import translations from 'translations';

const errorCodesIgnoreTranslation = [
  'INVALID_DEPOSIT_LIMIT_INPUT_ERROR',
  'PENDING_DEPOSIT_LIMIT_INCREASE_EXISTS',
  'INVALID_OPERATION',
  'INVALID_FILE_FORMAT',
  'DEPOSIT_LIMIT',
  'CAPPED_MAX_DEPOSIT_LIMIT_ERROR',
  'DEPOSIT_BLOCKED_ERROR',
  'INVALID_USERNAME_OR_EMAIL',
  'MEMBER_DEPOSIT_LIMIT_UNCONFIRMABLE',
  'PLATFORM_ID_NOT_FOUND',
  'INVALID_SESSION',
];

type GqlError = {
  code: string;
  id: string;
  message: string;
  service: string;
};

type Error = GraphQLError &
  GqlError & {
    original: GqlError & {
      name: string;
      stack: string;
      required: string[];
    };
  };

export type ApiErrorHandlerFunc = (
  graphQLErrors: readonly Error[] | undefined
) => void;

export const ApiErrorHandlerContext = createContext<{
  handleApiError: ApiErrorHandlerFunc;
}>({
  handleApiError: () => {},
});

const errorIsIgnored = (err: any) => {
  // Add ignoredPaths and messages here.
  const ignoredPaths = ['deletePromoCategory'];
  const ignoredMessages = ['Promo does not exist.'];
  const ignoredErrorCode = [
    'WITHDRAWAL_CLOSED_LOOP_ERROR',
    'MEMBER_BET_RECORD_REPORT_GENERATING',
  ];

  return (
    (ignoredPaths.includes(err?.path?.[0]) &&
      ignoredMessages.includes(err?.message)) ||
    ignoredErrorCode.includes(err?.original?.code) ||
    includes(err?.original?.message, ignoredErrorCode[0])
  );
};

const ApiErrorHandler: React.FC = ({ children }) => {
  const handleApiError = useCallback(
    (graphQLErrors: readonly Error[] | undefined = []) => {
      const [primaryError] = graphQLErrors;
      const { code: primaryErrorCode } = primaryError;

      // if primaryError is empty, return nothings

      if (primaryError?.original?.code === 'REQUIRED_PERMISSION_ERROR') {
        const [
          module,
          page,
          action,
        ] = primaryError?.original?.required[0].split(':');
        message.error(
          `Need permission in [${startCase(capitalize(module))}]: ${startCase(
            capitalize(page)
          )} -> ${startCase(capitalize(action))}`
        );
        return;
      }

      // if primaryError is APQ error, return nothing
      if (primaryError.extensions?.code === 'PERSISTED_QUERY_NOT_FOUND') return;

      // if primary error is TIMEOUT, perform check on the error `path`
      if (['TIMEOUT', 'INVALID_INPUT'].includes(primaryErrorCode)) {
        switch (primaryError.path?.join('.')) {
          case 'member.vendorBalance':
            return;
          default:
        }
      }

      // pattern for to match for RESOURCE_NOT_FOUND error code. Currently, it supports deposit and withdrawal. New error codes will be added based on what is recorded on sentry
      const regexContext = /(deposit|withdrawal|memberLoyalty)$/gi;

      const { original: secondaryError } = primaryError;
      const [primaryErrorPath] = primaryError.path ?? [];
      const { code: secondaryErrorCode } = secondaryError;
      const matchRegex = (primaryErrorPath ?? '')
        .toString()
        .match(regexContext);
      const context = matchRegex ? matchRegex[0] : '';

      // default the translation id from primaryErrorCode
      let translationId = primaryErrorCode;

      let translationValues = {};

      // generate translation values based from the error code and set the proper translation id based on the secondaryErrorCode
      if (primaryErrorCode === 'SERVER_ERROR') {
        switch (secondaryErrorCode) {
          case 'RESOURCE_NOT_FOUND':
            translationId = 'RESOURCE_NOT_FOUND';
            translationValues = {
              context: capitalize(context),
            };
            break;

          case 'CANNOT_USE_OLD_WITHDRAWAL_PASSWORD':
            translationId = 'CANNOT_USE_OLD_WITHDRAWAL_PASSWORD';
            break;
          default:
            translationId = secondaryErrorCode;
        }
        // set the translationId from secondaryErrorCode for 'INVALID_REQUEST' primary code
      } else if (primaryErrorCode === 'INVALID_REQUEST') {
        // This is to show the error message straight from the error message, if the primaryy and secondary code are the same
        if (secondaryErrorCode === 'INVALID_REQUEST') {
          message.error({
            content: (
              <span data-testid="error">
                {primaryError.message} [Error Code - ${primaryError.id}]
              </span>
            ),
          });
          return;
        }

        translationId = secondaryErrorCode;
      }

      const formatMessage = (id: string, values: Record<string, any> = {}) => {
        const locale = localStorage.getItem('locale') || 'en';
        let value = translations[locale][id] || translations.en[id];
        if (!value) {
          value =
            translations[locale].SERVER_ERROR || translations.en.SERVER_ERROR;
        }
        const translate = template(value, {
          escape: /{([\S]+)}/g,
        });
        return translate(values);
      };

      if (translationId === 'INVALID_INPUT') {
        if (primaryError?.original?.code === 'CUSTOM_FILTER_EXISTS') {
          message.error({
            content: <span data-testid="error">{primaryError?.message}</span>,
          });
          return;
        }
      }

      // If you have a situation where you want to just render the error.message sent by the backend
      // Situation: 1 code, multiple error message
      if (errorCodesIgnoreTranslation.find((x) => translationId === x)) {
        message.error({
          content: (
            <span data-testid="error">
              {secondaryError.message} [Error Code - ${secondaryError.id}]
            </span>
          ),
        });
        return;
      }

      if (!errorIsIgnored(primaryError)) {
        message.error({
          content: (
            <span data-testid="error">
              {`${formatMessage(translationId, translationValues)} ${
                primaryError.id ? `[Error Code - ${primaryError.id}]` : ''
              }`}
            </span>
          ),
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return (
    <ApiErrorHandlerContext.Provider
      value={{
        handleApiError,
      }}
    >
      {children}
    </ApiErrorHandlerContext.Provider>
  );
};

export default ApiErrorHandler;
