import React from 'react';
import { Tooltip } from 'antd';
import Circle from 'components/Circle';
import useTranslate from 'utils/useTranslate';
import { getStatusColor } from 'utils/depositWithdrawalStatus';
import messages from 'messages';
import { Withdrawal } from 'types/graphqlTypes';

interface Props {
  withdrawalDetails: Withdrawal;
}
const MemberSerialCode = ({ withdrawalDetails }: Props) => {
  const translate = useTranslate();

  const { status } = withdrawalDetails || {};

  return (
    <>
      <Tooltip
        placement="top"
        title={translate(messages[`${status}` as keyof typeof messages])}
      >
        <div>
          <Circle size={10} color={getStatusColor(status)} filled />
        </div>
      </Tooltip>
    </>
  );
};
export default MemberSerialCode;
