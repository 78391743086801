import {
  CheckCircleFilled,
  CloseCircleFilled,
  CopyOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import {
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Input,
  message,
  Row,
  Select,
  Slider,
  Typography,
} from 'antd';
import { ALink } from 'components/ALink/ALink';
import CustomSwitch from 'components/CustomSwitch';
import { ErrorHandler } from 'components/ErrorHandler';
import PlayerFileLink from 'components/PlayerFileLink';
import { SendMessage } from 'components/SendMessage/SendMessage';
import { DATE_FORMAT, DATE_TIME_FORMAT } from 'constants/date';
import { FeatureFlags } from 'constants/featureFlags';
import preferredLanguages from 'constants/preferredLanguages';
import { TabId } from 'constants/route';
import { UPDATE_MEMBER } from 'graphql/mutations/member.mutation';
import { useConfig } from 'hooks/useConfig';
import useIsNext from 'hooks/useIsNext';
import { capitalize, isNull, map, omitBy, startCase } from 'lodash';
import messages from 'messages';
import moment from 'moment';
import HexopayCards from 'pages/components/NewMemberDetail/components/MemberDetailContent/components/MemberInfo/components/HexopayCards';
import MemberCurrency from 'pages/components/NewMemberDetail/components/MemberDetailContent/components/MemberInfo/components/MemberCurrency';
import MemberLevelButton from 'pages/components/NewMemberDetail/components/MemberDetailContent/components/MemberInfo/components/MemberLevelButton/MemberLevelButton';
import { MemberStatusBadge } from 'pages/components/NewMemberDetail/components/MemberDetailContent/components/MemberInfo/utils';
import { useNewMemberDetailPermissions } from 'pages/components/NewMemberDetail/constants';
import { useMember } from 'pages/components/NewMemberDetail/memberContext';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAccount } from 'store/accountState';
import { useScreenTabV2 } from 'store/screenTabState';
import styled from 'styled-components';
import {
  Admin,
  MemberTitle,
  MutationUpdateMemberArgs,
  Operator,
} from 'types/graphqlTypes';
import addressFormat from 'utils/addressFormat';
import copyToClipboard from 'utils/copyToClipboard';
import { formatDate } from 'utils/dateUtils';
import esGet from 'utils/esGet';
import isRow from 'utils/isRow';
import { statusTransform } from 'utils/memberStatusTransform';
import useTranslate from 'utils/useTranslate';
import useMemberData from '../../../utils/useMemberData';
import AvailablePaymentMethods from './components/AvailablePaymentMethods';
import LastLoginIP from './components/LastLoginIP';
import MemberTags from './components/MemberTags';
import { Messages } from './components/Messages/Messages';
import RegistrationIP from './components/RegistrationIP';
// import VipProgTabs from './components/VipProgTabs';
import { ModifiedCollapse, StyledCardFlex, Wrapper } from './styles';

const { Panel } = Collapse;

const StyledTitle = styled.span`
  font-weight: 500 !important;
  font-size: 12px !important;
`;

const CustomMemberLevelButton: any = MemberLevelButton;

const keysToTransform = [
  'DOB',
  'FIRST_DEPOSIT_DATE',
  'LAST_DEPOSIT_DATE',
  'LAST_LOGIN_DATE',
  'LAST_PLAYED_DATE',
  'REGISTRATION_DATE',
];

const transformData = (key: string, value: string) => {
  const foundKey = keysToTransform.find((e) => e === key);

  if (foundKey) {
    // hardcoded solution as the dates is already formatted
    // would be good if this dates are in ISO format so we can use our util `formatDate`
    // tried to use the formatDate util but it moves the date by 1 day advance.
    const dateSlashFormatted = value.includes('/');
    const [month, day, year] = value.split(dateSlashFormatted ? '/' : '-');
    return `${day}/${month}/${year}`;
  }
  return value;
};

function MemberInfo({ permissions }: { permissions: Record<string, any> }) {
  const {
    ALLOWED_AGENT_AFFILIATE,
    ALLOWED_MEMBER_INFORMATION,
    // ALLOWED_VIP_TIER_PROGRESS,
    ALLOWED_MEMBER_LABEL,
    ALLOWED_USER_PROFILE,
    ALLOWED_LOGIN_DETAILS,
    ALLOWED_CONTACT_DETAILS,
    ALLOWED_REGISTRATION_INFORMATION,
    ALLOWED_MESSAGES,
  } = permissions;
  const { addTab } = useScreenTabV2();
  const { member } = useMember() as Record<string, any>;
  const euRequirements = useIsNext(FeatureFlags.euRequirements);

  const { ALLOWED_PLAYER_PROFILE } = useNewMemberDetailPermissions();

  const {
    localMemberData,
    totalMessages,
    fetchMemberData,
    loading: localMemberDataLoading,
  } = useMemberData(member.id);

  const [isEditPhone, setIsEditPhone] = useState(false);
  const [isEditTitle, setIsEditTitle] = useState(false);
  const [isEditPreferredLang, setIsEditPreferredLang] = useState(false);
  const [phoneValue, setPhoneValue] = useState('');

  const [isSkrillDisabled, setIsSkrillDisabled] = useState(false);
  const [isNetellerDisabled, setIsNetellerDisabled] = useState(false);
  const [isSkrillClosedLoopDisabled, setIsSkrillClosedLoopDisabled] = useState(
    false
  );
  const [
    isNetellerClosedLoopDisabled,
    setIsNetellerClosedLoopDisabled,
  ] = useState(false);

  const [titleValue, setTitleValue] = useState<MemberTitle | null | undefined>(
    null
  );
  const [preferredLanguage, setPreferredLanguage] = useState<
    string | null | undefined
  >(null);

  const [mockGeoFenceRestriction, setMockGeoFence] = useState(false);
  const forceProfileValidation = useIsNext('forceProfileValidation');
  const [updateMember] = useMutation<boolean, MutationUpdateMemberArgs>(
    UPDATE_MEMBER
  );
  const { account } = useAccount();
  const company = esGet((account?.account as Admin | Operator)?.company, '-');
  const handleClick = (ref: any) => addTab(ref);

  const translate = useTranslate();

  const { websiteLink } = useConfig();

  const realNameTabRef = {
    id: TabId.MEMBER_MANAGEMENT,
    state: {
      filter: { realName: { in: [member.realName] }, partial: null },
    },
  };

  useEffect(() => {
    if (member) fetchMemberData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member]);

  const {
    lastLoginDateTime,
    mobileNumber,
    email,
    dateOfBirth,
    registrationDateTime,
    totalReadMessagesCount,
    brandId,
    linkedPlatforms,
    address,
    countryCode,
    platformId,
    title,
    phoneNumber,
    currency,
    acceptedTermsAndConditions,
    acceptedPrivacyPolicy,
    skrillDisabled,
    netellerDisabled,
    skrillClosedLoopDisabled,
    netellerClosedLoopDisabled,
    initialEmail,
    btagsNext,
    affiliateId,
    preferredLanguage: initialPreferredLang,
  } = localMemberData;

  const filteredBtags = map(omitBy(btagsNext, isNull), (value, key) => ({
    [key]: value,
  }));

  useEffect(() => {
    setTitleValue(title);
    setPreferredLanguage(initialPreferredLang);
    setPhoneValue(phoneNumber);
    setIsSkrillDisabled(skrillDisabled);
    setIsNetellerDisabled(netellerDisabled);
    setIsSkrillClosedLoopDisabled(skrillClosedLoopDisabled);
    setIsNetellerClosedLoopDisabled(netellerClosedLoopDisabled);
  }, [
    netellerClosedLoopDisabled,
    netellerDisabled,
    phoneNumber,
    skrillClosedLoopDisabled,
    skrillDisabled,
    title,
    initialPreferredLang,
  ]);

  const birthdayTabRef = {
    id: TabId.MEMBER_MANAGEMENT,
    state: {
      filter: {
        dateOfBirth: {
          gte: dateOfBirth,
          lte: dateOfBirth,
        },
        partial: null,
      },
    },
  };

  const marks = {
    0: {},

    50: {},

    100: {},
  };

  // This is to hide the unused features in Member 360
  const TempDiv = ({ children }: { children: any }) => (
    <div className="d-none">{children}</div>
  );

  const onClickRegistrationDateTime = () => {
    addTab({
      id: 'member-management',
      state: {
        filter: {
          registrationDateTime: {
            gte: registrationDateTime,
            lte: registrationDateTime,
          },
        },
      },
    });
  };

  const redirectByLastLoginDateTime = () => {
    addTab({
      id: 'member-management',
      state: {
        filter: {
          lastLoginDateTime: { gte: lastLoginDateTime, lte: lastLoginDateTime },
        },
      },
    });
  };
  const redirectByMobileNumber = () => {
    addTab({
      id: 'member-management',
      state: {
        filter: {
          mobileNumber: {
            in: [mobileNumber],
          },
        },
      },
    });
  };

  const redirectByEmail = () => {
    addTab({
      id: 'member-management',
      state: {
        filter: {
          email: {
            in: [email],
          },
        },
      },
    });
  };

  const onUpdateTitle = () => {
    updateMember({
      variables: {
        id: member.id,
        input: {
          title: titleValue,
        },
      },
    }).then(() => {
      fetchMemberData();
      setIsEditTitle(false);
    });
  };

  const onUpdatePreferredLang = async () => {
    await updateMember({
      variables: {
        id: member.id,
        input: {
          preferredLanguage: preferredLanguage!,
        },
      },
    }).then(() => {
      fetchMemberData();
      setIsEditPreferredLang(false);
    });
  };

  const onUpdatePhoneNumber = () => {
    updateMember({
      variables: {
        id: member.id,
        input: {
          phoneNumber: phoneValue,
        },
      },
    }).then(() => {
      fetchMemberData();
      setIsEditPhone(false);
    });
  };

  const onTogglePaymentMethodState = (
    paymentMethodState:
      | { skrillDisabled: boolean }
      | { netellerDisabled: boolean }
  ) => {
    updateMember({
      variables: {
        id: member.id,
        input: paymentMethodState,
      },
    }).then(() => {
      fetchMemberData();
    });
  };

  const onUpdateCurrency = (localCurrency: string, cb: Function) => {
    if (!localCurrency) {
      message.warning('Please select a currency');
      return;
    }
    updateMember({
      variables: {
        id: member.id,
        input: {
          currency: localCurrency,
        },
      },
    }).then(() => {
      fetchMemberData();
      cb();
    });
  };

  const {
    building,
    street,
    province,
    postCode,
    premise,
    address: address2,
    country,
  } = address || {};

  const showAffiliateId = isRow;

  return (
    <Wrapper>
      <Card
        bordered={false}
        title={
          <Typography.Text className="head-typography fw-500">
            <FormattedMessage
              id="member.member-details.text"
              defaultMessage="Member Details"
            />
          </Typography.Text>
        }
        extra={
          <MemberStatusBadge status={localMemberData.status}>
            {
              statusTransform[
                localMemberData.status as keyof typeof statusTransform
              ]
            }
          </MemberStatusBadge>
        }
      >
        {forceProfileValidation && localMemberData?.forceVerification && (
          <div
            style={{ background: '#fffbe6', border: '1px solid #ffe58f' }}
            className="p-2"
          >
            <div className="d-flex align-items-center">
              <ExclamationCircleOutlined
                className="mr-2"
                style={{ fontSize: '24px', color: '#faad14' }}
              />
              <p className="fs-13 mb-0">
                <FormattedMessage
                  id="THIS_MEMBER_IS_PENDING_FOR_VALIDATION"
                  defaultMessage="This member is pending for validation"
                />
              </p>
            </div>

            <div className="mt-2 d-flex justify-content-space-between">
              <Button
                type="primary"
                className="fs-11"
                icon={<CopyOutlined />}
                onClick={() => {
                  copyToClipboard(localMemberData?.forceVerificationURL);
                }}
              >
                <FormattedMessage
                  id="VALIDATION_LINK"
                  defaultMessage="Validation Link"
                />
              </Button>
              <Button
                className="fs-11"
                onClick={() => {
                  updateMember({
                    variables: {
                      id: member.id,
                      input: {
                        forceVerification: false,
                      },
                    },
                  }).then(() => {
                    fetchMemberData();
                  });
                }}
              >
                <FormattedMessage
                  id="TURN_OFF_VALIDATION"
                  defaultMessage="Turn Off Validation"
                />
              </Button>
            </div>
          </div>
        )}
        {/* Temporary hide this section */}
        {ALLOWED_AGENT_AFFILIATE && false && (
          <>
            <StyledCardFlex>
              <StyledTitle style={{ color: '#000000D9' }}>
                <FormattedMessage
                  id="member.agent-affiliate.text"
                  defaultMessage="Agent Affiliate"
                />
              </StyledTitle>
              <Typography.Text>
                <ALink>
                  <FormattedMessage
                    id="member.affiliate-report.text"
                    defaultMessage="Affiliate Report"
                  />
                </ALink>
              </Typography.Text>
            </StyledCardFlex>

            <Slider max={100} min={0} step={25} marks={marks} />
            <Divider />
          </>
        )}
        {ALLOWED_MEMBER_INFORMATION && (
          <ModifiedCollapse>
            <Panel
              key="1"
              header={
                <StyledTitle>
                  {translate(messages.MEMBER_INFORMATION)}
                </StyledTitle>
              }
            >
              <StyledCardFlex style={{ marginBottom: '9px' }}>
                <Typography.Text>
                  <FormattedMessage
                    id="member.member-marker.text"
                    defaultMessage="Member Marker"
                  />
                </Typography.Text>
                <Typography.Text>
                  <ALink>
                    <ErrorHandler>
                      <CustomMemberLevelButton
                        isHoverable
                        maxChar={10}
                        memberId={member.id}
                        isMemberDetail
                        memberLevelInfo={{
                          loading: localMemberDataLoading,
                          name: localMemberData?.memberLevel?.name,
                          color: localMemberData?.memberLevel?.color,
                        }}
                      />
                    </ErrorHandler>
                  </ALink>
                </Typography.Text>
              </StyledCardFlex>
              <StyledCardFlex style={{ marginBottom: '9px' }}>
                <Typography.Text>Brand Name</Typography.Text>
                <Typography.Text>{company}</Typography.Text>
              </StyledCardFlex>
            </Panel>
          </ModifiedCollapse>
        )}
        {ALLOWED_PLAYER_PROFILE && (
          <ModifiedCollapse>
            <Panel key="1" header={<StyledTitle>Player Profile</StyledTitle>}>
              <PlayerFileLink memberId={member.id} />
            </Panel>
          </ModifiedCollapse>
        )}
        {/** commented this block as of the moment to hide it on the screen */}
        {/* {ALLOWED_VIP_TIER_PROGRESS && (
          <ModifiedCollapse>
            <Panel
              key="1"
              header={<StyledTitle>{translate(messages.VIP)}</StyledTitle>}
            >
              <VipProgTabs />
            </Panel>
          </ModifiedCollapse>
        )} */}
        {ALLOWED_MEMBER_LABEL && (
          <ModifiedCollapse>
            <Panel
              key="1"
              header={
                <StyledTitle>{translate(messages.MEMBER_LABEL)}</StyledTitle>
              }
            >
              <ErrorHandler>
                <MemberTags
                  memberId={member.id}
                  memberTags={localMemberData.tags}
                  isMemberDetail
                />
              </ErrorHandler>
            </Panel>
          </ModifiedCollapse>
        )}
        {/* add permissions when api is available */}
        {showAffiliateId && (
          <>
            <div
              style={{
                height: '35px',
              }}
              className="d-flex align-items-center justify-content-between"
            >
              <Typography.Text
                style={{
                  fontWeight: 500,
                }}
              >
                Affiliate ID
              </Typography.Text>
              <ErrorHandler>{affiliateId || '-'}</ErrorHandler>
            </div>
            <Divider className="m-0" />
          </>
        )}
        {ALLOWED_USER_PROFILE && (
          <ModifiedCollapse>
            <Panel
              key="1"
              header={
                <StyledTitle>{translate(messages.USER_PROFILE)}</StyledTitle>
              }
            >
              <StyledCardFlex>
                <Typography.Text>
                  <FormattedMessage id="TITLE" defaultMessage="Title" />
                </Typography.Text>
                <Typography.Text>
                  {isEditTitle && (
                    <div className="d-flex align-items-center">
                      <Select
                        className="mr-2"
                        style={{ width: '100px' }}
                        value={titleValue || undefined}
                        onChange={(e: MemberTitle) => setTitleValue(e)}
                      >
                        <Select.Option value="" disabled>
                          Select Title
                        </Select.Option>
                        <Select.Option value="MR">Mr</Select.Option>
                        <Select.Option value="MS">Ms</Select.Option>
                        <Select.Option value="MRS">Mrs</Select.Option>
                      </Select>

                      <CloseCircleFilled
                        onClick={() => setIsEditTitle(false)}
                        className="text-danger mr-2 cursor-pointer"
                      />
                      <CheckCircleFilled
                        onClick={onUpdateTitle}
                        className="text-success cursor-pointer"
                      />
                    </div>
                  )}
                  {!isEditTitle && (
                    <div className="d-flex align-items-center">
                      <Typography.Text className="mr-2">
                        {title || '-'}
                      </Typography.Text>
                      <EditOutlined
                        onClick={() => setIsEditTitle(true)}
                        className="text-info"
                      />
                    </div>
                  )}
                </Typography.Text>
              </StyledCardFlex>
              <StyledCardFlex>
                <Typography.Text>
                  <FormattedMessage
                    id="member.real-name.text"
                    defaultMessage="Real Name"
                  />
                </Typography.Text>
                <Typography.Text>
                  <ALink onClick={() => handleClick(realNameTabRef)}>
                    {localMemberData.realName || '-'}
                  </ALink>
                </Typography.Text>
              </StyledCardFlex>
              {euRequirements && (
                <StyledCardFlex>
                  <Typography.Text>
                    <FormattedMessage
                      id="FIRSTNAME"
                      defaultMessage="First Name"
                    />
                  </Typography.Text>
                  <Typography.Text>
                    {localMemberData?.firstName || '-'}
                  </Typography.Text>
                </StyledCardFlex>
              )}
              {euRequirements && (
                <StyledCardFlex>
                  <Typography.Text>
                    <FormattedMessage
                      id="LASTNAME"
                      defaultMessage="Last Name"
                    />
                  </Typography.Text>
                  <Typography.Text>
                    {localMemberData?.lastName || '-'}
                  </Typography.Text>
                </StyledCardFlex>
              )}
              <StyledCardFlex>
                <MemberCurrency
                  currency={currency}
                  onUpdateCurrency={onUpdateCurrency}
                />
              </StyledCardFlex>
              <StyledCardFlex>
                <Typography.Text>
                  <FormattedMessage
                    id="member.gender.text"
                    defaultMessage="Gender"
                  />
                </Typography.Text>
                <Typography.Text>
                  {localMemberData.gender
                    ? translate(
                        messages[
                          localMemberData.gender as keyof typeof messages
                        ]
                      )
                    : '-'}
                </Typography.Text>
              </StyledCardFlex>
              <StyledCardFlex>
                <Typography.Text>
                  <FormattedMessage
                    id="member.birth-date.text"
                    defaultMessage="Birth Date"
                  />
                </Typography.Text>

                <Typography.Text>
                  {dateOfBirth ? (
                    <ALink onClick={() => handleClick(birthdayTabRef)}>
                      {formatDate(dateOfBirth, DATE_FORMAT)}
                    </ALink>
                  ) : (
                    '-'
                  )}
                </Typography.Text>
              </StyledCardFlex>
              {euRequirements && (
                <StyledCardFlex>
                  <Typography.Text>
                    <FormattedMessage id="COUNTRY" defaultMessage="Country" />
                  </Typography.Text>
                  <Typography.Text>
                    {address?.country ? (
                      <ALink onClick={() => handleClick(birthdayTabRef)}>
                        {address?.country}
                      </ALink>
                    ) : (
                      '-'
                    )}
                  </Typography.Text>
                </StyledCardFlex>
              )}
              {euRequirements && (
                <StyledCardFlex>
                  <Typography.Text>
                    <FormattedMessage id="BRAND_ID" defaultMessage="Brand ID" />
                  </Typography.Text>
                  <Typography.Text>
                    <ALink onClick={() => handleClick(birthdayTabRef)}>
                      {brandId || '-'}
                    </ALink>
                  </Typography.Text>
                </StyledCardFlex>
              )}

              <StyledCardFlex>
                <Typography.Text>
                  <FormattedMessage id="ADDRESS" defaultMessage="Address" />
                </Typography.Text>
                <Typography.Text style={{ marginLeft: '50px' }}>
                  {address ? (
                    <span>
                      {addressFormat([
                        street!,
                        premise!,
                        postCode!,
                        building!,
                        province!,
                      ])}
                      {address2} {country}
                    </span>
                  ) : (
                    '-'
                  )}
                </Typography.Text>
              </StyledCardFlex>

              {/* apply permissions when available */}
              {isRow && (
                <StyledCardFlex>
                  <Typography.Text>Preferred Language</Typography.Text>
                  <Typography.Text>
                    {isEditPreferredLang && (
                      <div className="d-flex align-items-center">
                        <Select
                          className="mr-2"
                          style={{ width: '100px' }}
                          value={preferredLanguage || undefined}
                          onChange={(e) => setPreferredLanguage(e)}
                        >
                          {Object.entries(preferredLanguages).map(
                            ([value, label]) => (
                              <Select.Option value={value} key={value}>
                                {label}
                              </Select.Option>
                            )
                          )}
                        </Select>

                        <CloseCircleFilled
                          onClick={() => setIsEditPreferredLang(false)}
                          className="text-danger mr-2 cursor-pointer"
                        />
                        <CheckCircleFilled
                          onClick={onUpdatePreferredLang}
                          className="text-success cursor-pointer"
                        />
                      </div>
                    )}
                    {!isEditPreferredLang && (
                      <div className="d-flex align-items-center">
                        <Typography.Text className="mr-2">
                          {preferredLanguage
                            ? preferredLanguages[
                                preferredLanguage as keyof typeof preferredLanguages
                              ]
                            : '-'}
                        </Typography.Text>
                        <EditOutlined
                          onClick={() =>
                            setIsEditPreferredLang((prev) => !prev)
                          }
                          className="text-info"
                        />
                      </div>
                    )}
                  </Typography.Text>
                </StyledCardFlex>
              )}

              {filteredBtags?.length
                ? filteredBtags.map((btag) => {
                    const key = Object.keys(btag)[0];
                    if (key !== '__typename') {
                      return (
                        <StyledCardFlex>
                          <Typography.Text>{key}</Typography.Text>
                          <Typography.Text>{btag[key]}</Typography.Text>
                        </StyledCardFlex>
                      );
                    }
                    return '';
                  })
                : ''}
            </Panel>
          </ModifiedCollapse>
        )}
        {euRequirements && (
          <StyledCardFlex style={{ marginTop: 15 }}>
            <b>
              <Typography.Text>
                <FormattedMessage
                  id="VERIFICATION_STATUS"
                  defaultMessage="Verification Status"
                />
              </Typography.Text>
            </b>
            <Typography.Text
              className={member?.verified ? 'text-success' : 'text-danger'}
            >
              {member?.verified
                ? translate(messages.VERIFIED)
                : translate(messages.NOT_VERIFIED)}
            </Typography.Text>
          </StyledCardFlex>
        )}
        {ALLOWED_LOGIN_DETAILS && (
          <ModifiedCollapse>
            <Panel
              key="1"
              header={
                <StyledTitle>{translate(messages.LOGIN_DETAILS)}</StyledTitle>
              }
            >
              <StyledCardFlex>
                <LastLoginIP />
              </StyledCardFlex>
              <TempDiv>
                <StyledCardFlex>
                  <Typography.Text>
                    <FormattedMessage
                      id="member.geofencing-restriction.text"
                      defaultMessage="Geofencing Restriction"
                    />
                  </Typography.Text>
                  <div>
                    <CustomSwitch
                      value={mockGeoFenceRestriction}
                      onChange={(e: boolean) => setMockGeoFence(e)}
                      textHelper={null}
                      activeColor="#3ec988"
                      checkedChildren={
                        <FormattedMessage id="yes.text" defaultMessage="Yes" />
                      }
                      unCheckedChildren={
                        <FormattedMessage id="no.text" defaultMessage="No" />
                      }
                    />
                  </div>
                </StyledCardFlex>
              </TempDiv>
              <StyledCardFlex>
                <Typography.Text>
                  <FormattedMessage
                    id="member.last-login-date-time.text"
                    defaultMessage="Last Login Date & Time"
                  />
                </Typography.Text>
                <Typography.Text>
                  {lastLoginDateTime ? (
                    <ALink onClick={redirectByLastLoginDateTime}>
                      {moment(lastLoginDateTime).format(DATE_TIME_FORMAT)}
                    </ALink>
                  ) : (
                    '-'
                  )}
                </Typography.Text>
              </StyledCardFlex>
              <TempDiv>
                <StyledCardFlex>
                  <Typography.Text>
                    <FormattedMessage
                      id="member.last-login-fingerprint.text"
                      defaultMessage="Last Login Fingerprint"
                    />
                  </Typography.Text>
                  <Typography.Text>-</Typography.Text>
                </StyledCardFlex>
              </TempDiv>
              <TempDiv>
                <StyledCardFlex>
                  <Typography.Text>
                    <FormattedMessage
                      id="member.last-login-url.text"
                      defaultMessage="Last Login URL"
                    />
                  </Typography.Text>
                  <Typography.Text>-</Typography.Text>
                </StyledCardFlex>
              </TempDiv>
            </Panel>
          </ModifiedCollapse>
        )}
        {ALLOWED_CONTACT_DETAILS && (
          <ModifiedCollapse>
            <Panel
              key="1"
              header={
                <StyledTitle>{translate(messages.CONTACT_DETAILS)}</StyledTitle>
              }
            >
              <StyledCardFlex>
                <Typography.Text>
                  <FormattedMessage
                    id="MOBILE_NUMBER"
                    defaultMessage="Mobile Number"
                  />
                </Typography.Text>
                <Typography.Text>
                  {mobileNumber ? (
                    <ALink onClick={redirectByMobileNumber}>
                      {countryCode} {mobileNumber}
                    </ALink>
                  ) : (
                    '-'
                  )}
                </Typography.Text>
              </StyledCardFlex>
              <StyledCardFlex>
                <Typography.Text>
                  <FormattedMessage
                    id="member.email.text"
                    defaultMessage="Email"
                  />
                </Typography.Text>
                <Typography.Text>
                  {email ? (
                    <ALink onClick={redirectByEmail}>{email}</ALink>
                  ) : (
                    '-'
                  )}
                </Typography.Text>
              </StyledCardFlex>
              <StyledCardFlex>
                <Typography.Text>
                  <FormattedMessage
                    id="PHONE_NUMBER"
                    defaultMessage="Phone Number"
                  />
                </Typography.Text>
                {isEditPhone && (
                  <div className="d-flex align-items-center">
                    <Input
                      value={phoneValue}
                      type="number"
                      onChange={(e) => setPhoneValue(e.target.value)}
                      style={{ width: '150px' }}
                      className="mr-2"
                    />
                    <CloseCircleFilled
                      onClick={() => setIsEditPhone(false)}
                      className="text-danger mr-2 cursor-pointer"
                    />
                    <CheckCircleFilled
                      onClick={onUpdatePhoneNumber}
                      className="text-success cursor-pointer"
                    />
                  </div>
                )}
                {!isEditPhone && (
                  <div className="d-flex">
                    <Typography.Text className="mr-2">
                      {phoneNumber || '-'}
                    </Typography.Text>
                    <EditOutlined
                      onClick={() => setIsEditPhone(true)}
                      className="text-info"
                    />
                  </div>
                )}
              </StyledCardFlex>
            </Panel>
          </ModifiedCollapse>
        )}
        <ModifiedCollapse>
          <Panel
            key="1"
            header={<StyledTitle>Financial Information</StyledTitle>}
          >
            <HexopayCards memberId={member.id} />
            <AvailablePaymentMethods
              memberId={member.id}
              skrillDisabled={isSkrillDisabled}
              netellerDisabled={isNetellerDisabled}
              skrillClosedLoopDisabled={isSkrillClosedLoopDisabled}
              netellerClosedLoopDisabled={isNetellerClosedLoopDisabled}
              onTogglePaymentMethodState={onTogglePaymentMethodState}
              fetchMemberData={fetchMemberData}
              memberEmail={initialEmail}
            />
          </Panel>
        </ModifiedCollapse>
        {ALLOWED_REGISTRATION_INFORMATION && (
          <ModifiedCollapse>
            <Panel
              key="1"
              header={
                <StyledTitle>
                  {translate(messages.REGISTRATION_INFORMATION)}
                </StyledTitle>
              }
            >
              <StyledCardFlex>
                <RegistrationIP />
              </StyledCardFlex>

              <StyledCardFlex>
                <Typography.Text>
                  <FormattedMessage
                    id="member.registration-date-time.text"
                    defaultMessage="Registration Date & Time"
                  />
                </Typography.Text>
                <Typography.Text>
                  {registrationDateTime ? (
                    <ALink onClick={() => onClickRegistrationDateTime()}>
                      {moment(registrationDateTime).format(DATE_TIME_FORMAT)}
                    </ALink>
                  ) : (
                    '-'
                  )}
                </Typography.Text>
              </StyledCardFlex>
              <TempDiv>
                <StyledCardFlex>
                  <Typography.Text>
                    <FormattedMessage
                      id="member.registration-fingerprint.text"
                      defaultMessage="Registration Fingerprint"
                    />
                  </Typography.Text>
                  <Typography.Text>
                    <ALink>-</ALink>
                  </Typography.Text>
                </StyledCardFlex>
              </TempDiv>
            </Panel>
          </ModifiedCollapse>
        )}
        {ALLOWED_MESSAGES && (
          <div>
            <StyledCardFlex style={{ paddingTop: '6px' }}>
              <StyledTitle style={{ color: 'rgba(0,0,0,.85)' }}>
                <FormattedMessage
                  id="member.messages.text"
                  defaultMessage="Messages"
                />
              </StyledTitle>
              <SendMessage
                members={[member.id]}
                CustomButton={({ onClick }: { onClick: any }) => (
                  <ALink onClick={onClick} className="fs-12">
                    <FormattedMessage
                      id="SEND_MESSAGE"
                      defaultMessage="Send Message"
                    />
                  </ALink>
                )}
              />
            </StyledCardFlex>
            <div style={{ padding: '0px 1px 4px 0px' }}>
              <StyledCardFlex>
                <Typography.Text>
                  <FormattedMessage
                    id="member.no-of-messages.text"
                    defaultMessage="No. of Messages"
                  />
                </Typography.Text>
                <Typography.Text>
                  <Messages value={totalMessages} />
                </Typography.Text>
              </StyledCardFlex>

              <StyledCardFlex>
                <Typography.Text>
                  <FormattedMessage
                    id="member.read-messages.text"
                    defaultMessage="Read Messages"
                  />
                </Typography.Text>
                <Typography.Text>
                  <Messages value={totalReadMessagesCount} />
                </Typography.Text>
              </StyledCardFlex>
            </div>
          </div>
        )}
        {euRequirements && (
          <div style={{ paddingTop: '6px', borderTop: '1px solid #e8e8e8' }}>
            <Row>
              <Col span={12}>
                <StyledTitle style={{ color: 'rgba(0,0,0,.85)' }}>
                  <FormattedMessage
                    id="PLATFORM_ID"
                    defaultMessage="Platform ID"
                  />
                </StyledTitle>
              </Col>
              <Col span={12}>
                <div style={{ fontSize: '12px', textAlign: 'right' }}>
                  {platformId}
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={7}>
                <StyledTitle style={{ color: 'rgba(0,0,0,.85)' }}>
                  <div>
                    <FormattedMessage
                      id="BRAND_LINKAGES"
                      defaultMessage="Brand Linkages"
                    />
                  </div>
                </StyledTitle>
              </Col>
              <Col span={17}>
                {linkedPlatforms?.length > 0 ? (
                  <div style={{ fontSize: '12px', textAlign: 'right' }}>
                    <div key={linkedPlatforms[0]?.brandId}>
                      {linkedPlatforms[0]?.brandId} (
                      {linkedPlatforms[0]?.company})
                    </div>
                  </div>
                ) : (
                  company
                )}
              </Col>
            </Row>

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div style={{ fontSize: '12px', textAlign: 'right' }}>
                {linkedPlatforms?.length > 0 &&
                  linkedPlatforms
                    ?.slice(1)
                    ?.map((platform: { company: string; brandId: string }) => (
                      <div key={platform.brandId}>
                        {platform.brandId} ({platform.company})
                      </div>
                    ))}
              </div>
            </div>
          </div>
        )}
        <Divider />
        <ModifiedCollapse>
          <Panel key="1" header={<StyledTitle>Ver Acceptance</StyledTitle>}>
            <StyledCardFlex>
              <Typography.Text>
                <FormattedMessage
                  id="TERMS_AND_CONDITION"
                  defaultMessage="Terms & Condition"
                />
              </Typography.Text>
              {acceptedTermsAndConditions?.version && (
                <ALink
                  href={`${websiteLink}/terms-and-conditions/${acceptedTermsAndConditions?.version}`}
                  target="_blank"
                >
                  {acceptedTermsAndConditions?.version}
                </ALink>
              )}
              {!acceptedTermsAndConditions?.version && (
                <Typography.Text>-</Typography.Text>
              )}
            </StyledCardFlex>

            <StyledCardFlex>
              <Typography.Text>
                <FormattedMessage
                  id="PRIVACY_POLICY"
                  defaultMessage="Privacy Policy"
                />
              </Typography.Text>
              {acceptedPrivacyPolicy?.version && (
                <ALink
                  href={`${websiteLink}/privacy-policy/${acceptedPrivacyPolicy?.version}`}
                  target="_blank"
                >
                  {acceptedPrivacyPolicy?.version || '-'}
                </ALink>
              )}
              {!acceptedPrivacyPolicy?.version && (
                <Typography.Text>-</Typography.Text>
              )}
            </StyledCardFlex>
          </Panel>
        </ModifiedCollapse>
        {member.migrationDetails && (
          <ModifiedCollapse>
            <Panel
              key="1"
              header={<StyledTitle>Migration Details</StyledTitle>}
            >
              {Object.keys(member.migrationDetails).map((key) => (
                <StyledCardFlex>
                  <Typography.Text className="mr-3">
                    {capitalize(startCase(key))}
                  </Typography.Text>
                  <Typography.Text style={{ wordBreak: 'break-all' }}>
                    {transformData(key, member.migrationDetails[key])}
                  </Typography.Text>
                </StyledCardFlex>
              ))}
            </Panel>
          </ModifiedCollapse>
        )}
      </Card>
    </Wrapper>
  );
}

export default MemberInfo;
