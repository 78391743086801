export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** ObjectId custom scalar type */
  ObjectId: any;
  /** Cursor custom scalar type */
  Cursor: any;
  Date: any;
  /** Currency custom scalar type */
  Currency: any;
  /** Decimal custom scalar type */
  Decimal: any;
  /** Type for language codes */
  Language: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
  URL: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** A field whose value conforms to the standard internet email address format as specified in HTML Spec: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address. */
  EmailAddress: any;
  /** A field whose value exists in the standard IANA Time Zone Database: https://www.iana.org/time-zones */
  TimeZone: any;
};

export type BooleanFilterInput = {
  equal?: Maybe<Scalars['Boolean']>;
  notEqual?: Maybe<Scalars['Boolean']>;
};

export type Connection = {
  __typename?: 'Connection';
  totalCount: Scalars['Int'];
  edges: Array<Edge>;
  pageInfo: PageInfo;
};

export type CreateSystemJobInput = {
  id: Scalars['ObjectId'];
  type: SystemJobType;
  skinId?: Maybe<Scalars['Int']>;
  data?: Maybe<Scalars['JSON']>;
};

export type DateFilterInput = {
  equal?: Maybe<Scalars['Date']>;
  notEqual?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Scalars['Date']>>;
  notIn?: Maybe<Array<Scalars['Date']>>;
  lesserThan?: Maybe<Scalars['Date']>;
  lesserThanOrEqual?: Maybe<Scalars['Date']>;
  greaterThan?: Maybe<Scalars['Date']>;
  greaterThanOrEqual?: Maybe<Scalars['Date']>;
};

export type DateTimeFilterInput = {
  equal?: Maybe<Scalars['DateTime']>;
  notEqual?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lesserThan?: Maybe<Scalars['DateTime']>;
  lesserThanOrEqual?: Maybe<Scalars['DateTime']>;
  greaterThan?: Maybe<Scalars['DateTime']>;
  greaterThanOrEqual?: Maybe<Scalars['DateTime']>;
};

export type Edge = {
  __typename?: 'Edge';
  cursor: Scalars['Cursor'];
  node: Node;
};

export type EmailAddressFilterInput = {
  equal?: Maybe<Scalars['EmailAddress']>;
  notEqual?: Maybe<Scalars['EmailAddress']>;
  in?: Maybe<Array<Scalars['EmailAddress']>>;
  notIn?: Maybe<Array<Scalars['EmailAddress']>>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
};

export type EnrollMemberToPromoInput = {
  legacyMemberId: Scalars['String'];
  promo: Scalars['ObjectId'];
  bonusAmount: Scalars['Decimal'];
  ringFenceAmount: Scalars['Decimal'];
};

export type Error = {
  message: Scalars['String'];
};

export enum GameCategory {
  Casino = 'CASINO',
  Sports = 'SPORTS',
}

export type Member = Node & {
  __typename?: 'Member';
  id: Scalars['ObjectId'];
  username: Scalars['String'];
  legacyId: Scalars['String'];
  wallets: MemberWallets;
  hasPendingPromo: Scalars['Boolean'];
  activePromo?: Maybe<MemberPromo>;
  activePromoNext?: Maybe<Array<Maybe<MemberPromo>>>;
  promoHistory: Connection;
};

export type MemberWalletsArgs = {
  currency?: Maybe<Scalars['Currency']>;
};

export type MemberPromoHistoryArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<MemberPromoFilterInput>;
};

export type MemberPromo = Node & {
  __typename?: 'MemberPromo';
  id: Scalars['ObjectId'];
  promo: Promo;
  admin?: Maybe<Scalars['ObjectId']>;
  amount: Scalars['Decimal'];
  member: Member;
  turnoverTarget: Scalars['Decimal'];
  turnoverCounter: Scalars['Decimal'];
  bonus: Scalars['Decimal'];
  currency?: Maybe<Scalars['String']>;
  status: MemberPromoStatus;
  expiresAt?: Maybe<Scalars['Date']>;
  bonusWon?: Maybe<Scalars['Decimal']>;
  dateTimeCreated: Scalars['Date'];
  dateTimeResolved?: Maybe<Scalars['Date']>;
  walletMode: WalletMode;
  promoUsed?: Maybe<Scalars['Int']>;
};

export type MemberPromoFilterInput = {
  id?: Maybe<ObjectIdFilterInput>;
  status?: Maybe<MemberPromoStatusFilterInput>;
  expiresAt?: Maybe<DateFilterInput>;
  currency?: Maybe<PromoCurrencyFilterInput>;
};

export enum MemberPromoStatus {
  Active = 'ACTIVE',
  Complete = 'COMPLETE',
  Canceled = 'CANCELED',
  Expired = 'EXPIRED',
  Lost = 'LOST',
  Pending = 'PENDING',
}

export type MemberPromoStatusFilterInput = {
  equal?: Maybe<MemberPromoStatus>;
  notEqual?: Maybe<MemberPromoStatus>;
  in?: Maybe<Array<MemberPromoStatus>>;
  notIn?: Maybe<Array<MemberPromoStatus>>;
};

export type MemberWallets = {
  __typename?: 'MemberWallets';
  mainCash: Scalars['Decimal'];
  casinoCash: Scalars['Decimal'];
  sportsCash: Scalars['Decimal'];
  casinoBonus: Scalars['Decimal'];
  sportsBonus: Scalars['Decimal'];
};

export type Mutation = {
  __typename?: 'Mutation';
  enrollMemberToPromo: Scalars['Boolean'];
  completeActivePromo: Scalars['Boolean'];
  switchPromoToBonusMode: Scalars['Boolean'];
  cancelActivePromo: Scalars['Boolean'];
  createSystemJob: Scalars['Boolean'];
};

export type MutationEnrollMemberToPromoArgs = {
  input: EnrollMemberToPromoInput;
};

export type MutationCompleteActivePromoArgs = {
  id: Scalars['ObjectId'];
};

export type MutationSwitchPromoToBonusModeArgs = {
  id: Scalars['ObjectId'];
};

export type MutationCancelActivePromoArgs = {
  id: Scalars['ObjectId'];
};

export type MutationCreateSystemJobArgs = {
  input?: Maybe<CreateSystemJobInput>;
};

export type Node = {
  id: Scalars['ObjectId'];
};

export type ObjectIdFilterInput = {
  equal?: Maybe<Scalars['ObjectId']>;
  notEqual?: Maybe<Scalars['ObjectId']>;
  in?: Maybe<Array<Scalars['ObjectId']>>;
  notIn?: Maybe<Array<Scalars['ObjectId']>>;
};

export enum ObjectType {
  File = 'FILE',
}

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  endCursor?: Maybe<Scalars['Cursor']>;
};

export type Promo = Node & {
  __typename?: 'Promo';
  id: Scalars['ObjectId'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  amount: Scalars['Decimal'];
  type: PromoType;
  active: Scalars['Boolean'];
  allowedGameCodes?: Maybe<Array<Scalars['String']>>;
  dateTimeCreated: Scalars['DateTime'];
  minimumBonusBalance?: Maybe<Scalars['Decimal']>;
  minimumRealMoneyBalance?: Maybe<Scalars['Decimal']>;
  gameCategory?: Maybe<GameCategory>;
  minimumDepositAmount?: Maybe<Scalars['Decimal']>;
  bonusWon?: Maybe<Scalars['Decimal']>;
  rank?: Maybe<Scalars['Int']>;
  code: Scalars['String'];
  mainBannerImage?: Maybe<Scalars['URL']>;
  subBannerImage?: Maybe<Scalars['URL']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  maxUses: Scalars['Int'];
  currency?: Maybe<Scalars['Currency']>;
  promoUsed?: Maybe<Scalars['Decimal']>;
};

export type PromoNameArgs = {
  language?: Maybe<Scalars['Language']>;
};

export type PromoDescriptionArgs = {
  language?: Maybe<Scalars['Language']>;
};

export type PromoMainBannerImageArgs = {
  language?: Maybe<Scalars['Language']>;
};

export type PromoSubBannerImageArgs = {
  language?: Maybe<Scalars['Language']>;
};

export type PromoCurrencyFilterInput = {
  equal?: Maybe<Scalars['Currency']>;
  notEqual?: Maybe<Scalars['Currency']>;
  in?: Maybe<Array<Scalars['Currency']>>;
  notIn?: Maybe<Array<Scalars['Currency']>>;
};

export type PromoFilterInput = {
  type?: Maybe<PromoTypeFilterInput>;
  active?: Maybe<BooleanFilterInput>;
  currency?: Maybe<PromoCurrencyFilterInput>;
  isPublic?: Maybe<BooleanFilterInput>;
};

export enum PromoType {
  Login = 'LOGIN',
  Registration = 'REGISTRATION',
  Deposit = 'DEPOSIT',
}

export type PromoTypeFilterInput = {
  equal?: Maybe<PromoType>;
  notEqual?: Maybe<PromoType>;
  in?: Maybe<Array<PromoType>>;
  notIn?: Maybe<Array<PromoType>>;
};

export type Query = {
  __typename?: 'Query';
  node?: Maybe<Node>;
  memberPromoHistory: Connection;
  member?: Maybe<Member>;
  promos: Connection;
  availablePromo?: Maybe<Promo>;
};

export type QueryNodeArgs = {
  id?: Maybe<Scalars['ObjectId']>;
};

export type QueryMemberPromoHistoryArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<MemberPromoFilterInput>;
};

export type QueryMemberArgs = {
  id: Scalars['String'];
};

export type QueryPromosArgs = {
  filter?: Maybe<PromoFilterInput>;
};

export type QueryAvailablePromoArgs = {
  promoCode: Scalars['String'];
  currency?: Maybe<Scalars['String']>;
};

export enum Role {
  SuperAdmin = 'SUPER_ADMIN',
  Admin = 'ADMIN',
  Operator = 'OPERATOR',
  Member = 'MEMBER',
}

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type StringFilterInput = {
  equal?: Maybe<Scalars['String']>;
  notEqual?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
};

export type SystemJob = Node & {
  __typename?: 'SystemJob';
  id: Scalars['ObjectId'];
  type: SystemJobType;
  status: SystemJobType;
  data?: Maybe<Scalars['JSON']>;
  dateTimeCreated: Scalars['DateTime'];
};

export enum SystemJobType {
  SyncPromos = 'SYNC_PROMOS',
  UploadGames = 'UPLOAD_GAMES',
  ExpireMemberPromos = 'EXPIRE_MEMBER_PROMOS',
}

export type UnknownError = Error & {
  __typename?: 'UnknownError';
  message: Scalars['String'];
};

export enum WalletMode {
  Cash = 'CASH',
  Bonus = 'BONUS',
}
