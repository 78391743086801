import React, { useState } from 'react';
import { Button, Form, Input, Modal, Switch, Typography, message } from 'antd';
import { Config } from 'types/graphqlTypes';
import gql from 'graphql-tag';
import { useForm, Controller } from 'react-hook-form';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { UPDATE_CONFIG } from 'graphql/mutations/updateConfig.mutation';
import { useOperatorHeader } from 'utils/useOperatorHeader';

const PRE_DEPOSIT_CONFIG = gql`
  query PreDepositConfig {
    config {
      preDepositEnabled
      preDepositValues
    }
  }
`;

interface FormValues {
  preDepositEnabled: boolean;
  preDepositValues: number[];
}

const { Text } = Typography;

const defaultValues = [10, 20, 50];

const ManagePreDeposit = () => {
  const { context } = useOperatorHeader();
  const [visible, setVisible] = useState(false);
  const [preDepositEnabled, setPreDepositEnabled] = useState(true);

  const { control, reset, handleSubmit, setValue, watch } = useForm<FormValues>(
    {
      defaultValues: {
        preDepositEnabled: false,
        preDepositValues: defaultValues,
      },
    }
  );

  const [updatePreDepositConfig, { loading: mutationLoading }] = useMutation(
    UPDATE_CONFIG,
    {
      context,
    }
  );

  const [loadPreDepositConfig, { loading }] = useLazyQuery<{ config: Config }>(
    PRE_DEPOSIT_CONFIG,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      onCompleted: ({ config }) => {
        reset({
          preDepositEnabled: config.preDepositEnabled ?? false,
          preDepositValues: config?.preDepositValues ?? [0, 0, 0],
        });
        setPreDepositEnabled(config.preDepositEnabled ?? false);
      },
    }
  );

  const handleShow = () => {
    setVisible(true);
    loadPreDepositConfig();
  };

  const handleModalClose = () => {
    setVisible(false);
    reset();
  };

  const onSubmit = async (values: FormValues) => {
    try {
      await updatePreDepositConfig({
        variables: {
          input: {
            preDepositEnabled: values.preDepositEnabled,
            preDepositValues: values.preDepositValues
              .filter(Boolean)
              .map((value) => Number(value)),
          },
        },
      });
      message.success('Pre-deposit Configuration updated successfully');
    } catch (error) {
      message.error('Something went wrong, please try again.');
    } finally {
      handleModalClose();
    }
  };

  const onResetDefault = async () => {
    try {
      await updatePreDepositConfig({
        variables: {
          input: {
            preDepositEnabled: watch('preDepositEnabled'),
            preDepositValues: watch('preDepositValues')
              .filter(Boolean)
              .map((value) => Number(value)),
          },
        },
      });

      message.success('Pre-deposit Configuration updated successfully');
    } catch (error) {
      message.error('Something went wrong, please try again.');
    } finally {
      handleModalClose();
    }
  };

  const onResetDepositValues = async () => {
    defaultValues.map((value, idx) =>
      setValue(`preDepositValues.${idx}`, value)
    );
    await onResetDefault();
  };

  const preDepositValues = watch('preDepositValues');

  return (
    <div>
      <h3>Manage Pre-deposit Configuration</h3>
      <Button onClick={handleShow}>Configure Pre-deposit</Button>

      <Modal
        visible={visible}
        destroyOnClose
        title="Manage"
        closable
        onCancel={handleModalClose}
        centered
        okButtonProps={{ loading: loading || mutationLoading }}
        cancelButtonProps={{ loading: loading || mutationLoading }}
        onOk={handleSubmit(onSubmit)}
      >
        <div className="d-flex flex-column">
          <div className="d-flex w-50 justify-content-around mb-2">
            <Text strong>Enable Pre-Deposit: </Text>
            <Controller
              control={control}
              name="preDepositEnabled"
              render={({ value }) => (
                <Switch
                  onChange={(e) => {
                    setPreDepositEnabled(e);
                    setValue('preDepositEnabled', e);
                  }}
                  checked={value}
                  checkedChildren="On"
                  unCheckedChildren="Off"
                />
              )}
            />
          </div>
          <>
            <Text strong className="ml-5 mb-2 font-italic">
              Pre-deposit Options:
            </Text>
            <div className="d-flex  flex-column ml-5">
              {Array.from({ length: 3 }).map((_, index) => (
                <div key={index} className="d-flex align-items-center">
                  <Text strong className="ml-5 font-italic mr-2">
                    Option {index + 1}:
                  </Text>
                  <Form.Item>
                    <Controller
                      control={control}
                      name={`preDepositValues.${index}`}
                      render={({ value, onChange }) => (
                        <Input
                          value={value}
                          onChange={onChange}
                          placeholder="Enter an amount"
                          type="number"
                          min={0}
                          disabled={
                            (index > 0 && !preDepositValues[index - 1]) ||
                            !preDepositEnabled
                          }
                        />
                      )}
                    />
                  </Form.Item>
                </div>
              ))}
              <Button
                className="w-50 align-self-center"
                onClick={onResetDepositValues}
                loading={loading}
              >
                Reset Values to Default
              </Button>
            </div>
          </>
        </div>
      </Modal>
    </div>
  );
};

export default ManagePreDeposit;
