import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { LoadingOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Input,
  Popconfirm,
  // message,
  Row,
  Select,
  Spin,
  Switch,
} from 'antd';
import { StyledNumberInput } from 'components/Antd/StyledNumberInput/style';
import CountriesSelect from 'components/CountriesSelect';
import CountryCodesSelect from 'components/CountryCodesSelect';
import CustomSwitch from 'components/CustomSwitch';
import { MemberMarkerSelect } from 'components/MemberMarkerSelect/MemberMarkerSelect';
import MemberTagsSelect from 'components/MemberTagsSelect';
// import MultiVIPSelect from 'components/MultiVIPSelect';
import PostalCodeSelect from 'components/PostalCodeSelect';
import { DATE_FORMAT } from 'constants/date';
import { FeatureFlags } from 'constants/featureFlags';
import { MEMBER_MANAGEMENT } from 'constants/testIds';
import { useFormik } from 'formik';
// import { MemberLoyaltyLevel } from 'interfaces/user.interface';
import { useAvailableCurrencies } from 'hooks/useAvailableCurrencies';
import useIsNext from 'hooks/useIsNext';
import _, { set, uniqBy } from 'lodash';
import { Moment } from 'moment';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useEffectOnce } from 'react-use';
import { StyledForm } from 'styles/StyledForm';
import {
  CommunicationType,
  FormField as FormFieldType,
} from 'types/graphqlTypes';
import addressFormat from 'utils/addressFormat';
import isRow from 'utils/isRow';
import useTranslate from 'utils/useTranslate';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import {
  AddressInputWrapper,
  getFormattedValidationSchema,
  placeholderText,
} from './utils';

const { Item } = Form;
const { Password } = Input;
const { Option } = Select;

type FormField = FormFieldType & {
  field?: string;
};

type Config = {
  loqateIntegration?: boolean | null;
  memberFormFields?: FormField[];
  loadingConfig?: boolean;
  refetchConfig: () => void;
  checkDepositLimit?: boolean | null;
};

type Props = {
  initialValues: Record<string, any>;
  onSubmit: (e: any) => void;
  onClose: () => void;
  isSubmitting?: boolean;
  config: Config;
  isEdit?: boolean;
  setDeletedTags?: Function;
};

const plainOptionsNext = [
  {
    value: 'SMS',
    label: 'SMS',
  },
  {
    value: 'EMAIL',
    label: 'Email',
  },
  {
    value: 'EMAIL_CASINO',
    label: 'Casino',
  },
  {
    value: 'EMAIL_SPORTS',
    label: 'Sports',
  },
  {
    value: 'SMS_CASINO',
    label: 'Casino',
  },
  {
    value: 'SMS_SPORTS',
    label: 'Sports',
  },
];

const MemberForm = ({
  initialValues,
  onSubmit,
  onClose,
  isSubmitting,
  config,
  isEdit = false,
  setDeletedTags,
}: Props) => {
  const translate = useTranslate();
  const euRequirements = useIsNext(FeatureFlags.euRequirements);

  const {
    availableCurrencies,
    loadAvailableCurrencies,
  } = useAvailableCurrencies();

  const defaultCommunicationTypePreferences = Object.values(CommunicationType)
    .filter(
      (value) =>
        ![CommunicationType.Mail, CommunicationType.PhoneCall].includes(value)
    )
    .map((value) => value);

  const {
    memberFormFields,
    loqateIntegration,
    loadingConfig,
    refetchConfig,
    checkDepositLimit,
  } = config || {};

  const emailType = memberFormFields?.filter(
    (formField) => formField?.field === 'EMAIL'
  );

  const {
    street,
    premise,
    postCode,
    stateDistrict,
    province,
    building,
    country,
  } = initialValues?.address || {};

  const isLoqateAddress = Boolean(building || province);
  const [isAddressEdited, setIsAddressEdited] = useState(false);
  const [isPrefferedLanguageEdited, setIsPrefferedLanguageEdited] = useState(
    false
  );
  const initValues = {
    id: initialValues.id,
    username: initialValues.username,
    password: initialValues.password,
    withdrawalPassword: initialValues.withdrawalPassword,
    realName: initialValues.realName,
    email: initialValues.email,
    memberLevel: initialValues.memberLevel,
    dateOfBirth: initialValues.dateOfBirth,
    gender: initialValues.gender,
    mobileNumber: initialValues.mobileNumber,
    wechatId: initialValues.wechatId,
    qqId: initialValues.qqId,
    status: initialValues.status,
    tags: initialValues.tags,
    forceVerification: initialValues.forceVerification,
    firstName: initialValues.firstName,
    lastName: initialValues.lastName,
    countryCode: initialValues.countryCode,
    communicationTypePreferences:
      isEdit && Array.isArray(initialValues.communicationTypePreferences)
        ? initialValues.communicationTypePreferences
        : defaultCommunicationTypePreferences,
    address: {
      ...(initialValues?.address || null),
    },
    depositLimitFrequency: initialValues.depositLimitFrequency,
    depositLimit: initialValues.depositLimit,
    platformId: initialValues.platformId,
    currency: initialValues.currency,
    ...(isRow &&
      isEdit && {
        preferredLanguage: initialValues.preferredLanguage || 'EN',
      }),

    // To Be deleted and not submitted on the API
    // eslint-disable-next-line no-nested-ternary
    partialAddress: !isEdit
      ? ''
      : isLoqateAddress
      ? addressFormat([
          building,
          street,
          premise,
          postCode,
          stateDistrict,
          province,
        ])
      : street,
    city: isEdit ? premise : '',
    country: initialValues.country || country,
    address2: '',
  };

  const onChangePostalCode = (value: boolean) => setIsAddressEdited(value);

  const [editedAddress, setEditedAddress] = useState('');
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initValues,
    onSubmit: (values) => {
      if (editedAddress) {
        set(values, 'address.street', editedAddress);
        set(values, 'address.building', '');
        set(values, 'address.province', '');
      }
      // commented this block since VIP is hidden
      // if (memberLoyaltyValue?.length === 0) {
      //   message.error('Please select a VIP first');
      //   return;
      // }
      const copiedValues = _.cloneDeep(values);

      const {
        address,
        preferredLanguage: notEditedAddress,
        ...restValues
      } = values;
      const { preferredLanguage, ...restCopiedValues } = copiedValues;

      const newValue = !isAddressEdited
        ? {
            ...restValues,

            address: initValues.address,
            ...(isPrefferedLanguageEdited && {
              preferredLanguage: notEditedAddress,
            }),
          }
        : {
            ...restCopiedValues,
            ...(isPrefferedLanguageEdited && {
              preferredLanguage,
            }),
          };
      const valuesToSubmit = {
        ...newValue,
        // memberLoyaltyLevels: memberLoyaltyValue,
        username: values.username.toLowerCase(),
      };

      // setMemberLoyaltyValue([]);
      onSubmit(valuesToSubmit);
    },
    ...(emailType && {
      validationSchema: getFormattedValidationSchema(
        translate,
        emailType[0]?.required
      ),
    }),
  });

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    errors,
    touched,
    values,
    handleBlur,
  } = formik;

  const onChangePrefToggle = (checked: boolean, value: string) => {
    if (checked) {
      const val = defaultCommunicationTypePreferences.filter((v) =>
        v.startsWith(value)
      );

      setFieldValue(
        'communicationTypePreferences',
        uniqBy([...values.communicationTypePreferences, ...val], (item) => item)
      );
    } else {
      setFieldValue(
        'communicationTypePreferences',
        Array.isArray(values.communicationTypePreferences)
          ? values.communicationTypePreferences.filter(
              (val: string) => !val.startsWith(value)
            )
          : []
      );
    }
  };

  const onChangePrefCheckbox = (e: CheckboxChangeEvent) => {
    const { value, checked } = e.target;
    if (checked) {
      setFieldValue(
        'communicationTypePreferences',
        uniqBy([...values.communicationTypePreferences, value], (item) => item)
      );
    } else {
      setFieldValue(
        'communicationTypePreferences',
        Array.isArray(values.communicationTypePreferences)
          ? values.communicationTypePreferences.filter(
              (val: string) => val !== value
            )
          : []
      );
    }
  };

  useEffectOnce(() => {
    refetchConfig();
  });

  // const initialMemberLevels: string[] = initialValues?.memberLoyaltyLevels?.map(
  //   (memberLoyaltyLevel: MemberLoyaltyLevel) => memberLoyaltyLevel.id
  // );

  // React.useEffect(() => {
  //   if (isEdit) {
  //     setMemberLoyaltyValue(initialMemberLevels);
  //   }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [initialValues]);

  React.useEffect(() => {
    loadAvailableCurrencies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const LANGUAGES = [
    {
      value: 'EN',
      label: 'English',
    },
    {
      value: 'ZH',
      label: '简体中文',
    },
    {
      value: 'ES',
      label: 'Spanish',
    },
    {
      value: 'HI',
      label: 'Hindi',
    },
    {
      value: 'PT_BR',
      label: 'Portuguese',
    },
  ];

  return (
    <StyledForm onSubmit={handleSubmit} layout="horizontal">
      <Spin spinning={isSubmitting}>
        <div className="p-3" data-testid={isEdit ? 'edit-member-form' : ''}>
          <Row gutter={16}>
            <Col span={12}>
              <Item
                data-testid="status"
                label={
                  <FormattedMessage id="status.text" defaultMessage="Status" />
                }
                validateStatus={errors.status && touched.status ? 'error' : ''}
                help={touched.status ? errors.status : null}
              >
                <CustomSwitch
                  activeColor="#52c41a"
                  value={values.status === 'ENABLED'}
                  onChange={(e: boolean) => {
                    setFieldValue('status', e ? 'ENABLED' : 'DISABLED');
                  }}
                  textHelper={{
                    true: (
                      <FormattedMessage
                        id="active.text"
                        defaultMessage="Active"
                      />
                    ),
                    false: (
                      <FormattedMessage
                        id="inactive.text"
                        defaultMessage="Inactive"
                      />
                    ),
                  }}
                />
              </Item>
              <Item
                label={
                  <FormattedMessage
                    id="username.text"
                    defaultMessage="Username"
                  />
                }
                validateStatus={
                  errors.username && touched.username ? 'error' : ''
                }
                help={
                  touched.username ? (
                    <span data-testid={MEMBER_MANAGEMENT.usernameRequired}>
                      {errors.username}
                    </span>
                  ) : null
                }
              >
                {isEdit ? (
                  <Input disabled value={initialValues.username} />
                ) : (
                  <Input
                    onBlur={handleBlur}
                    aria-label="username"
                    name="username"
                    onChange={handleChange}
                    value={values.username?.toLowerCase() || ''}
                    placeholder={translate(
                      placeholderText['please-enter.text']
                    )}
                  />
                )}
              </Item>

              <Item
                label={
                  <FormattedMessage
                    id="real-name.text"
                    defaultMessage="Real Name"
                  />
                }
              >
                <Input
                  value={`${values.firstName || ''} ${values.lastName || ''}`}
                  disabled
                  placeholder={translate(placeholderText['please-enter.text'])}
                />
              </Item>

              {euRequirements && (
                <Item
                  label={
                    <FormattedMessage
                      id="first-name.text"
                      defaultMessage="First Name"
                    />
                  }
                  validateStatus={
                    errors.firstName && touched.firstName ? 'error' : ''
                  }
                  help={
                    touched.firstName ? (
                      <span data-testid={MEMBER_MANAGEMENT.firstName}>
                        {errors.firstName}
                      </span>
                    ) : null
                  }
                >
                  <Input
                    onBlur={handleBlur}
                    name="firstName"
                    onChange={handleChange}
                    value={values.firstName || ''}
                    placeholder={translate(
                      placeholderText['please-enter.text']
                    )}
                  />
                </Item>
              )}

              {euRequirements && (
                <Item
                  label={
                    <FormattedMessage
                      id="last-name.text"
                      defaultMessage="Last Name"
                    />
                  }
                  validateStatus={
                    errors.lastName && touched.lastName ? 'error' : ''
                  }
                  help={
                    touched.lastName ? (
                      <span data-testid={MEMBER_MANAGEMENT.lastName}>
                        {errors.lastName}
                      </span>
                    ) : null
                  }
                >
                  <Input
                    onBlur={handleBlur}
                    name="lastName"
                    onChange={handleChange}
                    value={values.lastName || ''}
                    placeholder={translate(
                      placeholderText['please-enter.text']
                    )}
                  />
                </Item>
              )}

              <Item
                label={
                  <FormattedMessage
                    id="member-marker.text"
                    defaultMessage="Member Marker"
                  />
                }
                validateStatus={
                  errors.memberLevel && touched.memberLevel ? 'error' : ''
                }
                help={touched.memberLevel ? errors.memberLevel : null}
              >
                <MemberMarkerSelect
                  aria-label="member-marker"
                  value={values.memberLevel || ''}
                  name="memberLevel"
                  onChange={setFieldValue}
                />
              </Item>

              {/* <MultiVIPSelect
                memberLevel={memberLoyaltyValue}
                onChange={(val: any) => setMemberLoyaltyValue(val)}
              />
              {memberLoyaltyValue?.length === 0 && (
                <small className="text-danger">Please select a VIP</small>
              )} */}

              <Item
                label={
                  <FormattedMessage
                    id="birthdate.text"
                    defaultMessage="Birth date"
                  />
                }
                validateStatus={
                  errors.dateOfBirth && touched.dateOfBirth ? 'error' : ''
                }
                help={touched.dateOfBirth ? errors.dateOfBirth : null}
              >
                <DatePicker
                  inputReadOnly
                  placeholder={DATE_FORMAT}
                  format={DATE_FORMAT}
                  style={{ marginTop: '4px', marginBottom: '6px' }}
                  value={values.dateOfBirth}
                  onChange={(value?: string | Moment | null) => {
                    setFieldValue('dateOfBirth', value);
                  }}
                />
              </Item>

              <Item
                label={
                  <FormattedMessage id="label.text" defaultMessage="Label" />
                }
              >
                <div
                  className="px-2"
                  style={{
                    border: '1px solid #d9d9d9',
                  }}
                >
                  <MemberTagsSelect
                    value={values.tags || []}
                    onChange={(e) => setFieldValue('tags', e)}
                    setDeletedTags={setDeletedTags}
                  />
                </div>
              </Item>

              {isEdit ? (
                <>
                  <Item label="Platform ID">
                    <Input
                      name="platformId"
                      onChange={handleChange}
                      value={values.platformId}
                      placeholder="Please Enter Platform ID"
                    />
                  </Item>
                  {isRow && (
                    <Item label="Preferred Language">
                      <Select
                        value={values.preferredLanguage}
                        placeholder="Select Language"
                        onChange={(e) => {
                          if (e !== initValues.preferredLanguage) {
                            setIsPrefferedLanguageEdited(true);
                          }
                          setFieldValue('preferredLanguage', e);
                        }}
                      >
                        {LANGUAGES?.map(({ value, label }, index) => (
                          <Select.Option key={index} value={value}>
                            {label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Item>
                  )}
                </>
              ) : (
                checkDepositLimit && (
                  <>
                    <Item
                      label="Deposit Limit"
                      validateStatus={
                        checkDepositLimit &&
                        errors.depositLimit &&
                        touched.depositLimit
                          ? 'error'
                          : ''
                      }
                      help={touched.depositLimit ? errors.depositLimit : null}
                    >
                      <div
                        style={{
                          display: 'grid',
                          gridTemplateColumns: 'repeat(2, 1fr)',
                          gap: '10px',
                        }}
                      >
                        <Select
                          value={values.depositLimitFrequency}
                          onChange={(e) =>
                            setFieldValue('depositLimitFrequency', e)
                          }
                        >
                          <Select.Option value="MONTHLY">Monthly</Select.Option>
                          <Select.Option value="WEEKLY">Weekly</Select.Option>
                          <Select.Option value="DAILY">Daily</Select.Option>
                        </Select>
                        <StyledNumberInput
                          onBlur={handleBlur}
                          type="number"
                          name="depositLimit"
                          value={values.depositLimit}
                          onChange={handleChange}
                          placeholder="Deposit Limit Amount"
                          onKeyDown={(e): void => {
                            const invalidChars = ['+', '-', '.', 'e'];
                            if (invalidChars.includes(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                    </Item>
                  </>
                )
              )}
            </Col>

            <Col span={12}>
              <Item
                data-testid="profile-validation"
                label={
                  <FormattedMessage
                    id="PROFILE_VALIDATION"
                    defaultMessage="Profile Validation"
                  />
                }
              >
                <CustomSwitch
                  activeColor="#52c41a"
                  value={values.forceVerification}
                  onChange={(e: boolean) => {
                    setFieldValue('forceVerification', e);
                  }}
                  textHelper={{
                    true: <FormattedMessage id="ON" defaultMessage="On" />,
                    false: <FormattedMessage id="Off" defaultMessage="Off" />,
                  }}
                />
              </Item>

              <Item
                label={
                  <FormattedMessage
                    id="password.text"
                    defaultMessage="Password"
                  />
                }
                validateStatus={
                  errors.password && touched.password ? 'error' : ''
                }
                help={
                  touched.password ? (
                    <span data-testid={MEMBER_MANAGEMENT.passwordRequired}>
                      {errors.password}
                    </span>
                  ) : null
                }
              >
                {isEdit ? (
                  <Input disabled value="********" />
                ) : (
                  <Password
                    aria-label="password"
                    name="password"
                    onChange={handleChange}
                    value={values.password || ''}
                    placeholder={translate(
                      placeholderText['please-enter.text']
                    )}
                  />
                )}
              </Item>
              <Item
                label={
                  <FormattedMessage id="gender.text" defaultMessage="Gender" />
                }
                validateStatus={errors.gender && touched.gender ? 'error' : ''}
                help={touched.gender ? errors.gender : null}
              >
                <Select
                  placeholder="Select Gender"
                  value={values.gender || undefined}
                  onChange={(e) => setFieldValue('gender', e)}
                >
                  <Option value="MALE">
                    <FormattedMessage id="male.text" defaultMessage="Male" />
                  </Option>
                  <Option value="FEMALE">
                    <FormattedMessage
                      id="female.text"
                      defaultMessage="Female"
                    />
                  </Option>
                </Select>
              </Item>

              <Item
                label={
                  <FormattedMessage id="email.text" defaultMessage="Email" />
                }
                validateStatus={errors.email && touched.email ? 'error' : ''}
                help={touched.email ? errors.email : null}
              >
                <Input
                  name="email"
                  type="email"
                  onChange={handleChange}
                  value={values.email || ''}
                  placeholder={translate(placeholderText['please-enter.text'])}
                />
              </Item>

              <Item
                label={
                  <FormattedMessage
                    id="mobile-phone.text"
                    defaultMessage="Mobile number"
                  />
                }
                validateStatus={
                  (errors.mobileNumber && touched.mobileNumber
                    ? 'error'
                    : '') ||
                  (errors.countryCode && touched.countryCode ? 'error' : '')
                }
                help={
                  (touched.mobileNumber ? errors.mobileNumber : null) ||
                  (touched.countryCode ? errors.countryCode : null)
                }
              >
                <div style={{ display: 'flex' }}>
                  <CountryCodesSelect
                    value={values.countryCode}
                    onSelected={(countryCode: string) =>
                      setFieldValue('countryCode', countryCode)
                    }
                  />
                  <StyledNumberInput
                    onBlur={handleBlur}
                    type="number"
                    name="mobileNumber"
                    value={values.mobileNumber || ''}
                    onChange={handleChange}
                    placeholder="Mobile Number"
                    onKeyDown={(e): void => {
                      const invalidChars = ['+', '-', '.', 'e'];
                      if (invalidChars.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
              </Item>

              {euRequirements && (
                <>
                  <Item
                    label={
                      <FormattedMessage id="COUNTRY" defaultMessage="Country" />
                    }
                  >
                    <CountriesSelect
                      isUkOnly={false}
                      value={values.country}
                      onChange={(e) => {
                        setFieldValue('country', e);
                        setFieldValue('address', {});
                        setFieldValue('partialAddress', '');
                      }}
                    />
                  </Item>

                  {loadingConfig ? (
                    <div className="d-flex justify-content-center p-3">
                      <Spin indicator={<LoadingOutlined />} />
                    </div>
                  ) : (
                    <AddressInputWrapper
                      isEdit={isEdit}
                      loqateIntegration={loqateIntegration}
                      values={values}
                      setFieldValue={setFieldValue}
                      onManualAddressChange={onChangePostalCode}
                    >
                      <Item
                        label={
                          <FormattedMessage
                            id="POSTAL_CODE"
                            defaultMessage="Postal Code"
                          />
                        }
                      >
                        <PostalCodeSelect
                          country={values.country}
                          value={{
                            label: values?.address?.postCode || undefined,
                            value: 'DUMMY_VALUE',
                          }}
                          onSelectAddress={(add) => {
                            setEditedAddress('');
                            onChangePostalCode(true);
                            setFieldValue(
                              'partialAddress',
                              addressFormat([
                                add.building!,
                                add.street!,
                                add.premise!,
                                add.postCode!,
                                add.stateDistrict!,
                                add.province!,
                              ])
                            );
                            setFieldValue('city', add.premise);
                            setFieldValue('address', add);
                          }}
                        />
                      </Item>
                      <Item
                        label={
                          <FormattedMessage
                            id="ADDRESS"
                            defaultMessage="Address"
                          />
                        }
                      >
                        <Input
                          disabled={!isEdit}
                          onBlur={handleBlur}
                          name="partialAddress"
                          value={values.partialAddress || ''}
                          onChange={(e) => {
                            setFieldValue('partialAddress', e.target.value);
                            setEditedAddress(e.target.value);
                          }}
                        />
                      </Item>
                      <Item label="Address 2">
                        <Input
                          name="address2"
                          value={
                            isEdit ? values.address.address : values.address2
                          }
                          onChange={handleChange}
                        />
                      </Item>
                      <Item
                        label={
                          <FormattedMessage id="CITY" defaultMessage="City" />
                        }
                      >
                        <Input
                          name="city"
                          value={isEdit ? values.address.premise : values.city}
                          disabled={!isEdit}
                          onChange={(e) =>
                            setFieldValue('address.premise', e.target.value)
                          }
                        />
                      </Item>
                    </AddressInputWrapper>
                  )}
                  {isRow && (
                    <Item label="Currency">
                      <Select
                        value={values.currency}
                        placeholder="Select Currency"
                        onChange={(e) => setFieldValue('currency', e)}
                        disabled={isEdit}
                      >
                        {availableCurrencies?.map((item, index) => (
                          <Select.Option key={index} value={item.code}>
                            {`${item.name} (${item.symbol})`}
                          </Select.Option>
                        ))}
                      </Select>
                    </Item>
                  )}
                  <Item
                    label={
                      <FormattedMessage
                        id="USER_COMMUNICATION_PREFERENCE"
                        defaultMessage="User Communication Preference"
                      />
                    }
                  >
                    <div className="communication-container mt-2">
                      <div className="communication-wrapper">
                        <p className="font-weight-bold">Email</p>
                        <Switch
                          checked={
                            Array.isArray(
                              values.communicationTypePreferences
                            ) &&
                            values.communicationTypePreferences.includes(
                              'EMAIL'
                            )
                          }
                          onChange={(checked: boolean) =>
                            onChangePrefToggle(checked, 'EMAIL')
                          }
                        />
                      </div>
                      {plainOptionsNext
                        .filter(
                          ({ value }) =>
                            !value.startsWith('SMS') && value !== 'EMAIL'
                        )
                        .map((v) => (
                          <div className="communication-wrapper" key={v.value}>
                            <p>{v.label}</p>
                            <Checkbox
                              checked={
                                Array.isArray(
                                  values.communicationTypePreferences
                                ) &&
                                values.communicationTypePreferences.includes(
                                  v.value
                                )
                              }
                              disabled={
                                Array.isArray(
                                  values.communicationTypePreferences
                                ) &&
                                !values.communicationTypePreferences.includes(
                                  'EMAIL'
                                )
                              }
                              value={v.value}
                              onChange={onChangePrefCheckbox}
                            />
                          </div>
                        ))}
                      <div className="communication-wrapper">
                        <p className="font-weight-bold">SMS</p>
                        <Switch
                          checked={
                            Array.isArray(
                              values.communicationTypePreferences
                            ) &&
                            values.communicationTypePreferences.includes('SMS')
                          }
                          onChange={(checked: boolean) =>
                            onChangePrefToggle(checked, 'SMS')
                          }
                        />
                      </div>
                      {plainOptionsNext
                        .filter(
                          ({ value }) =>
                            !value.startsWith('EMAIL') && value !== 'SMS'
                        )
                        .map((v) => (
                          <div className="communication-wrapper" key={v.value}>
                            <p>{v.label}</p>
                            <Checkbox
                              value={v.value}
                              checked={
                                Array.isArray(
                                  values.communicationTypePreferences
                                ) &&
                                values.communicationTypePreferences.includes(
                                  v.value
                                )
                              }
                              disabled={
                                Array.isArray(
                                  values.communicationTypePreferences
                                ) &&
                                !values.communicationTypePreferences.includes(
                                  'SMS'
                                )
                              }
                              onChange={onChangePrefCheckbox}
                            />
                          </div>
                        ))}
                    </div>
                  </Item>
                </>
              )}
            </Col>
          </Row>
        </div>
        <div className="bt-1 p-3 text-right">
          <Button className="mr-2" onClick={onClose}>
            <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
          </Button>
          {isEdit ? (
            <Popconfirm
              title="Are you sure to update this member?"
              onConfirm={() => handleSubmit()}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary">Save Changes</Button>
            </Popconfirm>
          ) : (
            <Button type="primary" htmlType="submit">
              <FormattedMessage id="confirm.text" defaultMessage="Confirm" />
            </Button>
          )}
        </div>
      </Spin>
    </StyledForm>
  );
};

export default MemberForm;
