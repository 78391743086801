import { FocusEvent } from 'react';

export function formatAmount(
  e: FocusEvent<HTMLInputElement>,
  callback?: (val: string) => void
) {
  const { value } = e.target;
  if (value) {
    const newValue = parseFloat(value).toFixed(2);
    callback?.(newValue);
  }
}
