import React from 'react';
import { Checkbox } from 'antd';
import Circle from 'components/Circle';
import { FilterProps } from 'interfaces/filters.interface';
import useTranslate from 'utils/useTranslate';
import isRow from 'utils/isRow';
import messages from './messages';

export const getColor = (status: string) => {
  let color = 'primary';

  switch (status) {
    case 'PROCESSING':
      color = '#1890ff';
      break;
    case 'APPROVED':
      color = '#52c41a';
      break;
    case 'PENDING':
      color = '#faad14';
      break;
    case 'REJECTED':
      color = '#AA4A44';
      break;
    case 'FAILED':
      color = '#EE4B2B';
      break;
    case 'EXPIRED':
      color = 'gray';
      break;
    case 'DRAFT':
      color = '#ADAE10';
      break;
    case 'CANCELLED':
      color = '#ff0000';
      break;
    case 'ON_HOLD':
      color = '#800080';
      break;
    case 'CANCELED':
      color = '#cccccc';
      break;
    default:
      color = 'primary';
      break;
  }

  return color;
};

const PaymentStatusFilter = ({
  value,
  onChange,
  excludedStatus,
}: FilterProps) => {
  const handleChange = (e: any) => {
    onChange(e);
  };

  let options = [
    {
      id: 'sidebar.status.internal.filter.pending.text',
      label: 'Pending',
      value: 'PENDING',
    },
    {
      id: 'sidebar.status.internal.filter.processing.text',
      label: 'Processing',
      value: 'PROCESSING',
    },
    {
      id: 'sidebar.status.internal.filter.approved.text',
      label: 'Approved',
      value: 'APPROVED',
    },
    {
      id: 'sidebar.status.internal.filter.onHold.text',
      label: 'On Hold',
      value: 'ON_HOLD',
    },
    {
      id: 'sidebar.status.internal.filter.rejected.text',
      label: 'Rejected',
      value: 'REJECTED',
    },
    {
      id: 'sidebar.status.internal.filter.failed.text',
      label: 'Failed',
      value: 'FAILED',
    },
    {
      id: 'sidebar.status.internal.filter.expired.text',
      label: 'Expired',
      value: 'EXPIRED',
    },
    {
      id: 'sidebar.status.internal.filter.draft.text',
      label: 'Draft',
      value: 'DRAFT',
    },
    {
      id: 'sidebar.status.internal.filter.cancelled.text',
      label: 'Cancelled',
      value: 'CANCELLED',
    },
  ];

  if (isRow) {
    options.push({
      id: 'sidebar.status.internal.filter.canceled.text',
      label: 'Canceled',
      value: 'CANCELED',
    });
  }

  if (excludedStatus?.length) {
    options = options.filter(
      (option) => !excludedStatus.includes(option.value)
    );
  }
  const translate = useTranslate();
  return (
    <div>
      <Checkbox.Group onChange={handleChange} value={value || []}>
        {options.map((item) => (
          <span key={item.value}>
            <Checkbox
              className="mt-1"
              value={item.value}
              data-testid={`internalDepositsStatus_${item.value}`}
            >
              <Circle size={8} color={getColor(item.value)} filled />
              {translate(messages[item.id as keyof typeof messages])}
            </Checkbox>
            <br />
          </span>
        ))}
      </Checkbox.Group>
    </div>
  );
};

export default PaymentStatusFilter;
