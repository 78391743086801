import { PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { Button, message } from 'antd';
import { CREATE_MEMBER } from 'graphql/mutations/member.mutation';
import { GET_MEMBERS } from 'graphql/queries/member.query';
import { useConfig } from 'hooks/useConfig';
import { isEmpty } from 'lodash';
import MemberForm from 'components/MemberForm';
import { useMemberManagement } from 'pages/components/NewMemberManagement/MemberManagementContext';
import React, { useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import removeNull from 'utils/removeNull';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import useTranslate from 'utils/useTranslate';
import { SharedStyledModal } from 'styles/SharedStyledModal';
import moment from 'moment';
import isRow from 'utils/isRow';

const messages = defineMessages({
  'new-member-created.text': {
    id: 'new-member-created.text',
    defaultMessage: 'New member created',
  },
});

const memberMapTitle = {
  MALE: 'MR',
  FEMALE: 'MS',
};

export const NewMember = ({
  refetchParams,
}: {
  refetchParams: Record<string, any>;
}) => {
  const [isShow, setShow] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleShow = () => setShow(!isShow);
  const [createMember] = useMutation(CREATE_MEMBER);
  const { refetchMemberManagement } = useMemberManagement();

  const translate = useTranslate();

  const {
    loqateIntegration,
    memberFormFields,
    loadingConfig,
    refetch: refetchConfig,
    checkDepositLimit,
  } = useConfig();

  const { context } = useOperatorHeader();

  const handleSubmit = async (input: Record<string, any>) => {
    setIsSubmitting(true);
    const newInput = {
      ...removeNull(input, true),
      tags: input.tags
        ? input.tags.map((item: Record<string, any>) => item.id)
        : undefined,
      address: removeNull(
        {
          ...(input.country && { country: input.country }),
          ...(input.address && { ...input.address }),
          address: input.address2,
        },
        true
      ),
      title: memberMapTitle[input.gender as keyof typeof memberMapTitle],
      depositLimit: input.depositLimit,
      mobileNumber: input.mobileNumber?.toString(),
      dateOfBirth: moment(input.dateOfBirth).format('YYYY-MM-DD'),
    };

    if (!isRow) delete newInput.currency;

    delete newInput.country;
    delete newInput.partialAddress;
    delete newInput.city;
    delete newInput.address2;

    if (isEmpty(newInput?.address)) {
      message.error('Postal code field is required');
      setIsSubmitting(false);
      return;
    }

    try {
      await createMember({
        variables: { input: newInput },
        refetchQueries: [
          {
            query: GET_MEMBERS,
            variables: refetchParams,
          },
        ],
        context,
      });
      refetchMemberManagement();
      message.success(
        <span data-testid="success">
          {translate(messages['new-member-created.text'])}
        </span>
      );
      handleShow();
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Button type="primary" onClick={handleShow} role="button">
        <PlusOutlined />
        <FormattedMessage id="new.text" defaultMessage="New" />
      </Button>

      {isShow && (
        <SharedStyledModal
          title={
            <FormattedMessage
              id="new-member.text"
              defaultMessage="New member"
            />
          }
          width={600}
          visible
          footer={false}
          onCancel={handleShow}
        >
          <MemberForm
            initialValues={{
              username: null,
              password: null,
              withdrawalPassword: null,
              realName: null,
              dateOfBirth: null,
              gender: null,
              email: null,
              mobileNumber: null,
              wechatId: null,
              qqId: null,
              status: 'ENABLED',
              memberLevel: null,
              tags: null,
              forceVerification: null,
              country: '',
              communicationTypePreferences: [],
              depositLimitFrequency: 'MONTHLY',
              depositLimit: 0,
              currency: 'USD',
            }}
            onClose={handleShow}
            onSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            config={{
              loqateIntegration,
              memberFormFields,
              loadingConfig,
              refetchConfig,
              checkDepositLimit,
            }}
          />
        </SharedStyledModal>
      )}
    </>
  );
};
