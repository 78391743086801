import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { message, Spin } from 'antd';
import gql from 'graphql-tag';
import { useConfig } from 'hooks/useConfig';
import { get, includes, compact } from 'lodash';
import messages from 'messages/index';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import coercedGet from 'utils/coercedGet';
import removeNull from 'utils/removeNull';
import removeTypename from 'utils/removeTypename';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import useTranslate from 'utils/useTranslate';
import { UPDATE_MEMBER } from 'graphql/mutations/member.mutation';
import { Member, MemberTag } from 'types/graphqlTypes';
import MemberForm from 'components/MemberForm';
import { SharedStyledModal } from 'styles/SharedStyledModal';
import moment from 'moment';
import isRow from 'utils/isRow';

export const EDIT_MEMBER_DATA = gql`
  query Status($id: ID, $hasPermission: Boolean!) {
    member(id: $id) {
      id
      status
      dateOfBirth
      email
      mobileNumber
      countryCode
      wechatId
      qqId
      gender
      realName
      firstName
      lastName
      forceVerification
      communicationTypePreferences
      platformId
      currency
      preferredLanguage
      address {
        country
        postCode
        street
        building
        premise
        locality
        stateDistrict
        province
        address
      }
      memberLevel {
        id
        name
        color
      }
      tags {
        id
        name
        color
        description
      }
      memberLoyaltyLevels {
        id
        name
        color
        programme {
          id
          name
          serialCode
        }
      }
    }

    memberLoyaltyProgrammes(filter: { status: { eq: ACTIVE } })
      @include(if: $hasPermission) {
      edges {
        node {
          id
          name
          primary
          dateTimeCreated
          levels {
            id
            name
            color
            rank
          }
        }
      }
    }
  }
`;

export const EditMember = ({
  handleShow,
  isShow,
  member,
  refetchQueries,
  refetchFn,
  onSuccess,
}: IEditMember) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [newMember, setNewMember] = useState<Partial<Member>>({});
  const [initialLoad, setInitialLoad] = useState(false);
  const [updateMember] = useMutation(UPDATE_MEMBER);

  const [deletedTags, setDeletedTags] = useState<MemberTag[]>([]);

  const translate = useTranslate();
  const client = useApolloClient();
  const { context } = useOperatorHeader();

  useEffect(() => {
    if (isShow) {
      setInitialLoad(true);
      client
        .query({
          query: EDIT_MEMBER_DATA,
          fetchPolicy: 'network-only',
          variables: {
            id: member.id,
            hasPermission: false,
          },
        })
        .then((resp) => {
          const memberData: Member = coercedGet(resp, 'data.member', {});
          setNewMember({
            ...removeTypename(member),
            memberLevel: memberData?.memberLevel?.id,
            status: memberData?.status,
            tags: memberData?.tags,
            dateOfBirth: moment(memberData?.dateOfBirth),
            email: memberData?.email,
            mobileNumber: memberData?.mobileNumber,
            gender: memberData?.gender,
            realName: memberData?.realName,
            forceVerification: memberData?.forceVerification,
            // memberLoyaltyLevels: memberData?.memberLoyaltyLevels,
            firstName: memberData?.firstName,
            lastName: memberData?.lastName,
            communicationTypePreferences:
              memberData?.communicationTypePreferences,
            countryCode: memberData.countryCode,
            address: memberData?.address,
            platformId: memberData?.platformId,
            currency: memberData?.currency,
            preferredLanguage: memberData?.preferredLanguage,
          });
        })
        .finally(() => {
          setInitialLoad(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow]);

  const deleteUnnecessaryInput = (input: Record<string, any>) => {
    if (!isRow) {
      delete input.currency;
      delete input.preferredLanguage;
      delete input.depositLimit;
      delete input.depositLimitFrequency;
    }

    delete input.id;
    delete input.username;
    delete input.country;
    delete input.city;
    delete input.partialAddress;
    delete input.address.__typename;
    delete input.tags;
  };

  const handleSubmit = async (field: { [key: string]: any }) => {
    setIsSubmitting(true);
    const input: any = removeNull(field, true);

    input.communicationTypePreferences =
      field.communicationTypePreferences || [];
    input.forceVerification = field.forceVerification;
    input.mobileNumber = field.mobileNumber?.toString();
    input.address = {
      ...removeNull(input.address, true),
      ...(input.country && { country: input.country }),
    };

    input.dateOfBirth = moment(field.dateOfBirth).format('YYYY-MM-DD');

    const inputTags = input.tags
      ? input.tags.map((item: MemberTag) => item.id)
      : undefined;

    const initialMemberTags = newMember?.tags?.map((tag) => tag?.id);

    const rawDeletedTags = deletedTags.map((tag) => tag.id);

    const removedTags = rawDeletedTags.map((deletedTag) =>
      includes(inputTags, deletedTag) && includes(initialMemberTags, deletedTag)
        ? null
        : deletedTag
    );

    deleteUnnecessaryInput(input);

    try {
      await updateMember({
        variables: {
          id: get(member, 'id'),
          input: {
            ...input,
            tagsV2: {
              add: inputTags,
              remove: compact(removedTags),
            },
          },
        },
        refetchQueries,
        context,
      }).then(() => {
        if (refetchFn) {
          refetchFn();
        }
      });

      setIsSubmitting(false);
      message.success(translate(messages.MEMBER_UPDATED));
      if (onSuccess) {
        onSuccess!(member.id);
      }

      handleShow();
    } catch (error) {
      setIsSubmitting(false);
    } finally {
      setDeletedTags([]);
    }
  };

  const {
    loadingConfig,
    loqateIntegration,
    refetch: refetchConfig,
    checkDepositLimit,
  } = useConfig();

  return (
    <>
      {isShow && (
        <SharedStyledModal
          destroyOnClose
          title={
            <FormattedMessage
              id="edit-member.text"
              defaultMessage="Edit Member"
            />
          }
          width={600}
          visible
          footer={false}
          onCancel={handleShow}
        >
          <Spin spinning={initialLoad}>
            <MemberForm
              isEdit
              isSubmitting={isSubmitting}
              initialValues={newMember}
              onClose={handleShow}
              onSubmit={handleSubmit}
              config={{
                loadingConfig,
                loqateIntegration,
                refetchConfig,
                checkDepositLimit,
              }}
              setDeletedTags={setDeletedTags}
            />
          </Spin>
        </SharedStyledModal>
      )}
    </>
  );
};

interface IEditMember {
  handleShow: () => void;
  isShow: boolean;
  member: { [key: string]: any };
  refetchQueries: any[];
  onSuccess?: (memberId: string) => void;
  refetchFn?: () => void;
}
