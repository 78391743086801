import * as yup from 'yup';

export const GbgNetConfigSchema = yup.object().shape({
  enableGBGAffordabilityCheck: yup.boolean(),
  gbgTrigger: yup.string().notRequired(),
  gbgNetDepositThreshold: yup.number().when('enableGBGAffordabilityCheck', {
    is: true,
    then: yup
      .number()
      .typeError('GBG Threshold is Required.')
      .required('GBG Threshold is Required.'),
    otherwise: yup.number().notRequired(),
  }),
});

export type FinancialLightCheckType = yup.InferType<typeof GbgNetConfigSchema>;
