/* eslint-disable no-bitwise */
import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import {
  BalanceTransactionRecord,
  ManualAdjustment,
  Member,
} from 'types/graphqlTypes';
import { Typography } from 'antd';
import moment from 'moment';
import TruncateWithTooltip from 'components/TruncateWithTooltip';
import { DATE_TIME_FORMAT } from 'constants/date';
import AdjustmentStatus from 'components/AdjustmentStatus';
import esGet from 'utils/esGet';
import RequestRedirect from 'components/RequestRedirect';
import ProcessorType from 'components/ProcessorType';
import isRow from 'utils/isRow';
import { startCase, toLower } from 'lodash';

const { Text } = Typography;

const reportFormatDate = (date: string, timezone: string) =>
  date ? moment.tz(date, timezone).format(`${DATE_TIME_FORMAT} A`) : '-';

export const getColumns = ({
  renderNumeral,
  reportTimezone,
  showBaseAmounts,
}: {
  renderNumeral: (
    value: string | number,
    isCrypto?: boolean,
    isExchangeRate?: boolean
  ) => string;
  reportTimezone: string;
  showBaseAmounts?: boolean | null;
}): ColumnsType<BalanceTransactionRecord> => [
  {
    key: 'serialCode',
    title: 'Serial Code',
    dataIndex: 'serialCode',
    width: 100,
    align: 'center',
  },
  {
    key: 'affiliate',
    title: 'Affiliate',
    dataIndex: 'affiliate',
    width: 100,
    align: 'center',
    render: (affiliate: string) => affiliate || '-',
  },
  {
    key: 'member',
    title: 'Member',
    dataIndex: 'member',
    width: 100,
    align: 'center',
    render: (member: Member) => <TruncateWithTooltip text={member.username!} />,
  },
  {
    key: 'platformId',
    title: 'Platform Id',
    dataIndex: 'member',
    width: 100,
    align: 'center',
    render: (member: Member) => (
      <TruncateWithTooltip text={member.platformId!} />
    ),
  },
  {
    ...(isRow && {
      key: 'currency',
      title: 'Member Currency',
      dataIndex: 'currency',
      width: 150,
      align: 'center',
      render: (currency: string) => currency || '-',
    }),
  },
  {
    key: 'amount',
    // eslint-disable-next-line no-nested-ternary
    title: `Amount ${isRow ? '(Member Currency)' : ''}`,
    width: 150,
    align: 'center',
    render: (record: BalanceTransactionRecord) => {
      const { amount, isCrypto } = record || {};

      const color = +amount < 0 ? '#f5222d' : '#52c41a';

      return <Text style={{ color }}>{renderNumeral(amount, isCrypto!)}</Text>;
    },
  },
  {
    key: 'balance',
    title: `Balance ${isRow ? '(Member Currency)' : ''}`,
    dataIndex: 'balance',
    width: 150,
    align: 'center',
    render: (balance: string) => renderNumeral(balance),
  },
  {
    ...(isRow && {
      key: 'mainCashBalance',
      title: 'Main Cash (Member Currency)',
      dataIndex: 'mainCashBalance',
      width: 150,
      align: 'center',
      render: (mainCashBalance: string) => renderNumeral(mainCashBalance),
    }),
  },
  {
    ...(isRow && {
      key: 'casinoCashBalance',
      title: 'Casino Cash (Member Currency)',
      dataIndex: 'casinoCashBalance',
      width: 150,
      align: 'center',
      render: (casinoCashBalance) => renderNumeral(casinoCashBalance),
    }),
  },
  {
    ...(isRow && {
      key: 'sportsCashBalance',
      title: 'Sports Cash (Member Currency)',
      dataIndex: 'sportsCashBalance',
      width: 150,
      align: 'center',
      render: (sportsCashBalance: string) => renderNumeral(sportsCashBalance),
    }),
  },
  {
    ...(isRow && {
      key: 'casinoBonusBalance',
      title: 'Casino Bonus (Member Currency)',
      dataIndex: 'casinoBonusBalance',
      width: 150,
      align: 'center',
      render: (casinoBonusBalance: string) => renderNumeral(casinoBonusBalance),
    }),
  },
  {
    ...(isRow && {
      key: 'sportsBonusBalance',
      title: 'Sports Bonus (Member Currency)',
      dataIndex: 'sportsBonusBalance',
      width: 150,
      align: 'center',
      render: (sportsBonusBalance: string) => renderNumeral(sportsBonusBalance),
    }),
  },
  {
    ...(isRow &&
      showBaseAmounts && {
        key: 'amountInBaseCurrency',
        title: 'Amount (Base)',
        dataIndex: 'amountInBaseCurrency',
        width: 150,
        align: 'center',
        render: (amountInBaseCurrency) => renderNumeral(amountInBaseCurrency),
      }),
  },
  {
    ...(isRow &&
      showBaseAmounts && {
        key: 'balanceInBaseCurrency',
        title: 'Balance (Base Currency)',
        dataIndex: 'balanceInBaseCurrency',
        width: 150,
        align: 'center',
        render: (balanceInBaseCurrency) => renderNumeral(balanceInBaseCurrency),
      }),
  },
  {
    ...(isRow &&
      showBaseAmounts && {
        key: 'mainCashBase',
        title: 'Main Cash (Base Currency)',
        dataIndex: 'mainCashBase',
        width: 150,
        align: 'center',
        render: (mainCashBase: string) => renderNumeral(mainCashBase),
      }),
  },
  {
    ...(isRow &&
      showBaseAmounts && {
        key: 'casinoCashBase',
        title: 'Casino Cash (Base Currency)',
        dataIndex: 'casinoCashBase',
        width: 150,
        align: 'center',
        render: (casinoCashBase) => renderNumeral(casinoCashBase),
      }),
  },
  {
    ...(isRow &&
      showBaseAmounts && {
        key: 'sportsCashBase',
        title: 'Sports Cash (Base Currency)',
        dataIndex: 'sportsCashBase',
        width: 150,
        align: 'center',
        render: (sportsCashBase: string) => renderNumeral(sportsCashBase),
      }),
  },
  {
    ...(isRow &&
      showBaseAmounts && {
        key: 'casinoBonusBase',
        title: 'Casino Bonus (Base Currency)',
        dataIndex: 'casinoBonusBase',
        width: 150,
        align: 'center',
        render: (casinoBonusBase: string) => renderNumeral(casinoBonusBase),
      }),
  },
  {
    ...(isRow &&
      showBaseAmounts && {
        key: 'sportsBonusBase',
        title: 'Sports Bonus (Base Currency)',
        dataIndex: 'sportsBonusBase',
        width: 150,
        align: 'center',
        render: (sportsBonusBase: string) => renderNumeral(sportsBonusBase),
      }),
  },

  {
    ...(isRow &&
      showBaseAmounts && {
        key: 'exchangeRate',
        title: 'Exchange Rate (Base)',
        dataIndex: 'exchangeRate',
        width: 150,
        align: 'center',
        render: (exchangeRate: string) =>
          renderNumeral(exchangeRate, false, true),
      }),
  },

  {
    ...(isRow && {
      key: 'cryptoBalance',
      title: 'Crypto Balance',
      dataIndex: 'cryptoBalance',
      width: 150,
      align: 'center',
      render: (cryptoBalance: string) => renderNumeral(cryptoBalance, true),
    }),
  },

  {
    ...(isRow && {
      key: 'cryptoAmount',
      title: 'Crypto Amount',
      dataIndex: 'cryptoAmount',
      width: 150,
      align: 'center',
      render: (cryptoAmount: string) => renderNumeral(cryptoAmount, true),
    }),
  },

  {
    ...(isRow && {
      key: 'cryptoCurrency',
      title: 'Crypto Currency',
      dataIndex: 'cryptoCurrency',
      width: 150,
      align: 'center',
      render: (cryptoCurrency: string) => cryptoCurrency || '-',
    }),
  },

  {
    ...(isRow && {
      key: 'cryptoExchangeRate',
      title: 'Crypto Exchange Rate',
      dataIndex: 'cryptoExchangeRate',
      width: 150,
      align: 'center',
      render: (cryptoExchangeRate: string) =>
        renderNumeral(cryptoExchangeRate, true, true),
    }),
  },

  {
    key: 'manualAdjustment',
    title: 'Balance Adjustment',
    dataIndex: 'manualAdjustment',
    width: 100,
    align: 'center',
    render: (manualAdjustment: Record<string, string>) =>
      manualAdjustment ? <AdjustmentStatus /> : '-',
  },
  {
    key: 'type',
    title: 'Type',
    width: 100,
    align: 'center',
    render: (record: BalanceTransactionRecord) => {
      const manualAdjType = esGet(record?.manualAdjustment?.type, undefined);

      if (manualAdjType) return startCase(toLower(manualAdjType)) || '-';

      const type = esGet(record.type, undefined);

      return startCase(toLower(type)) || '-';
    },
  },
  {
    key: 'remarks',
    title: 'Remarks',
    dataIndex: 'remarks',
    width: 100,
    align: 'center',
    render: (remarks: string) => remarks || '-',
  },
  {
    key: 'transaction',
    title: 'Unique Transaction ID',
    width: 100,
    align: 'center',
    render: (record: BalanceTransactionRecord) => (
      <RequestRedirect node={record} />
    ),
  },
  {
    key: 'processor',
    title: 'Initiator',
    width: 100,
    align: 'center',
    render: (record: BalanceTransactionRecord) => (
      <ProcessorType id={record.id} />
    ),
  },
  {
    key: 'dateTimeCreated',
    title: 'Date / Time Created',
    dataIndex: 'dateTimeCreated',
    width: 100,
    align: 'center',
    render: (dateTimeCreated: string) =>
      reportFormatDate(dateTimeCreated, reportTimezone),
  },
  {
    key: 'manualBalanceAdjustmentRemarks',
    title: 'Balance Adjustment Remarks',
    dataIndex: 'manualAdjustment',
    width: 100,
    align: 'center',
    render: (manualAdjustment: ManualAdjustment) =>
      manualAdjustment?.remarks || '-',
  },
];

const mapActualToManual = {
  WITHDRAWAL: 'WITHDRAWAL_MANUAL_ADJUSTMENT',
  DEPOSIT: 'DEPOSIT_MANUAL_ADJUSTMENT',
  PROMO_PAYOUT: 'PROMO_PAYOUT_MANUAL_ADJUSTMENT',
  PAYOUT: 'PAYOUT_MANUAL_ADJUSTMENT',
  CREDIT_VENDOR_TRANSFER: 'CREDIT_VENDOR_TRANSFER_MANUAL_ADJUSTMENT',
  DEBIT_VENDOR_TRANSFER: 'DEBIT_VENDOR_TRANSFER_MANUAL_ADJUSTMENT',
  SYSTEM: 'SYSTEM_CREDIT_MANUAL_ADJUSTMENT',
  // SYSTEM: 'SYSTEM_DEBIT_MANUAL_ADJUSTMENT',
  EXTERNAL_PROGRAMME_MANUAL_ADJUSTMENT: 'EXTERNAL_PROGRAMME_MANUAL_ADJUSTMENT',
};

const nonManualAdjustmentTypes = [
  'BET',
  'REFUND_BET',
  'PAYOUT',
  'DEPOSIT',
  'WITHDRAWAL',
  'WITHDRAWAL_FEE',
  'WITHDRAWAL_CLAWBACK_FEE',
  'WITHDRAWAL_REQUEST_REJECTED',
  'PROMO_PAYOUT',
  'REBATE_PAYOUT',
  'AFFILIATE_PAYOUT',
  'SYSTEM',
  'MEMBER_LOYALTY_PAYOUT',
];

// request to BE, expose manualAdjustment filter in BTR to avoid function like this
const modifyFilterForManualAdjustment = (values?: string[]) => {
  if (!values) {
    return undefined;
  }

  const actual = Object.keys(mapActualToManual);

  return actual.reduce((acc, cur) => {
    const valueExists = !!values.find((value) => value === cur);
    const manualOfActualExists = !!values.find(
      (value) =>
        value === mapActualToManual[cur as keyof typeof mapActualToManual]
    );
    if (valueExists && !manualOfActualExists) {
      return [...acc, mapActualToManual[cur as keyof typeof mapActualToManual]];
    }

    if (manualOfActualExists && !valueExists) {
      return acc.filter(
        (v) => v !== mapActualToManual[cur as keyof typeof mapActualToManual]
      );
    }
    return acc;
  }, values);
};

export const typeFilterAdjustment = (value: Record<string, any>) => {
  const typeValues = modifyFilterForManualAdjustment(value?.type?.in);
  return {
    ...value,
    ...(typeValues && {
      type: {
        in: typeValues,
      },
    }),
  };
};

export const convertManualAdjustmentToTypes = (value: Record<string, any>) => {
  const { MANUAL_ADJUSTMENT, ...restFilter } = value;
  const isNo = MANUAL_ADJUSTMENT === 'no';
  const isYes = MANUAL_ADJUSTMENT === 'yes';

  if (restFilter?.type) {
    const typeFilters = [...restFilter.type.in];
    const manual = Object.values(mapActualToManual);
    if (isNo) {
      // Actual transaction only
      // [DEPOSIT, DEPOSIT_MA, PROMO_PAYOUT, PROMO_PAYOUT_MA]
      // Result -> [DEPOSIT, PROMO_PAYOUT]
      return {
        ...restFilter,
        type: {
          in: typeFilters.filter(
            (typeData) => !manual.find((manualType) => manualType === typeData)
          ),
        },
      };
    }

    if (isYes) {
      // Manual Transaction only
      // [DEPOSIT, DEPOSIT_MA, PROMO_PAYOUT, PROMO_PAYOUT_MA]
      // Result -> [DEPOSIT_MA, PROMO_PAYOUT_MA]
      const filterForYes = typeFilters.filter(
        (typeData) => !!manual.find((manualType) => manualType === typeData)
      );
      if (filterForYes.includes('SYSTEM_CREDIT_MANUAL_ADJUSTMENT')) {
        filterForYes.push('SYSTEM_DEBIT_MANUAL_ADJUSTMENT');
      }

      return {
        ...restFilter,
        type: {
          in: filterForYes,
        },
      };
    }

    const hasSystemType = typeFilters.includes('SYSTEM');

    return {
      ...restFilter,
      type: {
        in: hasSystemType
          ? [...typeFilters, 'SYSTEM_DEBIT_MANUAL_ADJUSTMENT']
          : typeFilters,
      },
    };
    // Display Both
  }

  if (isYes) {
    return {
      ...restFilter,
      type: {
        nin: nonManualAdjustmentTypes,
      },
    };
  }

  if (isNo) {
    return {
      ...restFilter,
      type: {
        in: nonManualAdjustmentTypes,
      },
    };
  }

  return restFilter;
};
