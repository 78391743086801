import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Radio, Select, Switch } from 'antd';
import messages from 'messages';
import useTranslate from 'utils/useTranslate';
import VIPSelect from 'components/VIPSelect';
import { VipAwardTypeSelect } from 'components/VipAwardTypeSelect/VipAwardTypeSelect';
import { RebateGroupsSelect } from 'components/RebateGroupsSelect/RebateGroupsSelect';
import { AffiliateProgrammesSelect } from 'components/AffiliateProgrammesSelect/AffiliateProgrammesSelect';
import coercedGet from 'utils/coercedGet';
import { usePermissions } from 'store/accountState';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import ALL_PERMISSIONS from 'constants/permissions3';
import { useApolloClient } from '@apollo/react-hooks';
import { turnoverShowTypes } from 'pages/components/NewMemberManagement/components/Content/components/Members/components/MembersTable/components/BalanceForm/components/utils';
import { BRANDID_AMOUNT_NOTE } from 'constants/uploadExcelValidation';
import HexopayCardsSelect from 'pages/components/NewMemberManagement/components/Content/components/Members/components/MembersTable/components/BalanceForm/components/HexopayCardsSelect';
import ImportExcel from 'components/ImportExcel';
import { useMethod } from 'store/methodState';
import ImportText from 'components/ImportText/ImportText';
import { blockAlphabets } from 'utils/blockAlphabets';
import { formatAmount } from 'utils/formatAmount';
import {
  actualShowTypes,
  AFFILIATE_COMMISION,
  FormItem,
  MEMBER_LOYALTY_PAYOUT,
  REBATE_PAYOUT,
  renderOptsBasedOnAction,
  StyledItem,
} from './utils';
import { AFFILIATE_PROGRAMMES, REBATES } from './query';
import messagesLocal from '../messages';
import ManualWithdrawalFields from './ManualWithdrawalFields/ManualWithdrawalFields';

const { TextArea } = Input;

type Props = {
  touched: Record<string, any>;
  errors: Record<string, any>;
  setFieldValue: (key: string, data: any) => void;
  values: Record<string, any>;
  handleChange: (data: any) => void;
  showImportExcel: boolean;
  showImportText: boolean;
  type: 'single' | 'bulk';
  memberNotAffiliate: boolean;
  memberId?: string;
  isSingleManualWithdrawal?: boolean;
};

export const EditBalanceFields = ({
  touched,
  errors,
  setFieldValue,
  values,
  handleChange,
  showImportExcel,
  showImportText,
  type,
  memberNotAffiliate,
  memberId,
  isSingleManualWithdrawal = true,
}: Props) => {
  const [validationType, setValidationType] = useState({
    noPromo: false,
    noRebate: false,
    notAffiliate: false,
  });

  const translate = useTranslate();
  const client = useApolloClient();
  const {
    setSelectedMethod,
    method: { selectedMethod },
  } = useMethod() as any;

  const { role, permissions } = usePermissions();

  const { ALLOWED_LIST: ALLOWED_REBATE_LIST } = collectPermissions(
    role,
    permissions,
    ['LIST'],
    ALL_PERMISSIONS.ALL_REBATES.REBATES_REBATES_REBATE_GROUP
  );
  const { ALLOWED_LIST: ALLOWED_AFFILIATE_LIST } = collectPermissions(
    role,
    permissions,
    ['LIST'],
    ALL_PERMISSIONS.ALL_AFFILIATES.AFFILIATES_AFFILIATE_PROGRAMME
  );

  const { ALLOWED_ADD_BALANCE, ALLOWED_REMOVE_BALANCE } = collectPermissions(
    role,
    permissions,
    ['ADD_BALANCE', 'REMOVE_BALANCE'],
    `${ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MANAGEMENT}:EDIT_BALANCE`
  );

  const fetchValidationForType = useCallback(async () => {
    const rebateVariables = {
      filter: {
        status: {
          eq: 'ACTIVE',
        },
      },
      hasPermission: ALLOWED_REBATE_LIST,
    };
    const affiliateVariables = {
      filter: {
        status: {
          eq: 'ACTIVE',
        },
      },
      hasPermission: ALLOWED_AFFILIATE_LIST,
    };

    const [rebateResponse, affiliateResponse] = await Promise.all([
      client.query({
        query: REBATES,
        variables: rebateVariables,
      }),
      client.query({
        query: AFFILIATE_PROGRAMMES,
        variables: affiliateVariables,
      }),
    ]);
    const noRebate =
      coercedGet(rebateResponse, 'data.rebateGroups.edges', []).length === 0;
    let notAffiliate =
      coercedGet(affiliateResponse, 'data.affiliateProgrammes.edges', [])
        .length === 0;

    if (type === 'single') {
      notAffiliate = memberNotAffiliate;
    }

    setValidationType({
      noPromo: false,
      noRebate,
      notAffiliate,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, memberNotAffiliate, type]);

  useEffect(() => {
    fetchValidationForType();
  }, [fetchValidationForType]);

  return (
    <div className="p-3">
      {showImportExcel && !values.text && (
        <ImportExcel
          values={values}
          setFieldValue={setFieldValue}
          validation={BRANDID_AMOUNT_NOTE}
        />
      )}

      {showImportText && !values.excel && (
        <ImportText values={values} setFieldValue={setFieldValue} />
      )}

      <Form layout="vertical">
        <FormItem
          label={translate(messages.ACTION)}
          keyType="action"
          errors={errors}
          touched={touched}
          data-testid="radio-group"
        >
          <Radio.Group
            name="action"
            onChange={({ target: { value } }) => {
              setFieldValue('action', value);
              setFieldValue('type', '');
            }}
            value={values.action}
          >
            <Radio
              value="ADD"
              aria-label="add-balance"
              disabled={!ALLOWED_ADD_BALANCE}
            >
              <span className="fs-13">
                <FormattedMessage
                  id="add-balance.text"
                  defaultMessage="Add Balance"
                />
              </span>
            </Radio>
            <Radio
              value="REMOVE"
              aria-label="remove-balance"
              disabled={!ALLOWED_REMOVE_BALANCE}
            >
              <span className="fs-13">
                <FormattedMessage
                  id="remove-balance.text"
                  defaultMessage="Remove Balance"
                />
              </span>
            </Radio>
          </Radio.Group>
        </FormItem>
        {!values.excel && !values.text && (
          <FormItem
            label={translate(messages.AMOUNT)}
            touched={touched}
            errors={errors}
            keyType="amount"
          >
            <Input
              aria-label="amount"
              name="amount"
              onChange={handleChange}
              onBlur={(e) =>
                formatAmount(e, (val) => setFieldValue('amount', val))
              }
              onKeyDown={(e) => blockAlphabets(e)}
              value={values.amount}
              placeholder={translate(messagesLocal['please-enter.text'])}
            />
          </FormItem>
        )}

        <FormItem
          label={translate(messages.TYPE)}
          errors={errors}
          touched={touched}
          keyType="type"
        >
          <Select
            aria-label="balance-type"
            value={values.type}
            onChange={(e) => setFieldValue('type', e)}
          >
            {renderOptsBasedOnAction(
              values.action,
              validationType,
              translate,
              role,
              permissions,
              isSingleManualWithdrawal
            )}
          </Select>
        </FormItem>

        {values.type === 'WITHDRAWAL' && (
          <>
            <FormItem
              label="Choose a method"
              keyType="action"
              errors={errors}
              touched={touched}
              data-testid="radio-group"
            >
              <Radio.Group
                name="radiogroup"
                defaultValue="CARD"
                onChange={(e) => {
                  setSelectedMethod(e.target.value);
                  setFieldValue('method', e.target.value);
                }}
              >
                <Radio value="CARD">Card</Radio>
                <Radio value="MANUAL">Manual Bank Transfer</Radio>
              </Radio.Group>
            </FormItem>
            {selectedMethod === 'CARD' ? (
              <HexopayCardsSelect
                onChange={(e: string) => setFieldValue('hexoPayCreditCard', e)}
                memberId={memberId!}
                errors={errors}
                touched={touched}
                value={values.hexoPayCreditCard}
              />
            ) : (
              <></>
            )}
          </>
        )}

        {values.type === 'MANUAL_WITHDRAWAL' && (
          <ManualWithdrawalFields
            memberId={memberId!}
            errors={errors}
            touched={touched}
            values={values}
            setFieldValue={setFieldValue}
          />
        )}

        {actualShowTypes.includes(values.type) && (
          <StyledItem
            colon={false}
            label={<span className="fs-12">{translate(messages.ACTUAL)}</span>}
          >
            <Switch
              checked={values.actual}
              onChange={(val) => setFieldValue('actual', val)}
            />
            <small className="ml-2 font-weight-bold fs-13">
              {values.actual ? (
                <FormattedMessage id="yes.text" defaultMessage="Yes" />
              ) : (
                <FormattedMessage id="no.text" defaultMessage="No" />
              )}
            </small>
          </StyledItem>
        )}

        {turnoverShowTypes.includes(values.type) && (
          <FormItem
            label={translate(messages['turnover-x.text'])}
            touched={touched}
            errors={errors}
            keyType="turnover"
          >
            <Input
              name="turnover"
              onChange={handleChange}
              value={values.turnover}
              placeholder={translate(messagesLocal['please-enter.text'])}
            />
          </FormItem>
        )}

        {values.type === MEMBER_LOYALTY_PAYOUT && (
          <>
            <FormItem
              label={translate(messages.VIP_PROGRAMME_TIER_NAME)}
              errors={errors}
              touched={touched}
              keyType="vip"
            >
              <VIPSelect
                allProgramme
                onChange={(val: Record<string, any>) =>
                  setFieldValue('vip', {
                    id: val.id,
                    name: val.loyaltyName,
                    color: val.color,
                    programme: {
                      id: val.programmeId,
                      name: val.programmeName,
                    },
                  })
                }
                memberLevel={values.vip}
              />
            </FormItem>

            <FormItem
              label={translate(messages.AWARD_TYPE)}
              errors={errors}
              touched={touched}
              keyType="awardType"
            >
              <VipAwardTypeSelect
                value={values.awardType}
                onChange={(e) => setFieldValue('awardType', e)}
              />
            </FormItem>
          </>
        )}

        {values.type === REBATE_PAYOUT && (
          <FormItem
            label={translate(messages.REBATE_PROGRAMME_NAME)}
            errors={errors}
            touched={touched}
            keyType="rebateGroup"
          >
            <RebateGroupsSelect
              value={values.rebateGroup}
              onChange={(e) => setFieldValue('rebateGroup', e)}
            />
          </FormItem>
        )}

        {values.type === AFFILIATE_COMMISION && (
          <FormItem
            label={translate(messages.AFFILIATE_PROGRAMME_NAME)}
            errors={errors}
            touched={touched}
            keyType="affiliateProgramme"
          >
            <AffiliateProgrammesSelect
              value={values.affiliateProgramme}
              onChange={(e) => setFieldValue('affiliateProgramme', e)}
            />
          </FormItem>
        )}

        <FormItem
          label={translate(messages.TRANSACTION_RECORD_REMARK)}
          errors={errors}
          touched={touched}
          keyType="remarks"
        >
          <TextArea
            data-testid="edit-balance-form-remarks"
            aria-label="remarks"
            name="remarks"
            onChange={handleChange}
            value={values.remarks || ''}
            placeholder={translate(messagesLocal['please-enter.text'])}
            autoSize={{ minRows: 3, maxRows: 6 }}
          />
        </FormItem>
        <FormItem
          label={translate(messages.ACCOUNT_PASSWORD)}
          errors={errors}
          touched={touched}
          keyType="accountPassword"
        >
          <Input.Password
            data-testid="edit-balance-form-account-password"
            name="accountPassword"
            aria-label="password"
            onChange={handleChange}
            value={values.accountPassword || ''}
            placeholder={translate(messagesLocal['please-enter.text'])}
          />
        </FormItem>
      </Form>
    </div>
  );
};
