import React, { useContext } from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { ApolloClient } from 'apollo-client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from 'apollo-link-context';
import { createPersistedQueryLink } from 'apollo-link-persisted-queries';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
  defaultDataIdFromObject,
} from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { camelCase } from 'lodash';
import { ApolloLink, Observable, split } from 'apollo-link';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import introspectionQueryResultData from 'test-utils/fragmentTypes.json';
import Storage from 'constants/storage';
import typeDefs from 'apollo/typeDefs';
import resolvers from 'apollo/resolvers';
import useIsNext from 'hooks/useIsNext';
import ServerStatus from '../constants/serverStatus';
import refreshToken from '../utils/refreshToken';
import { ApiErrorHandlerContext, ApiErrorHandlerFunc } from './ApiErrorHandler';

const ApolloCustom: React.FC<{ handleApiError: ApiErrorHandlerFunc }> = ({
  children,
  handleApiError,
}) => {
  const { config, location } = window;

  const apolloPersistedQueryEnabled = useIsNext('apolloPersistedQuery');

  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
  });

  const wsLink = new WebSocketLink({
    uri: config.wsUrl,
    options: {
      reconnect: true,
      connectionParams: {
        headers: {
          authorization: `Bearer ${localStorage.getItem(Storage.ACCESS_TOKEN)}`,
        },
      },
      lazy: true,
    },
  });

  const httpLink = createUploadLink({
    uri: config.apiUrl,
  });

  const batchHttpLink = new BatchHttpLink({
    uri: config.apiUrl,
  });

  const link = split(
    ({ query, operationName, setContext: localSetContext }) => {
      const { kind, operation } = getMainDefinition(query) as any;
      localSetContext(() => ({
        uri: `${config.apiUrl}?${camelCase(operationName)}`,
      }));
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    split(
      ({ getContext }) => !!getContext().shouldBatch,
      batchHttpLink,
      httpLink
    )
  );

  const auth = setContext((_, { headers }) => {
    const accessToken = localStorage.getItem(Storage.ACCESS_TOKEN);
    const saToken = localStorage.getItem(Storage.SA_ACCESS_TOKEN);
    const boToken = localStorage.getItem(Storage.BO_ACCESS_TOKEN);

    const getAuth = () => {
      // This is a hack workaround to fix the problem of superadmin signin
      // and BackOfficce signin on the same browser happening
      // We should separate the superadmin completely on a new app
      // or separate Apollo provider
      const url = window.location.href;
      const lastUrl = url.split('/')[url.split('/').length - 1];
      if (accessToken) {
        if (lastUrl === 'controlcenter' || lastUrl === 'signin') {
          return `Bearer ${saToken}`;
        }
        return `Bearer ${boToken}`;
      }
      return '';
    };

    return {
      headers: {
        ...headers,
        authorization: getAuth(),
      },
    };
  });

  const error = onError(
    ({ graphQLErrors, networkError, operation, forward }): any => {
      if (graphQLErrors) {
        handleApiError(graphQLErrors as any);
      }
      if (networkError) {
        const err = (networkError as any).result || {
          code: ServerStatus.INTERNAL_SERVER_ERROR,
        };

        if (err?.message?.includes('Invalid access token')) {
          localStorage.removeItem(Storage.ACCESS_TOKEN);
          localStorage.removeItem(Storage.BO_ACCESS_TOKEN);
          localStorage.removeItem(Storage.REFRESH_TOKEN);
          localStorage.removeItem(Storage.APP_CONFIG);
          sessionStorage.removeItem(Storage.SCREEN_TAB_CONFIG);
          sessionStorage.removeItem(`${Storage.SCREEN_TAB_CONFIG}_NEW`);
          localStorage.removeItem('favorite');
          window.location.reload();
        }
        const oldHeaders = operation.getContext().headers;
        const func = async (observer: any) => {
          try {
            const {
              data: { access },
            } = await refreshToken(localStorage.getItem(Storage.REFRESH_TOKEN)); // eslint-disable-line
            localStorage.setItem(Storage.ACCESS_TOKEN, access);
            operation.setContext({
              headers: {
                ...oldHeaders,
                authorization: `Bearer ${access}`,
              },
            });
            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            };
            forward(operation).subscribe(subscriber);
          } catch (refreshError) {
            localStorage.removeItem(Storage.ACCESS_TOKEN);
            localStorage.removeItem(Storage.REFRESH_TOKEN);
            observer.error(refreshError);
          }
        };
        switch (err.code) {
          case ServerStatus.ACCESS_TOKEN_EXPIRED:
            return new Observable(func as any);
          case ServerStatus.FORBIDDEN:
            localStorage.removeItem(Storage.ACCESS_TOKEN);
            localStorage.removeItem(Storage.REFRESH_TOKEN);
            return new Observable((observer) =>
              observer.error((networkError as any).result)
            );
          default:
            return null;
        }
      }
      if (graphQLErrors) {
        const [gqlError] = graphQLErrors;
        if (!gqlError) return null;
        const { extensions } = gqlError;
        if (extensions?.code === 'PERSISTED_QUERY_NOT_FOUND') {
          return null;
        }
      }
      return null;
    }
  );

  const authLink = auth.concat(error);

  const url = new URL(location.href);
  const disableAPQ =
    true || // disable for now to examine if this one causes the issue
    apolloPersistedQueryEnabled ||
    url.searchParams.get('debug') ||
    false;

  const apolloLink = disableAPQ
    ? authLink.concat(link)
    : createPersistedQueryLink().concat(authLink.concat(link));

  const client = new ApolloClient({
    link: ApolloLink.from([apolloLink]),
    cache: new InMemoryCache({
      fragmentMatcher,
      dataIdFromObject: (object: any) => {
        switch (object.__typename) {
          case 'AccountUsageReportFilter':
            // to fix the unique identifier bug for filter fields in BHR

            return object.key; // use the `key` field as the identifier

          case 'BetHistoryReportFilter':
            // to fix the unique identifier bug for filter fields in BHR

            return object.key; // use the `key` field as the identifier

          case 'AstroPayWalletWithdrawalMethod':
            return object.key; // use the `key` field as the identifier

          default:
            return defaultDataIdFromObject(object); // fall back to default handling
        }
      },
    }),
    typeDefs,
    resolvers,
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

const Apollo: React.FC = ({ children }) => {
  const { handleApiError } = useContext(ApiErrorHandlerContext);
  return (
    <ApolloCustom handleApiError={handleApiError}>{children}</ApolloCustom>
  );
};

export default Apollo;
