import { LoadingOutlined } from '@ant-design/icons';
import { useApolloClient, useLazyQuery } from '@apollo/react-hooks';
import { Spin, Tabs, Tooltip } from 'antd';
import { ReactComponent as BalanceRefreshIcon } from 'components/assets/balance-refresh.svg';
import gql from 'graphql-tag';
import { useMemberPromo } from 'hooks/useMemberPromo';
import { get, sum } from 'lodash';
import messages from 'messages';
import { useLazyQueryPromise } from 'pages/components/common/hooks/useLazyQueryPromise';
import { useMember } from 'pages/components/NewMemberDetail/memberContext';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import coercedGet from 'utils/coercedGet';
import isRow from 'utils/isRow';
import useMemberCurrency from 'utils/useMemberCurrency';
import useTranslate from 'utils/useTranslate';
import { ReactComponent as RoundSearchIcon } from './assets/round-search-24px.svg';
import { AccountBalance } from './components/AccountBalance/AccountBalance';
import { TurnoverBalance } from './components/TurnoverBalance/TurnoverBalance';
import { WalletBalance } from './components/WalletBalance/WalletBalance';
import WalletBalanceWrapper from './components/WalletBalance/WalletBalancesWrapper';
import { actions, useBalancesContext } from './context';
import { VENDORS } from './queries';
import {
  StyledMainBalanceTabTitle,
  StyledMainTabs,
  WalletBalanceTabPane,
} from './styles';

const { TabPane } = Tabs;

const StyledTabPane = styled(TabPane)`
  padding: 0 !important;
`;

const BALANCE_UNITS = gql`
  query MemberBalanceUnit($id: ID!) {
    member(id: $id) {
      id
      balanceUnits {
        id
        amount
        expireAt
        originalAmount
        source
        turnoverRequirementBalance
        scope {
          game
          gameType
          vendor
          channel
        }
        timestamp
        turnoverRequirement
        turnoverRequirementMultiplier
        manualAdjustment
      }
    }
  }
`;
// const maxLoopCount = 3;

const MEMBER_BALANCE = gql`
  query MemberBalance($id: ID!) {
    member(id: $id) {
      id
      totalBalance
      totalDepositAmount
      totalDepositCount
      totalWithdrawalAmount
      totalWithdrawalCount
      winloss
      effectiveBet
      betsCount
      ggr
      totalPromoAmount
      ngr
      netDeposits {
        _30_DAYS
        _365_DAYS
        LIFETIME
      }
    }
  }
`;

const MEMBER_TOTAL_BALANCE = gql`
  query MemberBalance($id: ID!) {
    member(id: $id) {
      id
      totalBalance
    }
  }
`;

const initPollingBalancesValue = {
  totalDepositAmount: 0,
  totalDepositCount: 0,
  totalWithdrawalAmount: 0,
  totalWithdrawalCount: 0,
  winloss: 0,
  effectiveBet: 0,
  betsCount: 0,
  ggr: 0,
  totalPromoAmount: 0,
  ngr: 0,
  netDeposits: {
    LIFETIME: 0.0,
    _30_DAYS: 0.0,
    _365_DAYS: 0.0,
  },
};

const DISABLED_TEMP = true;

export const Balances = ({ permissions }: IBalancesProps) => {
  const {
    ALLOWED_ACCOUNT_BALANCE,
    ALLOWED_WALLET_BALANCE,
    ALLOWED_TO_BALANCE,
  } = permissions;

  const {
    refetchWalletBalances,
    walletTotalBalance,
    walletCurrency,
    walletBalancesLoading,
  } = useMemberPromo();
  const { addCurrency } = useMemberCurrency();

  const [loopCount, setLoopCount] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);
  const [pollingBalances, setPollingBalances] = useState(
    initPollingBalancesValue
  );
  const [balanceUnits, setBalanceUnits] = useState([]);
  const { member, refetch } = useMember() as any;
  const client = useApolloClient();
  const translate = useTranslate();
  const { onSearchWalletStatus, tabState, dispatch } = useBalancesContext();
  const [fetchVendors] = useLazyQueryPromise(VENDORS) as any;

  const limitedPollMemberBalance = useCallback(() => {
    client
      .query({
        query: MEMBER_BALANCE,
        variables: {
          id: member.id,
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        const {
          id: _id,
          totalBalance: balanceTotal,
          ...restOfBalances
        } = data?.member;
        setTotalBalance(balanceTotal);
        setPollingBalances(restOfBalances);
        setLoopCount((prev) => prev + 1);
      })
      .catch(() => {});
  }, [client, member.id]);

  useEffect(() => {
    limitedPollMemberBalance();
  }, [client, limitedPollMemberBalance, member.id]);

  useEffect(() => {
    if (loopCount > 0 && loopCount <= 3) {
      setTimeout(() => {
        limitedPollMemberBalance();
      }, 2000);
    }
  }, [limitedPollMemberBalance, loopCount]);

  const getVendors = useCallback(async () => {
    const response = await fetchVendors();
    const edges = get(response, 'data.vendors.edges', []);
    const vendorsData = edges.map((edge: any) => edge.node);
    dispatch([actions.SET_VENDORS, vendorsData]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!member) {
      return;
    }

    client
      .query({
        query: BALANCE_UNITS,
        context: { shouldBatch: true },
        fetchPolicy: 'network-only',
        variables: {
          id: member.id,
        },
      })
      .then((response) => {
        const data = coercedGet(response, 'data.member.balanceUnits', []);
        setBalanceUnits(data);
      });
  }, [client, member]);

  useEffect(() => {
    getVendors();
  }, [getVendors]);

  const [getTotalBalance] = useLazyQuery(MEMBER_TOTAL_BALANCE, {
    variables: {
      id: member.id,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const { totalBalance: balanceTotal } = data?.member;
      setTotalBalance(balanceTotal);
      refetchWalletBalances();
    },
  });

  const loadImage = (code: string) => {
    try {
      // eslint-disable-next-line global-require,import/no-dynamic-require
      return require(`../Balances/components/WalletBalance/assets/currencyIcons/${code.toLowerCase()}.png`);
    } catch (e) {
      //
    }

    return null;
  };

  // const isNext = useSearchParam('next') === 'true';

  return (
    <div className="card-container" id="main-tabs-container">
      <StyledMainTabs
        className="main-tab-member360"
        type="card"
        onChange={(e) => dispatch([actions.SET_TAB_STATE, e])}
      >
        {ALLOWED_ACCOUNT_BALANCE && (
          <TabPane
            tab={
              <div className="d-flex justify-content-between">
                <StyledMainBalanceTabTitle>
                  <FormattedMessage
                    id="account-balance.text"
                    defaultMessage="Account Balance"
                  />
                </StyledMainBalanceTabTitle>
                <div>{addCurrency(member.currency, totalBalance)}</div>
              </div>
            }
            key="1"
          >
            <AccountBalance
              member={member}
              refetch={refetch}
              balances={pollingBalances}
            />
          </TabPane>
        )}
        {ALLOWED_WALLET_BALANCE && (
          <WalletBalanceTabPane
            forceRender
            tab={
              <div className="d-flex justify-content-between align-items-center">
                <StyledMainBalanceTabTitle>
                  <FormattedMessage
                    id="wallet-balance.text"
                    defaultMessage="Wallet Balance"
                  />
                </StyledMainBalanceTabTitle>
                <div style={{ marginRight: '8px' }}>
                  {isRow ? (
                    <div className="d-flex align-items-center h-100 w-100">
                      {walletBalancesLoading ? (
                        <Spin
                          size="small"
                          indicator={<LoadingOutlined spin />}
                        />
                      ) : (
                        walletCurrency.selectedCurrency && (
                          <>
                            <img
                              src={loadImage(walletCurrency.selectedCurrency)}
                              alt={`${walletCurrency.selectedCurrency}_icon`}
                              style={{
                                height: '10px',
                                width: '10px',
                                marginRight: '8px',
                              }}
                            />

                            {walletTotalBalance.toFixed(
                              walletCurrency.currencyType === 'CRYPTO' ? 8 : 2
                            )}
                          </>
                        )
                      )}
                    </div>
                  ) : (
                    addCurrency(member.currency, walletTotalBalance)
                  )}
                </div>
                {tabState === '2' && !walletBalancesLoading && (
                  <>
                    <Tooltip title={translate(messages.REFRESH)}>
                      <BalanceRefreshIcon
                        style={{ width: '16px', height: '16px' }}
                        onClick={() => {
                          getTotalBalance();
                        }}
                      />
                    </Tooltip>
                    {DISABLED_TEMP ? (
                      <></>
                    ) : (
                      <Tooltip title={translate(messages.SEARCH)}>
                        <RoundSearchIcon
                          style={{ width: '16px', height: '16px' }}
                          onClick={() => {
                            dispatch([actions.SET_SEARCH_VENDORS_SELECTED, []]);
                            dispatch([
                              actions.SET_ON_SEARCH_WALLET_STATUS,
                              !onSearchWalletStatus,
                            ]);
                          }}
                        />
                      </Tooltip>
                    )}
                  </>
                )}
              </div>
            }
            key="2"
          >
            {isRow ? (
              <WalletBalanceWrapper member={member} />
            ) : (
              <WalletBalance member={member} />
            )}
          </WalletBalanceTabPane>
        )}
        {ALLOWED_TO_BALANCE && (
          <StyledTabPane
            tab={
              <div className="d-flex justify-content-between">
                <StyledMainBalanceTabTitle>
                  <FormattedMessage
                    id="t/o-balance.text"
                    defaultMessage="T/O Balance"
                  />
                </StyledMainBalanceTabTitle>
                <div>
                  {addCurrency(
                    member.currency,
                    sum(
                      balanceUnits.map((x: any) => x.turnoverRequirementBalance)
                    )
                  )}
                </div>
              </div>
            }
            key="3"
          >
            <TurnoverBalance member={member} balanceUnitsData={balanceUnits} />
          </StyledTabPane>
        )}
      </StyledMainTabs>
    </div>
  );
};

interface IBalancesProps {
  permissions: {
    ALLOWED_ACCOUNT_BALANCE: boolean;
    ALLOWED_WALLET_BALANCE: boolean;
    ALLOWED_TO_BALANCE: boolean;
  };
}
