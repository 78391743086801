import React, { useState } from 'react';
import Icon from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { ReactComponent as DownloadIcon } from 'components/assets/download-csv.svg';
import { useSpreadsheetContext } from 'contexts/Spreadsheet';
import { TabId } from 'types/nav';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';
import SpreadsheetModal from './SpreadsheetModal';

type SpreadsheetDownloadProps = {
  isLink?: boolean;
  iconOnly?: boolean;
  filter: Record<string, any> | undefined;
  filename: TabId;
  extension: string;
  permission?: boolean;
  reportName?: string;
};

const SpreadsheetDownload: React.FC<SpreadsheetDownloadProps> = ({
  isLink,
  iconOnly,
  filter,
  filename,
  extension,
  permission = true,
  reportName,
}) => {
  const {
    loadSpreadsheetQuery,
    dataList,
    cursor,
    hasNextPage,
    spreadsheetQueryLoading,
  } = useSpreadsheetContext();
  const [isModalVisible, setModalVisible] = useState(false);

  // Message related logic
  const translate = useTranslate();

  // Toggle Modal Visibility State
  const toggleModalVisibility = () =>
    setModalVisible((prevState) => !prevState);

  // Run Modal Toggle and Spreadsheet Query
  const generateCsv = () => {
    loadSpreadsheetQuery({ variables: { filter } });
  };

  React.useEffect(() => {
    if (dataList.length >= 1 && hasNextPage && !spreadsheetQueryLoading) {
      loadSpreadsheetQuery({
        variables: { filter, after: cursor },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cursor, hasNextPage]);

  // JSX renders
  const renderIslink = () =>
    isLink ? (
      <Button
        className="text-black"
        type="link"
        onClick={toggleModalVisibility}
        block
      >
        {translate(messages[extension as keyof typeof messages])}
      </Button>
    ) : (
      <Button onClick={toggleModalVisibility}>
        {translate(messages[extension as keyof typeof messages])}
      </Button>
    );

  return (
    <>
      {iconOnly ? (
        <Tooltip
          title={translate(messages[extension as keyof typeof messages])}
        >
          <Icon
            className="mr-1"
            component={DownloadIcon}
            style={{ fontSize: '22px' }}
            onClick={toggleModalVisibility}
          />
        </Tooltip>
      ) : (
        renderIslink()
      )}

      <SpreadsheetModal
        generateCsv={generateCsv}
        permission={permission}
        visible={isModalVisible}
        toggleModalVisibility={toggleModalVisibility}
        filename={filename}
        extension={extension}
        reportName={reportName}
      />
    </>
  );
};

export default SpreadsheetDownload;
