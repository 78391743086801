import gql from 'graphql-tag';

export const GET_MEMBERS_IDS = gql`
  query MEMBER_IDS(
    $filter: MembersFilterInput
    $partialFilter: MembersFilterInput
  ) {
    members(filter: $filter) {
      edges {
        cursor
        node {
          id
        }
      }
    }

    partial: members(filter: $partialFilter) {
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

export const GET_MEMBERS = gql`
  query Members($first: Int, $after: Binary, $filter: MembersFilterInput) {
    members(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          id
          role
          username
          currency
          language
          timezone
          gender
          email
          firstName
          countryCode
          address {
            country
            postCode
            building
            premise
            locality
            stateDistrict
            province
          }
          lastName
          brandId
          platformId
          realName
          preferredLanguage
          affiliateId
          mobileNumber
          wechatId
          qqId
          emailVerified
          mobileNumberVerified
          wechatIdVerified
          qqIdVerified
          lastLoginDateTime
          registrationDateTime
          dateTimeCreated
          dateTimeUpdated
          dateOfBirth
          starred
          watchlistRegistrationDateTime
          totalEffectiveBet
          effectiveBet
          status
          totalBalance
          communicationTypePreferences
          memberLevel {
            id
            name
            color
          }
          affiliate {
            id
            code
          }
          memberLoyaltyLevels {
            id
            name
            color
            programme {
              id
              name
              serialCode
            }
          }
          tags {
            id
            name
            color
            description
          }
          verified
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const GET_MEMBER = gql`
  query Member($id: ID) {
    member(id: $id) {
      id
      role
      username
      currency
      language
      timezone
      realName
      firstName
      lastName
      gender
      email
      dateOfBirth
      status
      realName
      preferredLanguage
      activeCurrency
      depositLimits {
        frequency
        limit
        nextLimit
        used
      }
      depositLimitLock
      depositLimitLockValue
      tags {
        id
        name
        color
      }
      memberLoyaltyLevels {
        id
        name
        color
        programme {
          id
          name
          serialCode
        }
      }
      netDeposits {
        _30_DAYS
        _365_DAYS
        LIFETIME
      }
      totalPromoUsedAmount
      totalPromoUsedCount
      ngr
      migrationDetails
      depositLimit
      depositLimitFrequency
      totalTurnover
      totalCredit
      totalRebatePayout
      realityCheckDuration
      selfExclusionDuration
      selfExclusionEndDateTime
      selfExclusionStartDateTime
      selfExclusionSetBy
      timeOutDuration
      timeOutEndDateTime
      timeOutStartDateTime
      totalMemberLoyaltyPayout
      totalPromoPayout
      forceVerification
      forceVerificationURL
      verified
      brandId
      depositBlocked
      withdrawalBlocked
      wagerBlocked
      promotionBlocked
      exchangeRate
    }
  }
`;

export const GET_MEMBERS_BALANCE = gql`
  query MembersBalance(
    $first: Int
    $after: Binary
    $filter: MembersFilterInput
  ) {
    members(first: $first, after: $after, filter: $filter) {
      totalCount
      totalAccountBalance
      totalEffectiveBet
      totalDepositApproved
      totalDepositRequested
    }
  }
`;

export const GET_MEMBERS_TOTAL_COUNT = gql`
  query MemberCount($filter: MembersFilterInput) {
    members(filter: $filter) {
      totalCount
    }
  }
`;

export const MEMBER_IDS = gql`
  query Members($first: Int, $after: Binary, $filter: MembersFilterInput) {
    members(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
        }
      }
    }
  }
`;

export const BRAND_IDS = gql`
  query FilterMembersBrandIds(
    $first: Int
    $after: Binary
    $filter: MembersFilterInput
  ) {
    members(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          brandId
        }
      }
    }
  }
`;

export const EMAILS = gql`
  query FilterMembersEmail(
    $first: Int
    $after: Binary
    $filter: MembersFilterInput
  ) {
    members(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          email
        }
      }
    }
  }
`;

export const PLATFORM_IDS = gql`
  query FilterMembersPlatformIds(
    $first: Int
    $after: Binary
    $filter: MembersFilterInput
  ) {
    members(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          platformId
        }
      }
    }
  }
`;

export const AFFILIATE_IDS = gql`
  query FilterMembersAffiliateIds(
    $first: Int
    $after: Binary
    $filter: MembersFilterInput
  ) {
    members(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          affiliateId
        }
      }
    }
  }
`;

export const REGISTRATION_IP = gql`
  query FilterMembersRegistrationIp($first: Int, $filter: MembersFilterInput) {
    members(first: $first, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          id
          registrationIpAddress
        }
      }
    }
  }
`;

export const LAST_LOGIN_IP = gql`
  query FilterMembersLastLoginIp($first: Int, $filter: MembersFilterInput) {
    members(first: $first, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          id
          lastLoginIpAddress
        }
      }
    }
  }
`;

export const PHONE_NUMBERS = gql`
  query FilterMembersPhoneNumbers($first: Int, $filter: MembersFilterInput) {
    members(first: $first, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          id
          mobileNumber
        }
      }
    }
  }
`;

export const GET_MEMBER_USERNAME = gql`
  query MemberName($id: ID!) {
    balanceTransactionRecord(id: $id) {
      id
      member {
        id
        username
      }
    }
  }
`;

export const GET_MEMBER_FULL_QUERY = gql`
  query Member($id: ID, $language: Language!) {
    member(id: $id) {
      id
      role
      username
      currency
      language
      timezone
      realName
      gender
      email
      mobileNumber
      wechatId
      qqId
      emailVerified
      mobileNumberVerified
      wechatIdVerified
      qqIdVerified
      lastLoginDateTime
      lastLoginIpAddress
      lastLoginIpAddressLocation {
        city(language: $language)
        country(language: $language)
        state(language: $language)
      }
      lastOnlineDateTime
      registrationDateTime
      registrationIpAddressLocation {
        city(language: $language)
        country(language: $language)
        state(language: $language)
      }
      dateTimeCreated
      dateTimeUpdated
      dateOfBirth
      status
      realName
      registrationIpAddress
      totalBalance
      totalTurnover
      totalCredit
      totalDepositCount
      totalDepositAmount
      totalWithdrawalCount
      totalWithdrawalAmount
      totalRebatePayout
      totalMemberLoyaltyPayout
      totalReadMessagesCount
      totalPromoPayout
      messages {
        totalCount
      }
      memberLoyaltyLevelQualificationProgress {
        deposit
        depositRemaining
        effectiveBet
        effectiveBetRemaining
        liveEffectiveBet
        pokerEffectiveBet
        fishEffectiveBet
        sportsbookEffectiveBet
        slotEffectiveBet
        lotteryEffectiveBet
        percentage
        daysRemaining
        resetDateTime
        nextMemberLoyaltyLevel {
          id
          qualificationDepositRequirement
          qualificationEffectiveBetRequirement

          maintenanceDepositRequirement
          maintenanceEffectiveBetRequirement
        }
        currentMemberLoyaltyLevel {
          inviteOnly
        }
      }
      memberLoyaltyLevelMaintenanceProgress {
        deposit
        depositRemaining
        effectiveBet
        effectiveBetRemaining
        liveEffectiveBet
        pokerEffectiveBet
        fishEffectiveBet
        sportsbookEffectiveBet
        slotEffectiveBet
        lotteryEffectiveBet
        percentage
        daysRemaining
        resetDateTime
        currentMemberLoyaltyLevel {
          inviteOnly
        }
      }
      winloss
      effectiveBet
      betsCount
      bankAccounts {
        edges {
          node {
            id
            bank
            accountName
            accountNumber
          }
        }
      }
      playedGames(first: 3) {
        totalCount
        edges {
          node {
            id
            game {
              id
              type
              name
            }
            lastMemberBetRecord {
              id
              serialCode
              gameType
              winloss
              dateTimePlaced
              game {
                id
                type
                name
              }
              vendor {
                id
                type
                name
                shortcode
              }
            }
          }
        }
      }
      notes(first: 2) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          cursor
          node {
            id
            header
            note
            pinned
            creator {
              id
              username
            }
            dateTimeCreated
            dateTimeUpdated
          }
        }
      }
      memberLevel {
        id
        name
        default
        color
        status
      }
      memberLoyaltyLevel {
        id
        name
        description
        inviteOnly

        color
        qualificationCriteria {
          type
          amount
          gameTypes
          and {
            type
            amount
            gameTypes
          }
        }
        maintenanceCriteria {
          type
          amount
          gameTypes
          and {
            type
            amount
            gameTypes
          }
        }
        programme {
          id
          serialCode
          name
          description
          validityStartDate
          validityEndDate
          evaluationPeriod
          automaticMemberLoyaltyLevelUpgrade
          automaticMemberLoyaltyLevelDowngrade
          levels {
            id
            name
          }
          status
          membersCount
          dateTimeCreated
          dateTimeUpdated
        }
      }
      tags {
        id
        name
        color
        description
      }
      rebatePayouts(first: 10) {
        edges {
          cursor
          node {
            id
            amount
            dateTimeClaimed
            rebateGroup {
              id
              name
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
        totalCount
      }
      memberLoyaltyPayouts(first: 10) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          cursor
          node {
            id
            amount
            dateTimeClaimed
            memberLoyaltyLevel {
              id
              name
              programme {
                id
                name
              }
            }
            turnover
            type
          }
        }
      }
      balanceUnits {
        id
        amount
        expireAt
        originalAmount
        source
        turnoverRequirementBalance
        scope {
          game
          gameType
          vendor
          channel
        }
        timestamp
        turnoverRequirement
        turnoverRequirementMultiplier
      }
    }
  }
`;

export const GET_MEMBER_BALANCE = gql`
  query member($id: String!) {
    member(id: $id) {
      id
      ... on Member {
        wallets {
          mainCash
          casinoCash
          sportsCash
          casinoBonus
          sportsBonus
        }
      }
    }
  }
`;
export const GET_MEMBER_BALANCE_NEXT = gql`
  query getMemberBalanceNext($id: String!, $currency: Currency) {
    member(id: $id) {
      id
      ... on Member {
        wallets(currency: $currency) {
          mainCash
          casinoCash
          sportsCash
          casinoBonus
          sportsBonus
        }
      }
    }
  }
`;

export const GET_MEMBER_FAV_CURRENCIES = gql`
  query MemberFavCurrency($id: ID!) {
    member(id: $id) {
      id
      favoriteCurrencyDetails {
        id
        code
        name
        digitalCode
        symbol
      }
    }
  }
`;

export const GET_MEMBER_ACTIVE_PROMO = gql`
  query member($id: String!, $language: Language) {
    member(id: $id) {
      id
      activePromo {
        id
        promo {
          name(language: $language)
          minimumRealMoneyBalance
          minimumBonusBalance
        }
        turnoverTarget
        turnoverCounter
        amount
        bonus
        status
        expiresAt
        currency
      }
    }
  }
`;

export const GET_MEMBER_PREFERRED_LANGUAGE = gql`
  query GetMemberPreferredLanguage($id: ID!) {
    member(id: $id) {
      id
      preferredLanguage
    }
  }
`;

export const GET_MEMBER_PROMO_HISTORY = gql`
  query MemberPromoHistory(
    $id: String!
    $filter: MemberPromoFilterInput
    $first: Int
    $after: Cursor
    $language: Language
  ) {
    member(id: $id) {
      id
      promoHistory(filter: $filter, after: $after, first: $first) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            ... on MemberPromo {
              id
              promo {
                id
                name(language: $language)
              }
              currency
              amount
              bonus
              turnoverTarget
              turnoverCounter
              status
              expiresAt
              bonusWon
              dateTimeCreated
              dateTimeResolved
            }
          }
        }
      }
    }
  }
`;
export const GET_MEMBERS_PROMO_HISTORY = gql`
  query MembersPromoHistory(
    $filter: MemberPromoFilterInput
    $first: Int
    $after: Cursor
    $language: Language
  ) {
    memberPromoHistory(filter: $filter, first: $first, after: $after) {
      edges {
        node {
          ... on MemberPromo {
            id
            promo {
              id
              name(language: $language)
              minimumBonusBalance
              minimumDepositAmount
            }
            currency
            amount
            bonus
            turnoverTarget
            turnoverCounter
            status
            expiresAt
            bonusWon
            dateTimeCreated
            dateTimeResolved
            walletMode
            member {
              username
            }
          }
        }
      }
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
