/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useMemberCurrency from 'utils/useMemberCurrency';
import { DownOutlined } from '@ant-design/icons';

interface NetDepositCardContentProps {
  netDeposits: {
    LIFETIME: number;
    _30_DAYS: number;
    _365_DAYS: number;
  };
  label: string;
  memberCurrency: string;
}

export const NetDepositCardContent: React.FC<NetDepositCardContentProps> = ({
  netDeposits,
  label,
  memberCurrency,
}) => {
  const { addCurrency } = useMemberCurrency();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className="d-flex flex-column h-100 overflow-hidden w-100"
      id="net-deposit"
    >
      <div
        role="button"
        className="d-flex justify-content-between align-items-center cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
        tabIndex={0}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            setIsOpen(!isOpen);
          }
        }}
      >
        <h4 className="text-muted fs-14 mr-1">{label}</h4>
        <DownOutlined
          style={{
            fontSize: '12px',
            transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'all 0.3s ease-in-out',
          }}
        />
      </div>

      {isOpen && (
        <>
          <div
            className="d-flex justify-content-between "
            style={{ gap: '4px' }}
          >
            <h5 className="fs-12 mb-0">
              <FormattedMessage id="30-days.text" defaultMessage="30 days" />
            </h5>{' '}
            <h5 className="fs-12 mb-0">
              {addCurrency(memberCurrency, netDeposits._30_DAYS)}
            </h5>
          </div>
          <div
            className="d-flex justify-content-between"
            style={{ gap: '4px' }}
          >
            <h5 className="fs-12 mb-0">
              <FormattedMessage id="365-days.text" defaultMessage="365 days" />{' '}
            </h5>
            <h5 className="fs-12 mb-0">
              {addCurrency(memberCurrency, netDeposits._365_DAYS)}
            </h5>
          </div>
          <div
            className="d-flex justify-content-between"
            style={{ gap: '4px' }}
          >
            <h5 className="fs-12 mb-0">
              <FormattedMessage id="lifetime.text" defaultMessage="Lifetime" />
            </h5>{' '}
            <h5 className="fs-12 mb-0">
              {addCurrency(memberCurrency, netDeposits.LIFETIME)}
            </h5>
          </div>
        </>
      )}
      {!isOpen && (
        <div className="d-flex justify-content-between" style={{ gap: '4px' }}>
          <h4 className="fs-14 mb-0">
            <FormattedMessage id="total.text" defaultMessage="Total" />
          </h4>
          <h4 className="fs-14 mb-0">
            {addCurrency(memberCurrency, netDeposits.LIFETIME)}
          </h4>
        </div>
      )}
    </div>
  );
};
