import styled from 'styled-components';

export const CashBalanceCards = styled.div`
  border: 1px solid #f0f0f0;
  padding: 19px 16px 15px 16px;
  border-radius: 4px;
  width: 140px;
  margin-bottom: 8px;
  margin-right: 6px;
`;
