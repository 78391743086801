/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import {
  CheckCircleOutlined,
  DollarTwoTone,
  EllipsisOutlined,
  BankOutlined,
} from '@ant-design/icons';
import { Tooltip, Dropdown, Menu } from 'antd';
import moment from 'moment';
import { StyledTable } from 'constants/styles';
import { defineMessages } from 'react-intl';
import { subtract, sum, values } from 'lodash';
import { useCustomColumnsV2 } from 'store/customColumnState/customColumnState';
import { useAccount, usePermissions } from 'store/accountState';
import { useConfig } from 'hooks/useConfig';
import ALL_PERMISSIONS from 'constants/permissions3';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import MemberLoyaltyTagList from 'components/MemberLoyaltyTagList/MemberLoyaltyTagList';
import { showPermissionError } from 'components/Navbar/Navbar';
import { useSpreadsheetContext } from 'contexts/Spreadsheet';
import globalMessages from 'messages';
import useTranslate from 'utils/useTranslate';
import coercedGet from 'utils/coercedGet';
import { columnAlign } from 'utils/tableAlignment';
import { ALink } from 'components/ALink/ALink';
import AffiliateIndicatorBatch from 'components/AffiliateIndicatorBatch';
import MemberLevelButton from 'components/MemberLevelButton';
import { isVipVisible } from 'utils/isVipVisible';
import esGet from 'utils/esGet';
import { WithdrawalStatus, Withdrawal, Admin } from 'types/graphqlTypes';
import isRow from 'utils/isRow';
import { OriginalColumn } from 'store/customColumnState/types';
import { currencies } from 'constants/currency';
import { DATE_FORMAT } from 'constants/date';
import PaymentSource from './components/PaymentSource';
import MemberStatus from './components/MemberStatus';
import WithdrawalFeesIndicator from './components/MemberFeeIndicator';
import messages from '../../messages';
import MemberLabelRemarks from './components/MemberLabelRemarks';
import MemberProfile from './components/MemberProfile';
import MemberPaymentAmount from './components/MemberPaymentAmount';
import StopProcessing from './components/StopProcessing';
import ComplianceWarning from './components/ComplianceWarning';
import ApproveWithdrawal from './components/ApproveWithdrawal';
import RejectWithdrawal from './components/RejectWithdrawal';
import WithdrawalDetails from '../WithdrawalDetails';
import OnHoldWithdrawal from './components/OnHoldWithdrawal';

const isFailed = (value: string) => value === 'FAILED';

const getProcessingTime = (withdrawal: Record<string, any>) => {
  const { dateTimeCreated, dateTimeProcessed, status } = withdrawal;

  if (status === 'APPROVED' || status === 'REJECTED')
    return moment(dateTimeProcessed).from(dateTimeCreated, true);
  if (status === 'EXPIRED') return '-';

  return moment(dateTimeCreated).fromNow();
};

const messagesLocal = defineMessages({
  'first-withdrawal.text': {
    id: 'first-withdrawal.text',
    defaultMessage: 'First Withdrawal',
  },
  'serial-code.text': {
    id: 'serial-code.text',
    defaultMessage: 'Serial Code',
  },
  'affiliate.text': {
    id: 'affiliate.text',
    defaultMessage: 'Affiliate',
  },
  'member-account.text': {
    id: 'member-account.text',
    defaultMessage: 'Member Account',
  },
  'vip-level.text': {
    id: 'vip-level.text',
    defaultMessage: 'VIP Level',
  },
  'manual-adjustment.text': {
    id: 'manual-adjustment.text',
    defaultMessage: 'Manual Adjustment',
  },
});

const computePayoutAmount = (
  withdrawal: Record<string, any>,
  renderNumeral: Function,
  isBase?: boolean
) => {
  const {
    handlingFeeApplied,
    withdrawalTaxFeeApplied,
    promoDeductionFeeApplied,
    withdrawalLimitFeeApplied,
    amountInBaseCurrency,
    amount,
  } = withdrawal;

  const newValue = values({
    handling: handlingFeeApplied || 0,
    tax: withdrawalTaxFeeApplied || 0,
    promoDeduction: promoDeductionFeeApplied || 0,
    limit: withdrawalLimitFeeApplied || 0,
  });
  const newPayoutAmount = subtract(
    isBase ? amountInBaseCurrency : amount,
    sum(newValue)
  );

  return newPayoutAmount < 0 ? '0.00' : renderNumeral(newPayoutAmount);
};

type Props = {
  dataSource: any;
  loading: boolean;
  isActiveTab: boolean;
  refetchVariables: any;
};

const WithdrawalsTable = ({
  dataSource,
  loading,
  isActiveTab,
  refetchVariables,
}: Props) => {
  const {
    account: { account: adminAccount },
  } = useAccount() as { account: { account: Admin } };

  const { setTableColumns } = useSpreadsheetContext();
  const translate = useTranslate();
  const { currency, baseCurrency, renderNumeral } = useConfig();
  const { role, permissions } = usePermissions();
  const {
    ALLOWED_LIST,
    ALLOWED_VIEW_DETAILS,
    ALLOWED_APPROVE,
    ALLOWED_REJECT,
    ALLOWED_PROCESS,
    ALLOWED_ON_HOLD,
  } = collectPermissions(
    role,
    permissions,
    ['LIST', 'VIEW_DETAILS', 'APPROVE', 'REJECT', 'PROCESS', 'ON_HOLD'],
    ALL_PERMISSIONS.ALL_WITHDRAWALS.WITHDRAWALS_WITHDRAWAL_REQUESTS
  );
  const [state, setState] = useState<Record<string, any>>({
    isViewWithdrawalDetails: false,
    withdrawalId: null,
    selectedRowKeys: [],
  });

  const toggleView = (id = null) => {
    if (!ALLOWED_VIEW_DETAILS) {
      showPermissionError('Withdrawal Requests - View Details');
      return;
    }
    setState({
      ...state,
      isViewWithdrawalDetails: !state.isViewWithdrawalDetails,
      withdrawalId: id,
    });
  };

  const rowSelection = {
    selectedRowKeys: state.selectedRowKeys,
    columnWidth: '32px',
    onChange: (e: any) => setState({ ...state, selectedRowKeys: e }),
  };

  const data = dataSource
    ? dataSource.map(({ node }: { node: Record<string, any> }) => ({
        ...node,
        key: node.id,
      }))
    : [];

  const noManualAndFirstWithdrawal = (withdrawal: Record<string, any>) =>
    !withdrawal.manualAdjustment && !withdrawal.firstWithdrawal;

  const columns = [
    {
      csvData: {
        label: translate(messagesLocal['first-withdrawal.text']),
        key: 'firstWithdrawal',
        renderCell: (withdrawal: Record<string, any>) =>
          coercedGet(withdrawal, 'firstWithdrawal', '')
            ? translate(messages.yes)
            : translate(messages.no),
      },
      key: 'firstWithdrawal',
      disabled: true,
      title: '',
      align: columnAlign.onCenter,
      customTitle: translate(messagesLocal['first-withdrawal.text']),
      width: 60,
      render: (withdrawal: Record<string, any>) => (
        <div className="d-flex justify-content-center" style={{ gap: '5px' }}>
          {withdrawal.manualAdjustment && (
            <Tooltip
              arrowPointAtCenter
              title={translate(messagesLocal['manual-adjustment.text'])}
            >
              <CheckCircleOutlined />
            </Tooltip>
          )}

          {withdrawal.manualBankTransfer && (
            <Tooltip arrowPointAtCenter title="Manual Bank Transfer">
              <BankOutlined />
            </Tooltip>
          )}
          {withdrawal.firstWithdrawal && withdrawal.status !== 'REJECTED' && (
            <Tooltip
              arrowPointAtCenter
              title={translate(messagesLocal['first-withdrawal.text'])}
            >
              <DollarTwoTone twoToneColor="#FF0000" />
            </Tooltip>
          )}

          {noManualAndFirstWithdrawal(withdrawal) && ''}
        </div>
      ),
    },
    {
      csvData: {
        label: translate(messagesLocal['serial-code.text']),
        key: 'serialCode',
        renderCell: (withdrawal: Withdrawal) => withdrawal.serialCode,
      },
      key: 'serialCode',
      align: columnAlign.onCenter,
      disabled: true,
      width: 95,
      title: translate(messagesLocal['serial-code.text']),
      render: (_text: string, withdrawal: Record<string, null>) => (
        <ALink
          role="button"
          aria-label={withdrawal.serialCode}
          onClick={() => toggleView(withdrawal.id)}
        >
          {withdrawal.serialCode}
        </ALink>
      ),
    },
    {
      csvData: {
        label: translate(messagesLocal['affiliate.text']),
        key: 'affiliate',
        renderCell: (withdrawal: Record<string, any>) =>
          coercedGet(withdrawal, 'account.affiliate.code', '-'),
      },
      disabled: true,
      title: '',
      align: columnAlign.onCenter,
      customTitle: translate(messagesLocal['affiliate.text']),
      key: 'affiliateIndicator',
      width: 20,
      render: (withdrawalDetails: Withdrawal) => (
        <AffiliateIndicatorBatch data={withdrawalDetails} />
      ),
    },
    {
      csvData: {
        label: translate(messagesLocal['member-account.text']),
        key: 'username',
        renderCell: (withdrawal: Record<string, any>) =>
          coercedGet(withdrawal, 'account.username', '-'),
      },
      key: 'username',
      align: columnAlign.onCenter,
      disabled: true,
      width: 100,
      title: translate(messagesLocal['member-account.text']),
      render: (withdrawal: Record<string, any>) => (
        <MemberProfile withdrawalId={withdrawal.id} />
      ),
    },
    {
      csvData: {
        label: 'Brand ID',
        key: 'brandId',
        renderCell: (withdrawal: Record<string, any>) =>
          coercedGet(withdrawal, 'account.brandId', '-'),
      },
      key: 'brandId',
      align: columnAlign.onCenter,
      width: 100,
      title: 'Brand ID',
      render: (withdrawal: Record<string, any>) => withdrawal.account?.brandId,
    },
    {
      csvData: {
        label: 'Platform ID',
        key: 'platformId',
        renderCell: (withdrawal: Record<string, any>) =>
          coercedGet(withdrawal, 'account.platformId', '-'),
      },
      key: 'platformId',
      align: columnAlign.onCenter,
      width: 100,
      title: 'Platform ID',
      render: (withdrawal: Record<string, any>) =>
        withdrawal.account?.platformId,
    },
    {
      ...(Boolean(isVipVisible) && {
        csvData: {
          label: translate(messages.VIP_TIER),
          key: 'vipLevel',
          renderCell: (withdrawal: Record<string, any>) => {
            const memberLevels = coercedGet(
              withdrawal,
              'account.memberLoyaltyLevels',
              '-'
            );
            return memberLevels.map((item: Record<string, any>) => item.name);
          },
        },
        width: 95,
        align: columnAlign.onCenter,
        title: translate(messages.VIP_TIER),
        key: 'vipLevel',
        render: (withdrawal: Withdrawal) =>
          withdrawal.account.memberLoyaltyLevels?.length ? (
            <MemberLoyaltyTagList
              loyaltyLevels={withdrawal.account.memberLoyaltyLevels}
            />
          ) : (
            '-'
          ),
      }),
    },
    {
      csvData: {
        label: translate(messages.memberMarker),
        key: 'memberMarker',
        renderCell: (withdrawal: Record<string, any>) =>
          coercedGet(withdrawal, 'account.memberLevel.name', '-'),
      },
      width: 110,
      align: columnAlign.onCenter,
      key: 'memberMarker',
      title: translate(messages.memberMarker),
      render: (withdrawal: Withdrawal) => (
        <MemberLevelButton memberLevelData={withdrawal.account.memberLevel} />
      ),
    },
    {
      csvData: {
        label: translate(messages.feeDeduction),
        key: 'feeDeduction',
        renderCell: (withdrawal: Record<string, any>) => {
          const {
            handlingFeeApplied,
            withdrawalTaxFeeApplied,
            promoDeductionFeeApplied,
            withdrawalLimitFeeApplied,
            handlingFee,
            withdrawalTaxFee,
            promoDeductionFee,
            withdrawalLimitFee,
          } = withdrawal;

          const handling =
            handlingFeeApplied === null ? handlingFee : handlingFeeApplied;
          const tax =
            withdrawalTaxFeeApplied === null
              ? withdrawalTaxFee
              : withdrawalTaxFeeApplied;
          const promoDeduction =
            promoDeductionFeeApplied === null
              ? promoDeductionFee
              : promoDeductionFeeApplied;
          const limit =
            withdrawalLimitFeeApplied === null
              ? withdrawalLimitFee
              : withdrawalLimitFeeApplied;

          return `${translate(messages.handlingFee)}: ${handling ||
            0.0} | ${translate(messages.withdrawalTax)}: ${tax ||
            0} | ${translate(messages.promoDeduction)}: ${promoDeduction ||
            0.0} | ${translate(messages.withdrawalLimitFee)}: ${limit || 0.0}`;
        },
      },
      title: translate(messages.feeDeduction),
      key: 'feeDeduction',
      width: 120,
      align: columnAlign.onCenter,
      render: (withdrawal: Record<string, any>) => (
        <WithdrawalFeesIndicator
          withdrawalID={withdrawal.id}
          toolTipId="withdrawal-request"
        />
      ),
    },
    {
      csvData: {
        label: translate(messages.complianceCheck),
        key: 'complianceCheck',
        renderCell: (withdrawal: Record<string, any>) => {
          const {
            oneTimeTurnoverDetectionCheck,
            promoHunterCheck,
            contraBetCheck,
            ipCollisionOffenderCheck,
            martingaleBetOffenderCheck,
          } = withdrawal;

          const fails = [];

          if (isFailed(oneTimeTurnoverDetectionCheck))
            fails.push(translate(messages.oneTimeTurnoverDetection));
          if (isFailed(promoHunterCheck))
            fails.push(translate(messages.promoHunter));
          if (isFailed(contraBetCheck))
            fails.push(translate(messages.contraBet));
          if (isFailed(ipCollisionOffenderCheck))
            fails.push(translate(messages.ipCollisionOffender));
          if (isFailed(martingaleBetOffenderCheck))
            fails.push(translate(messages.martingaleBetOffender));

          return fails;
        },
      },
      key: 'complianceCheck',
      width: 100,
      align: columnAlign.onCenter,
      title: translate(messages.complianceCheck),
      render: (withdrawal: Record<string, any>) => (
        <ComplianceWarning withdrawal={withdrawal} />
      ),
    },
    {
      csvData: {
        label: isRow
          ? `${translate(messages.withdrawalAmount)} (Member)`
          : `${translate(messages.withdrawalAmount)}(${currency?.symbol})`,
        key: 'payoutAmount',
        renderCell: (withdrawal: Record<string, any>) =>
          computePayoutAmount(withdrawal, renderNumeral, false),
      },
      key: 'payoutAmount',
      title: isRow
        ? `${translate(messages.withdrawalAmount)} (Member)`
        : `${translate(messages.withdrawalAmount)}(${currency?.symbol})`,
      width: 100,
      align: columnAlign.onCenter,
      render: (withdrawal: Withdrawal) => (
        <MemberPaymentAmount
          amount={withdrawal.amount}
          currency={withdrawal.currency!}
        />
      ),
    },
    {
      ...(isRow &&
        adminAccount?.multiCurrencyEnabled && {
          csvData: {
            label: `${translate(messages.withdrawalAmount)} (${
              currencies[baseCurrency! as keyof typeof currencies]
            }) (Base)`,
            key: 'amountInBaseCurrency',
            renderCell: (withdrawal: Record<string, any>) =>
              computePayoutAmount(withdrawal, renderNumeral, true),
          },
          key: 'amountInBaseCurrency',
          title: `${translate(messages.withdrawalAmount)} (${
            currencies[baseCurrency! as keyof typeof currencies]
          }) (Base)`,
          width: 100,
          align: columnAlign.onCenter,
          render: (withdrawal: Withdrawal) => (
            <MemberPaymentAmount
              amount={withdrawal.amountInBaseCurrency}
              currency={baseCurrency!}
            />
          ),
        }),
    },
    {
      csvData: {
        label: 'Payment Account Details',
        key: 'hexoPayCreditCard',
        renderCell: (withdrawal: any) => {
          const hexoPayCreditCard = esGet(withdrawal?.hexoPayCreditCard, {});
          const withdrawalType = esGet(withdrawal?.withdrawalMethod, {});

          const isCryptoWithdrawal =
            withdrawalType.__typename === 'CryptocurrencyWithdrawalMethod';

          if (isCryptoWithdrawal) return withdrawal.transactionDetails || '-';

          const {
            firstDigit,
            lastFourDigits,
            expirationYear,
            expirationMonth,
          } = hexoPayCreditCard || {};

          if (withdrawal.status === 'EXPIRED') return 'Expired Transaction';

          if (
            withdrawal.withdrawalMethod.__typename ===
            'ManualAdjustmentWithdrawalMethod'
          )
            return '-';

          return hexoPayCreditCard
            ? `${firstDigit}-${lastFourDigits} ${expirationMonth}/${expirationYear}`
            : withdrawal.withdrawalMethod.name === 'Neteller' ||
              withdrawal.withdrawalMethod.name === 'Skrill'
            ? withdrawal.account.initialEmail
            : '-';
        },
      },
      title: 'Payment Account Details',
      key: 'hexoPayCreditCard',
      width: 150,
      align: columnAlign.onCenter,
      render: (withdrawal: any) => {
        const withdrawalType = esGet(withdrawal?.withdrawalMethod, {});
        const { hexoPayCreditCard } = withdrawal;

        const isCryptoWithdrawal =
          withdrawalType.__typename === 'CryptocurrencyWithdrawalMethod';

        if (isCryptoWithdrawal) return withdrawal.transactionDetails || '-';

        const {
          firstSixDigits,
          lastFourDigits,
          expirationYear,
          expirationMonth,
        } = hexoPayCreditCard || {};
        if (hexoPayCreditCard)
          return `${firstSixDigits} *** *** ${lastFourDigits} ${expirationMonth}/${expirationYear}`;
        if (withdrawal.status === 'EXPIRED') return 'Expired Transaction';
        if (
          withdrawal.withdrawalMethod.name === 'Neteller' ||
          withdrawal.withdrawalMethod.name === 'Skrill'
        )
          return <span>{withdrawal.account.initialEmail}</span>;
        return '-';
      },
    },
    {
      csvData: {
        label: 'Payment Method Type',
        key: 'withdrawalMethod',
        renderCell: (withdrawal: Withdrawal) =>
          coercedGet(withdrawal, 'withdrawalMethod.name', '-'),
      },
      key: 'withdrawalMethod',
      title: 'Payment Method Type',
      width: 150,
      align: columnAlign.onCenter,
      render: (withdrawal: Withdrawal) =>
        withdrawal?.withdrawalMethod?.name || '-',
    },
    {
      csvData: {
        label: `${!isRow ? 'Hexopay' : ''} Transaction UID`,
        key: 'transactionReference',
        renderCell: (withdrawal: object) =>
          coercedGet(withdrawal, 'transactionReference', '-'),
      },
      title: `${!isRow ? 'Hexopay' : ''} Transaction UID`,
      key: 'transactionReference',
      width: 150,
      align: columnAlign.onCenter,
      render: (withdrawal: Record<string, string>) => (
        <span>{withdrawal.transactionReference || '-'}</span>
      ),
    },

    {
      csvData: {
        label: translate(globalMessages.PAYMENT_SOURCE),
        key: 'withdrawalSource',
        // todo: add payment source for csvData
        renderCell: (withdrawal: Record<string, any>) =>
          translate({
            id: `${coercedGet(withdrawal, 'status', '').toLowerCase()}.text`,
          } as any),
      },
      key: 'withdrawalSource',
      title: translate(globalMessages.WITHDRAWAL_SOURCE),
      width: 100,
      align: columnAlign.onCenter,
      render: (withdrawal: Record<string, any>) => {
        const { id, manualAdjustment } = withdrawal;
        return (
          <PaymentSource id={id || ''} manualAdjustment={manualAdjustment} />
        );
      },
    },
    {
      csvData: {
        label: translate(messages.status),
        key: 'status',
        renderCell: (withdrawal: Record<string, any>) => {
          const status = `${coercedGet(
            withdrawal,
            'status',
            ''
          ).toLowerCase()}.text`;
          return translate({
            id: status,
          } as any);
        },
      },
      key: 'status',
      title: translate(messages.status),
      align: columnAlign.onCenter,
      width: 60,
      render: (withdrawal: Withdrawal) => (
        <MemberStatus withdrawalDetails={withdrawal} />
      ),
    },
    {
      csvData: {
        label: translate(messages.labelsRemarks),
        key: 'remarks',
        renderCell: (withdrawal: Record<string, any>) =>
          withdrawal.externalRemarks || withdrawal.remarks,
      },
      key: 'remarks',
      align: columnAlign.onCenter,
      title: translate(messages.labelsRemarks),
      width: 125,
      render: (withdrawal: Record<string, any>) => (
        <MemberLabelRemarks withdrawal={withdrawal} />
      ),
    },
    {
      csvData: {
        label: translate(globalMessages['request-datetime.text']),
        key: 'requestDate',
        renderCell: (withdrawal: Record<string, any>) =>
          `${moment(withdrawal.dateTimeCreated).format(DATE_FORMAT)} - ${moment(
            withdrawal.dateTimeCreated
          ).format('HH:mm:ss')}`,
      },

      key: 'requestDate',
      align: columnAlign.onCenter,
      title: translate(globalMessages['request-datetime.text']),
      dataIndex: 'dateTimeCreated',
      width: 100,
      render: (dateTimeCreated: string) => (
        <span>
          {moment(dateTimeCreated).format(DATE_FORMAT)}
          <br />
          {moment(dateTimeCreated).format('HH:mm:ss')}
        </span>
      ),
    },
    {
      ...(isRow && {
        csvData: {
          label: 'Member Currency',
          key: 'currency',
          renderCell: (withdrawal: Withdrawal) => withdrawal.currency ?? '-',
        },
        title: 'Member Currency',
        key: 'currency',
        dataIndex: 'currency',
        align: columnAlign.onCenter,
        width: 95,
        render: (currency: string) => <span>{currency ?? '-'}</span>,
      }),
    },
    {
      ...(isRow &&
        adminAccount?.multiCurrencyEnabled && {
          csvData: {
            label: 'Exchange Rate',
            key: 'exchangeRate',
            renderCell: (withdrawal: Withdrawal) =>
              withdrawal.exchangeRate ?? '-',
          },
          title: 'Exchange Rate',
          key: 'exchangeRate',
          dataIndex: 'exchangeRate',
          align: columnAlign.onCenter,
          width: 95,
          render: (exchangeRate: string) => <span>{exchangeRate ?? '-'}</span>,
        }),
    },
    {
      csvData: {
        label: translate(globalMessages.ELAPSED_TIME),
        key: 'processingTime',
        renderCell: (withdrawal: Record<string, any>) =>
          getProcessingTime(withdrawal),
      },
      title: translate(globalMessages.ELAPSED_TIME),
      key: 'elapsedTime',
      align: columnAlign.onCenter,
      width: 95,
      render: (withdrawal: Record<string, any>) =>
        getProcessingTime(withdrawal),
    },
    {
      title: 'Processing Time',
      key: 'processingTime',
      align: columnAlign.onCenter,
      dataIndex: 'processingTime',
      width: 95,
      render: (processingTime: string) => `${processingTime} min(s)`,
    },
    {
      title: translate(messages.actions),
      align: columnAlign.onCenter,
      fixed: 'right',
      key: 'actions',
      hidden: true,
      width: 80,
      render: (item: Record<string, any>) => {
        const isVerificationPending =
          item.remarks === 'GBG verification pending';
        const isMemberVerified = item.account.verified;
        const isProcessing = item.status === WithdrawalStatus.Processing;
        const isPending = item.status === WithdrawalStatus.Pending;
        const isOnHold = item.status === WithdrawalStatus.OnHold;

        const gbgVerificationRequired =
          !isMemberVerified && isOnHold && isVerificationPending;

        return (
          ALLOWED_PROCESS && (
            <Dropdown
              overlay={
                <Menu>
                  {ALLOWED_PROCESS && ALLOWED_APPROVE && (
                    <Menu.Item
                      disabled={
                        (!isProcessing && !isPending && !isOnHold) ||
                        gbgVerificationRequired
                      }
                    >
                      <ApproveWithdrawal
                        isPending={isPending}
                        id={item.id}
                        disabled={!isProcessing && !isPending && !isOnHold}
                        refetchVariables={refetchVariables}
                      />
                    </Menu.Item>
                  )}
                  {ALLOWED_PROCESS && ALLOWED_REJECT && (
                    <Menu.Item
                      disabled={
                        (!isProcessing && !isPending && !isOnHold) ||
                        gbgVerificationRequired
                      }
                    >
                      <RejectWithdrawal
                        id={item.id}
                        remarks={item.remarks}
                        disabled={!isProcessing && !isPending && !isOnHold}
                        refetchVariables={refetchVariables}
                        isProcessing={isProcessing}
                        isOnHold={isOnHold}
                      />
                    </Menu.Item>
                  )}

                  {ALLOWED_PROCESS && ALLOWED_ON_HOLD && (
                    <Menu.Item
                      disabled={
                        (!isProcessing && !isPending) || gbgVerificationRequired
                      }
                    >
                      <OnHoldWithdrawal
                        id={item.id}
                        disabled={!isProcessing && !isPending}
                        refetchVariables={refetchVariables}
                        isProcessing={isProcessing}
                      />
                    </Menu.Item>
                  )}

                  {ALLOWED_PROCESS && (
                    <Menu.Item
                      disabled={!isProcessing || gbgVerificationRequired}
                    >
                      <StopProcessing id={item.id} disabled={!isProcessing} />
                    </Menu.Item>
                  )}
                </Menu>
              }
              placement="bottomRight"
            >
              <div>
                <EllipsisOutlined
                  className="cursor-pointer font-weight-bold"
                  style={{ fontSize: '25px' }}
                />
              </div>
            </Dropdown>
          )
        );
      },
    },
  ];

  useEffect(() => {
    setTableColumns(columns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { filterColumns } = useCustomColumnsV2(
    'withdrawal-requests',
    columns as OriginalColumn[]
  );

  return (
    <>
      {state.isViewWithdrawalDetails && isActiveTab && (
        <WithdrawalDetails
          id={state.withdrawalId}
          toggleModal={toggleView}
          refetchVariables={refetchVariables}
        />
      )}
      {ALLOWED_LIST && (
        <StyledTable
          loading={loading}
          rowSelection={rowSelection}
          columns={filterColumns(columns as OriginalColumn[])}
          size="small"
          dataSource={data}
          pagination={false}
          scroll={{ y: 'calc(100vh - 288px)', x: 1600 }}
          rowKey="serialCode"
        />
      )}
    </>
  );
};

export default WithdrawalsTable;
