import React from 'react';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  HexoPayCreditCardsConnection,
  PaymentMethodType,
  PaymentMethodsConnection,
  QueryAvailablePaymentMethodsArgs,
  QueryHexoPayCreditCardsArgs,
} from 'types/graphqlTypes';
import { Checkbox, message, Popconfirm, Tooltip, Typography } from 'antd';
import { StyledCardFlex } from 'pages/components/NewMemberDetail/components/MemberDetailContent/components/MemberInfo/styles';
import { DeleteOutlined, IssuesCloseOutlined } from '@ant-design/icons';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import { useNewMemberDetailPermissions } from 'pages/components/NewMemberDetail/constants';
import styled from 'styled-components';
import PermissionError from 'components/PermissionError';

const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
`;

const AVAILABLE_PAYMENT_METHODS = gql`
  query AvailablePaymentMethods($filter: AvailablePaymentMethodsFilterInput!) {
    availablePaymentMethods(filter: $filter) {
      edges {
        node {
          id
        }
      }
    }
  }
`;

const HEXOPAY_CARDS = gql`
  query HexopayCards(
    $first: Int
    $after: Binary
    $filter: HexoPayCreditCardsFilterInput
  ) {
    hexoPayCreditCards(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          brand
          expirationMonth
          expirationYear
          firstDigit
          firstSixDigits
          lastFourDigits
          disableClosedLoop
          disableCard
        }
      }
    }
  }
`;

const DELETE_HEXOPAY_CARD = gql`
  mutation DeleteHexopayCard($input: DeleteHexoPayCreditCardInput!) {
    deleteHexoPayCreditCard(input: $input)
  }
`;
const TOGGLE_HEXOPAY_CARD_CLOSED_LOOP = gql`
  mutation toggleHexopayCardClosedLoop(
    $input: TogglePaymentMethodClosedLoopInput!
  ) {
    togglePaymentMethodClosedLoop(input: $input)
  }
`;

type HexopayCardsProps = {
  memberId: string;
};

const HexopayCards: React.FC<HexopayCardsProps> = ({ memberId }) => {
  const { context } = useOperatorHeader();
  const { HEXOPAY_CARDS_PERMISSION } = useNewMemberDetailPermissions();

  const { data: availablePaymentMethods } = useQuery<
    {
      availablePaymentMethods: PaymentMethodsConnection;
    },
    QueryAvailablePaymentMethodsArgs
  >(AVAILABLE_PAYMENT_METHODS, {
    skip: !memberId,
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        account: memberId,
        paymentMethodType: {
          in: ['HEXOPAY' as PaymentMethodType],
        },
      },
    },
    context,
  });
  const rawAvailablePaymentMethodsData =
    availablePaymentMethods?.availablePaymentMethods?.edges || [];
  const hexopayPaymentMethodId = rawAvailablePaymentMethodsData[0]?.node.id;

  const { data, refetch } = useQuery<
    {
      hexoPayCreditCards: HexoPayCreditCardsConnection;
    },
    QueryHexoPayCreditCardsArgs
  >(HEXOPAY_CARDS, {
    skip: !memberId,
    fetchPolicy: 'network-only',
    variables: {
      first: 100,
      filter: {
        account: {
          eq: memberId,
        },
      },
    },
    context,
  });
  const [deleteHexopayCard] = useMutation(DELETE_HEXOPAY_CARD, {
    context,
  });
  const [toggleHexoClosedLoop] = useMutation(TOGGLE_HEXOPAY_CARD_CLOSED_LOOP, {
    context,
  });
  const rawData = data?.hexoPayCreditCards?.edges || [];
  const hexopayCards = rawData.map((x) => x!.node);

  const onDeleteCard = (cardId: string) => {
    deleteHexopayCard({
      variables: {
        input: {
          id: cardId,
          account: memberId,
        },
      },
    }).then(() => {
      message.success('Successfully removed card');
      refetch();
    });
  };

  const handleToggleClosedLoop = async ({
    hexoPayCardId,
    paymentMethod,
    disableClosedLoop,
    disableCard,
  }: {
    hexoPayCardId: string;
    paymentMethod: string;
    disableClosedLoop?: boolean;
    disableCard?: boolean;
  }) => {
    try {
      await toggleHexoClosedLoop({
        variables: {
          input: {
            hexoPayCardId,
            account: memberId,
            paymentMethod,
            disableClosedLoop,
            disableCard,
          },
        },
      });
      message.success('Hexopay Card state updated.');
    } catch (error) {
      if (error) message.error('Something went wrong, Please try again.');
    } finally {
      refetch();
    }
  };

  return (
    <>
      <PermissionError
        withPermission={HEXOPAY_CARDS_PERMISSION.ALLOWED_LIST}
        message="Hexopay Credit Cards"
      >
        {hexopayCards.length > 0 &&
          hexopayCards?.map((hexopay) => (
            <StyledCardFlex>
              <StyledCardFlex>
                <Typography.Text delete={hexopay.disableCard!}>
                  {`${hexopay.firstSixDigits || hexopay.firstDigit} *** *** ${
                    hexopay.lastFourDigits
                  }`}
                  <div className="fs-11">
                    {hexopay.expirationMonth}/{hexopay.expirationYear}
                  </div>
                </Typography.Text>
              </StyledCardFlex>
              <div
                className="d-flex justify-content-around mb-0 align-items-center"
                style={{
                  width: '60px',
                }}
              >
                {HEXOPAY_CARDS_PERMISSION.ALLOWED_DISABLE && (
                  <Popconfirm
                    okText="Confirm"
                    cancelText="Cancel"
                    onConfirm={() =>
                      handleToggleClosedLoop({
                        hexoPayCardId: hexopay.id,
                        paymentMethod: hexopayPaymentMethodId as string,
                        disableCard: !hexopay.disableCard,
                        disableClosedLoop: true,
                      })
                    }
                    title={`Are you sure you want to ${
                      hexopay?.disableCard ? 'enable' : 'disable'
                    } card?`}
                  >
                    <Tooltip
                      title={`Card is ${
                        hexopay?.disableCard ? 'disabled' : 'enabled'
                      }.`}
                    >
                      <StyledCheckbox checked={!hexopay.disableCard} />
                    </Tooltip>
                  </Popconfirm>
                )}
                {HEXOPAY_CARDS_PERMISSION.ALLOWED_TOGGLE_CLOSED_LOOP && (
                  <Popconfirm
                    okText="Confirm"
                    cancelText="Cancel"
                    onConfirm={() =>
                      handleToggleClosedLoop({
                        hexoPayCardId: hexopay.id,
                        paymentMethod: hexopayPaymentMethodId as string,
                        disableClosedLoop: !hexopay.disableClosedLoop!,
                      })
                    }
                    title={`Are you sure you want to ${
                      hexopay?.disableClosedLoop ? 'enable' : 'disable'
                    } closed loop?`}
                  >
                    <Tooltip
                      title={`Closed loop feature is ${
                        hexopay?.disableClosedLoop ? 'disabled' : 'enabled'
                      }.`}
                    >
                      <IssuesCloseOutlined
                        className={`text-${
                          hexopay?.disableClosedLoop ? 'danger' : 'success'
                        } cursor-pointer`}
                      />
                    </Tooltip>
                  </Popconfirm>
                )}

                <Popconfirm
                  okText="Confirm"
                  cancelText="Cancel"
                  onConfirm={() => onDeleteCard(hexopay.id)}
                  title="Are you sure you want to delete this card?"
                >
                  {HEXOPAY_CARDS_PERMISSION.ALLOWED_DELETE && (
                    <DeleteOutlined className="text-danger cursor-pointer" />
                  )}
                </Popconfirm>
              </div>
            </StyledCardFlex>
          ))}
      </PermissionError>
    </>
  );
};

export default HexopayCards;
