import { Form } from '@ant-design/compatible';
import { Input } from 'antd';
import React, { ReactNode, ChangeEvent, useState } from 'react';
import { defineMessages } from 'react-intl';
import * as yup from 'yup';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import messages from 'messages';
import { CustomRegex } from 'utils/regex';

const { Item } = Form;

type Props = {
  children: ReactNode;
  loqateIntegration?: boolean | null;
  values: {
    address2?: string;
    country: string;
    address: {
      postCode: string;
      street: string;
      premise: string;
      address?: string;
      building?: string;
      province?: string;
    } | null;
  };
  setFieldValue: Function;
  isEdit?: boolean;
  onManualAddressChange?: (value: boolean) => void;
};

export const AddressInputWrapper = ({
  children,
  loqateIntegration,
  values,
  setFieldValue,
  onManualAddressChange,
  isEdit,
}: Props) => {
  const [isManualEdit, setIsManualEdit] = useState(false);

  const handleEditAddress = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (!isManualEdit) setIsManualEdit(true);

    onManualAddressChange?.(true);
    setFieldValue('address.street', e.target.value);
  };

  React.useEffect(() => {
    setFieldValue('address.building', '');
    setFieldValue('address.province', '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isManualEdit]);

  return (
    <>
      {loqateIntegration ? (
        children
      ) : (
        <>
          <Item label="Postal Code">
            <Input
              onChange={(e) => {
                onManualAddressChange?.(true);
                setFieldValue('address.postCode', e.target.value);
              }}
              value={values.address?.postCode || ''}
            />
          </Item>
          <Item label="Address">
            {!isEdit ? (
              <Input
                onChange={(e) => {
                  setFieldValue('address.street', e.target.value);
                }}
                value={values.address?.street ?? ''}
              />
            ) : (
              <Input
                onChange={handleEditAddress}
                value={
                  isManualEdit
                    ? values.address?.street
                    : `${values.address?.street ?? ''} ${values.address
                        ?.building ?? ''} ${values.address?.province ?? ''}`
                }
              />
            )}
          </Item>
          <Item label="Address2">
            <Input
              onChange={(e) =>
                setFieldValue(
                  isEdit ? 'address.address' : 'address2',
                  e.target.value
                )
              }
              value={!isEdit ? values.address2 : values.address?.address}
            />
          </Item>
          <Item label="City">
            <Input
              onChange={(e) => setFieldValue('address.premise', e.target.value)}
              value={values.address?.premise || ''}
            />
          </Item>
        </>
      )}
    </>
  );
};

export const placeholderText = defineMessages({
  'please-enter.text': {
    id: 'please-enter.text',
    defaultMessage: 'Please enter',
  },
});

const EMAIL_REGEX = /^[a-zA-Z0-9](?!.*[_.]{2})[a-zA-Z0-9._]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;

export const getFormattedValidationSchema = (
  translate: any,
  emailRequired: boolean
) => {
  const messagesLocal = defineMessages({
    'member-management.this-field-is-required.text': {
      id: 'member-management.this-field-is-required.text',
      defaultMessage: 'This field is required',
    },
    'member-management-invalid-password.text': {
      id: 'member-management-invalid-password.text',
      defaultMessage: 'Password must be 6 to 12 characters',
    },
    'member-management.minimum-length-of-6-characters': {
      id: 'member-management.minimum-length-of-6-characters',
      defaultMessage: 'minimum length of 6 numbers',
    },
    'member-management.maximum-length-of-32-characters': {
      id: 'member-management.maximum-length-of-32-characters',
      defaultMessage: 'Maximum length of 32 characters',
    },
    'member-management.must-only-be-6-characters': {
      id: 'member-management.must-only-be-6-characters',
      defaultMessage: 'Must only be 6 characters',
    },
    'invalid.text': {
      id: 'invalid.text',
      defaultMessage: 'Invalid',
    },
    'member-management-must-be-a-number.text': {
      id: 'member-management-must-be-a-number.text',
      defaultMessage: 'Must be a a number',
    },
    'qqid-must-be-a-number-error.text': {
      id: 'qqid-must-be-a-number-error.text',
      defaultMessage: 'QQ Id must be a number and between 5 to 14 digits',
    },
    'member-level-no-spaces.text': {
      id: 'member-level-username-no-spaces.text',
      defaultMessage: 'Must have no spaces',
    },
    'wechat-id-validation-error.text': {
      id: 'wechat-id-validation-error.text',
      defaultMessage: 'WeChat Id should be between 8 to 14 characters',
    },
  });

  return yup.object().shape(
    {
      username: yup
        .string()
        .matches(/^\S*$/, {
          message: translate(messagesLocal['member-level-no-spaces.text']),
        })
        .min(
          4,
          translate(messages['minimum-number-of-characters.text'], { count: 4 })
        )
        .max(
          20,
          translate(messages['maximum-number-of-characters.text'], {
            count: 20,
          })
        )
        .matches(
          /^[a-zA-Z0-9]+$/,
          translate(messages['no-special-character-error.text'])
        )
        .nullable()
        .required(
          translate(
            messagesLocal['member-management.this-field-is-required.text']
          )
        ),
      firstName: yup
        .string()
        .matches(
          /^[a-zA-Z0-9 ]+$/,
          translate(messages['no-special-character-error.text'])
        )
        .nullable()
        .required(
          translate(
            messagesLocal['member-management.this-field-is-required.text']
          )
        ),
      lastName: yup
        .string()
        .matches(
          /^[a-zA-Z0-9 ]+$/,
          translate(messages['no-special-character-error.text'])
        )
        .nullable()
        .required(
          translate(
            messagesLocal['member-management.this-field-is-required.text']
          )
        ),
      password: yup
        .string()
        // eslint-disable-next-line
        .matches(
          CustomRegex.password(),
          translate(messages.PASSWORD_VALIDATION_FIELD)
        )
        .nullable()
        .when('id', {
          is: (val) => isEmpty(val),
          then: yup
            .string()
            .required(
              translate(
                messagesLocal['member-management.this-field-is-required.text']
              )
            )
            // eslint-disable-next-line
            .matches(
              CustomRegex.password(),
              translate(messages.PASSWORD_VALIDATION_FIELD)
            )
            .nullable(),
        }),
      withdrawalPassword: yup
        .string()
        .length(
          6,
          translate(
            messagesLocal['member-management.must-only-be-6-characters']
          )
        )
        .matches(
          /^[0-9]*$/,
          translate(messagesLocal['member-management-must-be-a-number.text'])
        )
        .nullable(),
      ...(emailRequired && {
        email: yup
          .string()
          .matches(EMAIL_REGEX, 'Invalid Email Address format.')
          .nullable()
          .required('Email field is required'),
      }),

      memberLevel: yup
        .string()
        .required(
          translate(messages['member-marker-field-required-error.text'])
        )
        .nullable(),
      qqId: yup
        .string()
        .matches(
          /^[0-9]{5,14}$/,
          translate(messagesLocal['qqid-must-be-a-number-error.text'])
        )
        .nullable(),
      wechatId: yup
        .string()
        .nullable()
        .matches(
          /^[A-Za-z0-9_\\-]{8,14}$/,
          translate(messagesLocal['wechat-id-validation-error.text'])
        ),
      gender: yup
        .string()
        .nullable()
        .required(translate(messages['gender-field-required-error.text'])),
      depositLimit: yup.number().required('Deposit Limit required'),
      dateOfBirth: yup
        .string()
        .nullable()
        .required(translate(messages['birthdate-field-required-error.text']))
        .test(
          'dateOfBirth',
          translate(messages.DOB_INVALID_ERROR),
          (value) => moment().diff(moment(value), 'years') > 0
        )
        .test(
          'dateOfBirth',
          translate(messages.DOB_UNDERAGE_ERROR),
          (value) => moment().diff(moment(value), 'years') >= 18
        ),
      mobileNumber: yup
        .string()
        .required('Mobile number is required')
        .matches(
          /^[0-9]{8,14}$/,
          translate(messages['mobile-number-error-field.text'])
        )
        .nullable(),
      countryCode: yup.string().required('Country code is required'),
    },
    [
      ['realName', 'dateOfBirth'],
      ['gender', 'dateOfBirth'],
      ['realName', 'gender'],
    ]
  );
};
