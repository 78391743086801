import { useMutation } from '@apollo/react-hooks';
import {
  Button,
  Col,
  Input,
  message,
  Row,
  Select,
  Spin,
  Table,
  Typography,
} from 'antd';
import gql from 'graphql-tag';
import { UPDATE_MEMBER } from 'graphql/mutations/member.mutation';
import { capitalize, isNil } from 'lodash';
import MemberDropdown from 'pages/components/NewMemberDetail/components/MemberDropdown';
import { useNewMemberDetailPermissions } from 'pages/components/NewMemberDetail/constants';
import { useMember } from 'pages/components/NewMemberDetail/memberContext';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ALink } from 'components/ALink/ALink';
import PermissionError from 'components/PermissionError';
import {
  DepositLimits,
  Member,
  MemberDepositLimitFrequency,
  MutationUpdateMemberArgs,
} from 'types/graphqlTypes';
import isRow from 'utils/isRow';
import { LockOutlined } from '@ant-design/icons';
import { ContainerBody, ContainerHeader } from '../../styles';
import ForceMaxDepositLimit from './ForceMaxDepositLimit';

const CONFIRM_DEPOSIT_LIMIT_CHANGE = gql`
  mutation ConfirmDepositLimitChange($input: ConfirmDepositLimitChangeInput!) {
    confirmDepositLimitChange(input: $input)
  }
`;

const CANCEL_DEPOSIT_LIMIT_CHANGE = gql`
  mutation CancelDepositLimitChange($input: CancelDepositLimitChangeInput!) {
    cancelDepositLimitChange(input: $input)
  }
`;

const MemberDepositLimit = () => {
  const [showTable, setShowTable] = useState(true);
  const [periodSelected, setPeriodSelected] = useState<
    MemberDepositLimitFrequency
  >(MemberDepositLimitFrequency.Daily);
  const [depositLimitValue, setDepositLimitValue] = useState('');
  const [loading, setLoading] = useState(false);
  const { member, refetch } = useMember() as {
    member: Member;
    refetch: Function;
  };

  const { MEMBER_DEPOSIT_LIMIT_PERMISSION } = useNewMemberDetailPermissions();

  const [updateDepositLimit] = useMutation<boolean, MutationUpdateMemberArgs>(
    UPDATE_MEMBER
  );
  const [
    confirmDepositLimitChange,
    { loading: loadingForConfirm },
  ] = useMutation(CONFIRM_DEPOSIT_LIMIT_CHANGE);
  const [cancelDepositLimitChange, { loading: loadingForCancel }] = useMutation(
    CANCEL_DEPOSIT_LIMIT_CHANGE
  );

  const onUpdateDepositLimit = async () => {
    if (Number(depositLimitValue) < 0) {
      message.error('The limit value should be equal or more than 0');
      return;
    }
    setLoading(true);
    updateDepositLimit({
      variables: {
        id: member?.id,
        input: {
          depositLimitFrequency: periodSelected,
          depositLimit: Number(depositLimitValue),
        },
      },
    })
      .then(() => {
        message.success('Deposit limit updated');
        refetch();
      })

      .finally(() => {
        setLoading(false);
      });
  };

  const onConfirmLimit = async (frequency: MemberDepositLimitFrequency) => {
    confirmDepositLimitChange({
      variables: {
        input: {
          frequency,
          member: member.id,
        },
      },
    }).then(() => {
      message.success('Deposit limit confirmed');
      refetch();
    });
  };

  const onCancelLimit = async (frequency: MemberDepositLimitFrequency) => {
    cancelDepositLimitChange({
      variables: {
        input: {
          frequency,
          member: member.id,
        },
      },
    }).then(() => {
      message.success('Deposit limit cancelled');
      refetch();
    });
  };
  const isLoadingMutations = loadingForConfirm || loadingForCancel;

  const RenderDepositLimitActions: React.FC<{
    nextLimit: number;
    frequency: MemberDepositLimitFrequency;
  }> = ({ nextLimit, frequency }) => {
    if (nextLimit) {
      if (isLoadingMutations) {
        return <Spin />;
      }

      return (
        <div className="flex">
          <ALink
            className="mr-3 fs-11"
            onClick={() => onConfirmLimit(frequency!)}
          >
            Confirm
          </ALink>
          <ALink className="fs-11" onClick={() => onCancelLimit(frequency!)}>
            Decline
          </ALink>
        </div>
      );
    }

    return <></>;
  };
  const columns = [
    {
      title: 'Period',
      dataIndex: 'frequency',
      key: 'frequency',
      render: (e: string) => capitalize(e),
    },
    {
      title: 'Limit',
      dataIndex: 'limit',
      key: 'limit',
      render: (e: string) => (
        <div className="align-items-center justify-content-center">
          <Typography.Text className="mr-1">{e}</Typography.Text>
          {member.depositLimitLock && <LockOutlined />}
        </div>
      ),
    },
    {
      title: 'Limit Used',
      dataIndex: 'used',
      key: 'used',
      render: (used: number) => used,
    },
    {
      title: 'Pending Limit',
      dataIndex: 'nextLimit',
      key: 'nextLimit',
      render: (nextLimit: number) => (isNil(nextLimit) ? '-' : nextLimit),
    },
    {
      title: '',
      width: 50,
      key: 'none',
      render: (_: unknown, allData: DepositLimits) => (
        <RenderDepositLimitActions
          nextLimit={allData?.nextLimit}
          frequency={allData?.frequency!}
        />
      ),
    },
  ];

  const depositLimits =
    member?.depositLimits!.filter(
      (limit) =>
        limit!.frequency !== MemberDepositLimitFrequency.Quarterly &&
        limit!.frequency !== MemberDepositLimitFrequency.Yearly &&
        limit!.frequency !== MemberDepositLimitFrequency.Hourly
    ) || [];

  return (
    <div className="mt-3">
      <ContainerHeader>
        <span className="fw-500">
          <FormattedMessage
            id="DEPOSIT_LIMITS"
            defaultMessage="Deposit Limits"
          />
        </span>
        <MemberDropdown showTable={showTable} setShowTable={setShowTable} />
      </ContainerHeader>
      <ContainerBody style={{ display: showTable ? 'block' : 'none' }}>
        <PermissionError
          message="Member Deposit Limit"
          withPermission={MEMBER_DEPOSIT_LIMIT_PERMISSION.ALLOWED_LIST}
        >
          <>
            <Table
              size="small"
              columns={columns}
              dataSource={depositLimits as DepositLimits[]}
              pagination={false}
            />

            <div className="pt-3 pb-2 pl-3">
              <Row gutter={16}>
                <Col span={10}>
                  <Select
                    style={{ width: '100%' }}
                    value={periodSelected}
                    onSelect={(e) => setPeriodSelected(e)}
                  >
                    <Select.Option value={MemberDepositLimitFrequency.Daily}>
                      Daily
                    </Select.Option>
                    <Select.Option value={MemberDepositLimitFrequency.Weekly}>
                      Weekly
                    </Select.Option>
                    <Select.Option value={MemberDepositLimitFrequency.Monthly}>
                      Monthly
                    </Select.Option>
                  </Select>
                </Col>
                <PermissionError
                  message="Member deposit limit -> Edit"
                  withPermission={MEMBER_DEPOSIT_LIMIT_PERMISSION.ALLOWED_EDIT}
                >
                  <>
                    <Col span={10}>
                      <Input
                        type="number"
                        placeholder="Deposit Limit"
                        value={depositLimitValue}
                        onChange={(e) => setDepositLimitValue(e.target.value)}
                      />
                    </Col>
                    <Col span={4}>
                      <Button
                        onClick={onUpdateDepositLimit}
                        disabled={loading || !depositLimitValue}
                        loading={loading}
                      >
                        Save
                      </Button>
                    </Col>
                  </>
                </PermissionError>
              </Row>
            </div>
            {!isRow && <ForceMaxDepositLimit />}
          </>
        </PermissionError>
      </ContainerBody>
    </div>
  );
};

export default MemberDepositLimit;
