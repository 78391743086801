import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import isRow from 'utils/isRow';
import { StyledModal } from './styles';
import MemberLevelForm from '../MemberLevelForm';

const NewMemberLevel = ({
  onSubmitForm,
}: {
  onSubmitForm: (data: Record<string, any>) => void;
}) => {
  const [isShow, setShow] = useState(false);
  const handleShow = () => setShow(!isShow);

  return (
    <>
      <Button type="primary" onClick={handleShow}>
        <PlusOutlined />
        <FormattedMessage
          id="new-member-marker.text"
          defaultMessage="New Member Marker"
        />
      </Button>
      {isShow && (
        <StyledModal
          width={600}
          title={
            <FormattedMessage
              id="new-member-marker.text"
              defaultMessage="New Member Marker"
            />
          }
          visible
          footer={false}
          onCancel={handleShow}
        >
          <MemberLevelForm
            actionType="CREATE"
            toggleModal={handleShow}
            onSubmitForm={onSubmitForm}
            initialValues={{
              name: '',
              color: '',
              default: false,
              status: 'ENABLED',
              ...(!isRow && {
                brandLinkage: false,
              }),
            }}
          />
        </StyledModal>
      )}
    </>
  );
};

export default NewMemberLevel;
