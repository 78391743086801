import styled from 'styled-components';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

export const StyledForm = styled(Form)`
  .ant-legacy-form-item {
    margin-bottom: 0 !important;
    display: flex;
    flex-direction: column;

    .ant-legacy-form-item-label {
      text-align: left;

      label {
        color: rgba(0, 0, 0, 0.35);
      }
    }
  }

  .ant-legacy-form-item-label {
    height: 28px;
    z-index: 9999999 !important;
  }
  .communication-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
  }
  .communication-wrapper {
    display: flex;
    flex-direction: row;
    height: 25px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    p {
      margin-bottom: 0px;
    }
  }
`;

export default {};
