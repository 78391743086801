import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Checkbox, Input } from 'antd';
import { Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { pick, pickBy, identity } from 'lodash';
import useTranslate from 'utils/useTranslate';
import ColorPicker from 'components/ColorPicker';
import { formItemHorizontalLayout } from 'constants/form';
import isRow from 'utils/isRow';
import messages from './messages';
import validationSchema from './validationSchema';

type Props = {
  mutate: any;
  onClose: () => void;
  defaults: Record<string, any>;
};

const CustomFormik: any = Formik;

const LabelForm = React.forwardRef((props: Props, ref) => {
  const { mutate, onClose, defaults } = props;

  const defaultValues = {
    name: '',
    color: '',
    description: '',
    ...(!isRow && {
      brandLinkage: false,
    }),
  };

  const initialValues = {
    ...defaultValues,
    ...pick(defaults, Object.keys(defaultValues)),
  };

  const translate = useTranslate();

  return (
    <Root>
      <CustomFormik
        ref={ref as any}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema(translate)}
        onSubmit={async (
          values: Record<string, any>,
          { resetForm, setSubmitting }: any
        ) => {
          const input = pickBy(values, identity);

          try {
            if (defaults.id && input.name === defaults.name) delete input.name;

            const response = await mutate({
              variables: {
                ...(defaults.id ? { id: defaults.id } : {}),
                input: {
                  ...input,
                  ...(!isRow && {
                    brandLinkage: values.brandLinkage,
                  }),
                },
              },
            });

            if (response) {
              resetForm();
              onClose();
            }
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
          handleSubmit,
          setFieldValue,
          values,
          errors,
          handleChange,
          isSubmitting,
        }: any) => (
          <Form onSubmit={handleSubmit} layout="horizontal">
            <div className="px-3 pt-4 pb-3">
              <Form.Item
                label={translate(
                  messages['member.label-management.label-name.text']
                )}
                {...formItemHorizontalLayout}
                validateStatus={errors.name ? 'error' : ''}
                help={errors.name}
              >
                <Input
                  size="large"
                  name="name"
                  onChange={handleChange}
                  value={values.name}
                />
              </Form.Item>
              <Form.Item
                label={translate(
                  messages['member.label-management.color.text']
                )}
                validateStatus={errors.color ? 'error' : ''}
                help={errors.color}
                {...formItemHorizontalLayout}
              >
                <ColorPicker.Twitter
                  value={values.color}
                  onChange={(e: any) => setFieldValue('color', e)}
                />
              </Form.Item>
              <Form.Item
                label={translate(
                  messages['member.label-management.description.text']
                )}
                {...formItemHorizontalLayout}
                validateStatus={errors.description ? 'error' : ''}
                help={errors.description}
              >
                <Input
                  size="large"
                  name="description"
                  onChange={handleChange}
                  value={values.description}
                />
              </Form.Item>
              {!isRow && (
                <Form.Item
                  label="Set for Brand Linkage"
                  {...formItemHorizontalLayout}
                >
                  <Checkbox
                    name="brandLinkage"
                    onChange={handleChange}
                    checked={values.brandLinkage}
                  />
                </Form.Item>
              )}
            </div>
            <div className="bt-1 px-3 py-3 text-right">
              <Button
                className="mr-2"
                onClick={onClose}
                disabled={isSubmitting}
              >
                <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
              </Button>
              <Button type="primary" htmlType="submit" loading={isSubmitting}>
                <FormattedMessage id="confirm.text" defaultMessage="Confirm" />
              </Button>
            </div>
          </Form>
        )}
      </CustomFormik>
    </Root>
  );
});

const Root = styled.div`
  .ant-legacy-form-item {
    margin-bottom: 0.5rem;
  }
`;

export default LabelForm;
