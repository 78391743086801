import { useQuery } from '@apollo/react-hooks';
import { Spin, Typography } from 'antd';
import { ErrorHandler } from 'components/ErrorHandler';
import Layout from 'components/Layout';
import NextPrevButton from 'components/NextPrevButton';
import { useFilterValues } from 'contexts/Filters';
import { WITHDRAWAL_REQUESTS } from 'graphql/queries/withdrawal.query';
import { TableFooterPages } from 'pages/components/NewMemberManagement/components/Content/components/Members/components/MembersTable/components/TableFooterPages/TableFooterPages';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useScreenTabV2 } from 'store/screenTabState';
import { transformMovableDateFilter } from 'utils/dateUtils';
import { getPollInterval } from 'utils/pollInterval';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import coercedGet from 'utils/coercedGet';
import AccountSum from '../AccountSum';
import HeaderLeft from '../HeaderLeft';
import HeaderRight from '../HeaderRight';
import SideBar from '../SideBar';
import WithdrawalsTable from '../WithdrawalsTable';

const WithdrawalsScreen = ({ refreshPage }: { refreshPage: () => void }) => {
  const { isTabActive } = useScreenTabV2();
  const { context, setOnPoll } = useOperatorHeader();
  const isActiveTab = isTabActive('withdrawal-requests');

  const [isRealtime, setIsRealtime] = useState(true);

  const [page, setPage] = useState<Record<string, any>>({
    first: 10,
    after: undefined,
    savedCursor: [''],
    currentPage: 0,
  });

  const { filters } = useFilterValues();

  const handleSetRealtime = () => {
    setIsRealtime((prev) => !prev);
  };

  const { loading, data = {}, variables } = useQuery(WITHDRAWAL_REQUESTS, {
    variables: {
      first: page.first,
      ...(page.after && { after: page.after }),
      ...(filters && {
        filter: transformMovableDateFilter(filters),
      }),
    },
    fetchPolicy: 'network-only',
    pollInterval: getPollInterval({
      isActive: isActiveTab,
      isRealtime,
    }),
    notifyOnNetworkStatusChange: true,
    context,
    onCompleted: () => {
      setOnPoll(true);
    },
  });

  const tablePagesForFooter = [10, 20, 50, 100];

  const { withdrawals = {} } = data as Record<string, any>;
  const { totalCount, pageInfo } = withdrawals;
  const edges = coercedGet(data, 'withdrawals.edges', []);
  const totalPage = Math.ceil(totalCount / page.first) || 1;

  const handleNext = () => {
    const { savedCursor, currentPage } = page;
    savedCursor.push(pageInfo.endCursor);
    setPage({
      ...page,
      after: pageInfo.endCursor,
      currentPage: currentPage + 1,
      savedCursor,
    });
  };

  const handlePrev = () => {
    const { currentPage, savedCursor } = page;
    const prevPage = currentPage - 1;
    const after = savedCursor[prevPage];
    setPage({
      ...page,
      after,
      currentPage: prevPage,
    });
  };

  const Footer = () => (
    <div
      className="d-flex justify-content-space-between align-items-center "
      style={{
        borderTop: '1px solid #e8e8e8',
        padding: '1.2rem',
        height: '2.4vh',
        background: '#f3f8fb',
      }}
    >
      <AccountSum />
      <Spin spinning={loading}>
        <div className="styled-center">
          <div className="mr-3 text-truncate">
            <Typography.Text strong className="mr-1">
              {loading ? '#' : totalCount}
            </Typography.Text>
            <FormattedMessage
              id="results-found.text"
              defaultMessage="Results Found"
              values={{
                count: null,
              }}
            />
          </div>
          <NextPrevButton
            onPrev={handlePrev}
            disablePrev={page.currentPage + 1 === 1}
            onNext={handleNext}
            disableNext={page.currentPage + 1 === totalPage}
            label={`${page.currentPage + 1} / ${totalPage}`}
          />
          <TableFooterPages
            loading={false}
            values={tablePagesForFooter}
            setPage={setPage}
            page={page}
          />
        </div>
      </Spin>
    </div>
  );
  return (
    <Layout.Container>
      <>
        <Layout.Header
          leftNode={<HeaderLeft />}
          rightNode={
            <HeaderRight
              refreshPage={refreshPage}
              isRealtime={isRealtime}
              handleSetRealtime={handleSetRealtime}
              refetchVariables={variables}
            />
          }
        />
        <Layout.Content sideBar={<SideBar />} footer={Footer()}>
          <ErrorHandler>
            <WithdrawalsTable
              dataSource={edges}
              loading={loading}
              isActiveTab={isActiveTab}
              refetchVariables={variables}
            />
          </ErrorHandler>
        </Layout.Content>
      </>
    </Layout.Container>
  );
};

export default WithdrawalsScreen;
