import React from 'react';
import { Button } from 'antd';
import { useScreenTabV2 } from 'store/screenTabState';
import { TabId } from 'constants/route';
import coercedGet from 'utils/coercedGet';
// import { useApolloClient } from '@apollo/react-hooks';
// import { GET_MEMBER_USERNAME } from 'graphql/queries/member.query';
import { flatten } from 'lodash';

function RequestRedirect({ node }: { node: Record<string, any> }) {
  const type = coercedGet(node, 'type', null);
  // const client = useApolloClient();
  const redirectTypes = {
    DEPOSIT: ['DEPOSIT', 'DEPOSIT_MANUAL_ADJUSTMENT'],
    WITHDRAWAL: ['WITHDRAWAL', 'WITHDRAWAL_MANUAL_ADJUSTMENT'],
    BET: ['BET'],
    PAYOUT: ['PAYOUT'],
    REFUND_BET: ['REFUND_BET'],
    SYSTEM: ['SYSTEM'],
  };

  const redirectable = flatten(Object.values(redirectTypes)).includes(type);

  const mbrKey = flatten([
    redirectTypes.BET,
    redirectTypes.PAYOUT,
    redirectTypes.REFUND_BET,
  ]).includes(type);
  const depositKey = flatten([
    redirectTypes.DEPOSIT,
    redirectTypes.SYSTEM,
  ]).includes(type);
  const withdrawalKey = flatten([
    redirectTypes.WITHDRAWAL,
    redirectTypes.SYSTEM,
  ]).includes(type);

  const { addTab } = useScreenTabV2() as Record<string, any>;
  // const user = coercedGet(node?.member, 'node.member.id', undefined);

  const userId = node.member.id;

  const handleRedirect = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    // const resp = await client.query({
    //   query: GET_MEMBER_USERNAME,
    //   variables: {
    //     id: node?.id,
    //   },
    // });
    // const userId = coercedGet(
    //   resp,
    //   'data.balanceTransactionRecord.member.id',
    //   undefined
    // );

    // console.log(userId);

    const internalDepositsTab = {
      id: TabId.INTERNAL_DEPOSIT_REQUESTS,
      state: {
        memberIdRef: userId,
        serialCodeRef: node?.transaction,
      },
    };

    const withdrawalRequestsTab = {
      id: TabId.WITHDRAWAL_REQUESTS,
      state: {
        next: true,
        memberIdRef: userId,
        serialCodeRef: node?.transaction,
      },
    };

    const mbrTab = {
      id: TabId.MEMBER_BET_RECORDS,
      state: {
        next: true,
        memberIdRef: userId,
        roundRef: node?.transaction,
      },
    };

    if (depositKey) return addTab(internalDepositsTab);
    if (withdrawalKey) return addTab(withdrawalRequestsTab);
    if (mbrKey) return addTab(mbrTab);

    return null;
  };

  if (node?.transaction) {
    return redirectable ? (
      <Button
        style={{ fontSize: '13px' }}
        className="px-0"
        type="link"
        onClick={handleRedirect}
      >
        {node.transaction}
      </Button>
    ) : (
      <span>{node?.transaction}</span>
    );
  }
  return <span>-</span>;
}

export default RequestRedirect;
