/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import {
  CheckCircleOutlined,
  DollarTwoTone,
  EllipsisOutlined,
} from '@ant-design/icons';
import { Tooltip, Dropdown, Menu } from 'antd';
import { useIntl, defineMessages } from 'react-intl';
import { useAccount, usePermissions } from 'store/accountState';
import { useCustomColumnsV2 } from 'store/customColumnState/customColumnState';
import coercedGet from 'utils/coercedGet';
import messages from 'messages';
import Circle from 'components/Circle';
import { getStatusColor } from 'utils/depositWithdrawalStatus';
import moment from 'moment';
import { StyledTable } from 'constants/styles';

import ALL_PERMISSIONS from 'constants/permissions3';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import { showPermissionError } from 'components/Navbar/Navbar';

import { ALink } from 'components/ALink/ALink';
// import MemberLoyaltyTagList from 'components/MemberLoyaltyTagList/MemberLoyaltyTagList';
import AffiliateIndicatorBatch from 'components/AffiliateIndicatorBatch';
import DepositPaymentMethod from 'pages/components/InternalMemberDeposits/components/DepositPaymentMethod';
import { customFormatMessage } from 'utils/customFormatMessage';
import {
  Deposit,
  Member,
  HexoPayDeposit,
  DepositStatus,
  NetellerDeposit,
  SkrillDeposit,
  CryptocurrencyDeposit,
  Admin,
} from 'types/graphqlTypes';
// import { isVipVisible } from 'utils/isVipVisible';
import isRow from 'utils/isRow';
import { OriginalColumn } from 'store/customColumnState/types';
import { columnAlign } from 'utils/tableAlignment';
import esGet from 'utils/esGet';
import { DATE_FORMAT } from 'constants/date';
import MemberLevelButton from 'components/MemberLevelButton';
import { useSpreadsheetContext } from 'contexts/Spreadsheet';
import { useNextParam } from 'hooks/useIsNext';
import { useConfig } from 'hooks/useConfig';
import { currencies } from 'constants/currency';
import MemberLabelRemarks from './components/MemberLabelRemarks';
import DepositDetails from '../DepositDetails';
import MemberProfile from './components/MemberProfile';
import ApproveDeposit from './components/ApproveDeposit';
import RejectDeposit from './components/RejectDeposit';
import StopProcessing from './components/StopProcessing';
import DepositedAmount from './components/DepositAmount';
import HexopayTransaction from './components/HexopayTransaction';

type Props = {
  dataSource: Array<object>;
  loading: boolean;
  refetchVariables: object;
  refetchDepositRequests: (item: object) => void;
  isActiveTab: boolean;
};
type DepositType = HexoPayDeposit &
  NetellerDeposit &
  SkrillDeposit &
  CryptocurrencyDeposit &
  Deposit;

const messagesLocal = defineMessages({
  'first-deposit.text': {
    id: 'first-deposit.text',
    defaultMessage: 'First Deposit',
  },
  'serial-code.text': {
    id: 'serial-code.text',
    defaultMessage: 'Serial Code',
  },
  'affiliate.text': {
    id: 'affiliate.text',
    defaultMessage: 'Affiliate',
  },
  'account-username.text': {
    id: 'account-username.text',
    defaultMessage: 'Account Username',
  },
  'actions.text': {
    id: 'actions.text',
    defaultMessage: 'Actions',
  },
  'manual-adjustment.text': {
    id: 'manual-adjustment.text',
    defaultMessage: 'Manual Adjustment',
  },
});

const getProcessingTime = (deposit: any) => {
  const { dateTimeCreated, dateTimeProcessed, status } = deposit;

  if (status === 'APPROVED' || status === 'REJECTED') {
    if (!dateTimeProcessed) {
      return moment(dateTimeCreated).fromNow();
    }
    return moment(dateTimeProcessed).from(dateTimeCreated, true);
  }
  if (status === 'EXPIRED') return '-';

  return moment(dateTimeCreated).fromNow();
};

const DepositsTable: React.FC<Props> = ({
  dataSource,
  loading,
  refetchVariables,
  refetchDepositRequests,
  isActiveTab,
}) => {
  const {
    account: { account: adminAccount },
  } = useAccount() as { account: { account: Admin } };

  const isNext = useNextParam();
  const { setTableColumns } = useSpreadsheetContext();
  const { baseCurrency, renderNumeral } = useConfig();
  const { role, permissions } = usePermissions();
  const {
    ALLOWED_LIST,
    ALLOWED_VIEW_DETAILS,
    ALLOWED_APPROVE,
    ALLOWED_REJECT,
    ALLOWED_PROCESS,
  } = collectPermissions(
    role,
    permissions,
    ['LIST', 'VIEW_DETAILS', 'APPROVE', 'REJECT', 'PROCESS'],
    ALL_PERMISSIONS.ALL_DEPOSITS.DEPOSITS_INTERNAL_DEPOSITS
  );

  const intl = useIntl();
  const { formatMessage } = intl;
  const [state, setState] = useState({
    selectedRowKeys: [],
    isViewDepositDetails: false,
    depositId: null,
  });

  const toggleView = (id = null) => {
    if (!ALLOWED_VIEW_DETAILS) {
      showPermissionError('Deposit Request (Internal) - View Details');
      return;
    }
    setState({
      ...state,
      isViewDepositDetails: !state.isViewDepositDetails,
      depositId: id,
    });
  };

  const rowSelection = {
    selectedRowKeys: state.selectedRowKeys,
    columnWidth: '32px',
    onChange: (e: any) => setState({ ...state, selectedRowKeys: e }),
  };

  const data = dataSource
    ? dataSource.map(({ node }: any) => ({ ...node, key: node.id }))
    : [];
  const translate = (messageVal: any, opts: any = null) =>
    customFormatMessage(formatMessage, messageVal, opts);

  const noManualAndFirstDeposit = (deposit: Deposit) =>
    !deposit.manualAdjustment && !deposit.firstDeposit;
  const columns = [
    {
      csvData: {
        label: translate(messagesLocal['first-deposit.text']),
        key: 'firstDeposit',
        renderCell: (deposit: object) =>
          coercedGet(deposit, 'firstDeposit', '')
            ? translate(messages.YES)
            : translate(messages.NO),
      },
      key: 'firstDeposit',
      title: '',
      customTitle: translate(messagesLocal['first-deposit.text']),
      disabled: true,
      width: 50,
      render: (deposit: any) => (
        <div className="d-flex justify-content-between">
          {deposit.manualAdjustment && (
            <Tooltip
              arrowPointAtCenter
              title={translate(messagesLocal['manual-adjustment.text'])}
            >
              <CheckCircleOutlined />
            </Tooltip>
          )}

          {deposit.firstDeposit && deposit.status !== 'REJECTED' && (
            <Tooltip
              arrowPointAtCenter
              title={translate(messagesLocal['first-deposit.text'])}
            >
              <DollarTwoTone twoToneColor="#FF0000" />
            </Tooltip>
          )}

          {noManualAndFirstDeposit(deposit) && ''}
        </div>
      ),
    },
    {
      csvData: {
        label: translate(messagesLocal['serial-code.text']),
        key: 'serialCode',
        renderCell: (deposit: Deposit) => deposit.serialCode,
      },

      key: 'serialCode',
      align: columnAlign.onCenter,
      title: translate(messages['serial-code.text']),
      disabled: true,
      width: 100,
      render: (_text: string, deposit: any) => (
        <ALink
          role="button"
          aria-label={deposit.serialCode}
          onClick={() => toggleView(deposit.id)}
        >
          {deposit.serialCode}
        </ALink>
      ),
    },
    {
      csvData: {
        label: translate(messagesLocal['affiliate.text']),
        key: 'affiliate',
        renderCell: (deposit: object) =>
          coercedGet(deposit, 'account.affiliate.code', '-'),
      },
      disabled: true,
      align: columnAlign.onCenter,
      title: translate(messagesLocal['affiliate.text']),
      key: 'affiliateIndicator',
      width: 100,
      render: (depositDetails: Deposit) => (
        <AffiliateIndicatorBatch data={depositDetails} />
      ),
    },
    {
      csvData: {
        label: translate(messagesLocal['account-username.text']),
        key: 'username',
        renderCell: (deposit: object) =>
          coercedGet(deposit, 'account.username', '-'),
      },
      disabled: true,
      align: columnAlign.onCenter,
      key: 'username',
      title: translate(messages.ACCOUNT_USERNAME),
      width: 100,
      render: (deposit: Deposit) => <MemberProfile data={deposit} />,
    },
    {
      csvData: {
        label: 'Platform ID',
        key: 'platformId',
        renderCell: (deposit: object) =>
          coercedGet(deposit, 'account.platformId', '-'),
      },
      align: columnAlign.onCenter,
      key: 'platformId',
      title: 'Platform ID',
      dataIndex: 'account',
      width: 100,
      render: (account: Member) => account.platformId,
    },
    {
      csvData: {
        label: 'Brand ID',
        key: 'brandId',
        renderCell: (deposit: object) =>
          coercedGet(deposit, 'account.brandId', '-'),
      },
      align: columnAlign.onCenter,
      key: 'brandId',
      title: 'Brand ID',
      dataIndex: 'account',
      width: 100,
      render: (account: Member) => account.brandId,
    },
    {
      key: 'affiliateId',
      align: columnAlign.onCenter,
      title: `${translate(messages.AFFILIATE)} ${translate(messages.ID)}`,
      dataIndex: 'account',
      width: 100,
      render: (account: Member) => <>{account?.affiliate?.code || '-'}</>,
    },
    // {
    //   ...(Boolean(isVipVisible) && {
    //     csvData: {
    //       label: translate(messages.VIP_TIER),
    //       key: 'vipLevel',
    //       renderCell: (deposit: object) => {
    //         const memberLevels = coercedGet(
    //           deposit,
    //           'account.memberLoyaltyLevels',
    //           '-'
    //         );
    //         return memberLevels.map((item: any) => item.name);
    //       },
    //     },
    //     width: 100,
    //     key: 'vipLevel',
    //     align: columnAlign.onCenter,
    //     title: translate(messages.VIP_TIER),
    //     render: (deposit: Deposit) =>
    //       deposit.account?.memberLoyaltyLevels?.length ? (
    //         <MemberLoyaltyTagList
    //           loyaltyLevels={deposit.account.memberLoyaltyLevels}
    //         />
    //       ) : (
    //         '-'
    //       ),
    //   }),
    // },
    {
      csvData: {
        label: translate(messages.MEMBER_MARKER),
        key: 'memberMarker',
        renderCell: (deposit: object) =>
          coercedGet(deposit, 'account.memberLevel.name', '-'),
      },
      title: translate(messages.MEMBER_MARKER),
      key: 'memberMarker',
      width: 100,
      align: columnAlign.onCenter,
      render: (deposit: Deposit) => (
        <MemberLevelButton memberLevelData={deposit.account?.memberLevel} />
      ),
    },
    {
      csvData: {
        label: translate(messages['payment-method.text']),
        key: 'paymentMethod',
        renderCell: (data: Deposit) => {
          if (data.manualAdjustment) return 'Manual Adjustment';
          return coercedGet(data, 'paymentMethod.name', '-');
        },
      },
      title: translate(messages['payment-method.text']),
      key: 'paymentMethod',
      dataIndex: 'id',
      width: 150,
      align: columnAlign.onCenter,
      render: (id: string) => <DepositPaymentMethod depositId={id} />,
    },
    {
      csvData: {
        label: `${!isRow ? 'Hexopay' : ''} Transaction UID`,
        key: 'transactionReference',
        renderCell: (deposit: object) =>
          coercedGet(deposit, 'transactionReference', '-'),
      },
      title: `${!isRow ? 'Hexopay' : ''} Transaction UID`,
      key: 'transactionReference',
      width: 150,
      align: columnAlign.onCenter,
      render: (deposit: HexoPayDeposit) => {
        const hexopayUID =
          deposit.transactionReference ||
          (deposit.status === 'EXPIRED' ? 'Expired Transaction' : '-');
        return isNext ? (
          <HexopayTransaction data={hexopayUID} id={deposit.id} />
        ) : (
          <span>{hexopayUID}</span>
        );
      },
    },
    {
      csvData: {
        label: 'Payment Account Details',
        key: 'paymentAccountDetails',
        renderCell: (deposit: DepositType) => {
          const hexoPayCreditCard = deposit?.hexoPayCreditCard;
          const paymentMethod = esGet(deposit?.paymentMethod, {});
          const account = esGet(deposit?.account, {});

          const isCryptoDeposit =
            deposit.__typename === 'CryptocurrencyDeposit';

          if (isCryptoDeposit) return deposit.depositorBlockchainAddress || '-';

          if (deposit.status === 'EXPIRED') return 'Expired Transaction';
          if (
            paymentMethod?.name === 'Neteller' ||
            paymentMethod?.name === 'Skrill'
          ) {
            return account.initialEmail;
          }

          if (hexoPayCreditCard) {
            const {
              firstDigit,
              lastFourDigits,
              expirationYear,
              expirationMonth,
            } = hexoPayCreditCard;

            return `${firstDigit}-${lastFourDigits} ${expirationMonth}/${expirationYear}`;
          }
          return '-';
        },
      },
      title: 'Payment Account Details',
      key: 'paymentAccountDetails',
      width: 150,
      align: columnAlign.onCenter,
      render: (deposit: DepositType) => {
        const {
          account,
          paymentMethod,
          hexoPayCreditCard,
          status,
          depositorBlockchainAddress,
        } = deposit || {};
        const {
          firstSixDigits,
          lastFourDigits,
          expirationYear,
          expirationMonth,
        } = hexoPayCreditCard || {};

        const isCryptoDeposit = deposit.__typename === 'CryptocurrencyDeposit';

        if (isCryptoDeposit) return depositorBlockchainAddress || '-';

        if (
          paymentMethod?.name === 'Neteller' ||
          paymentMethod?.name === 'Skrill'
        ) {
          return <span>{account?.initialEmail}</span>;
        }

        if (status === 'EXPIRED') return 'Expired Transaction';

        return (
          <span>
            {hexoPayCreditCard
              ? `${firstSixDigits} *** *** ${lastFourDigits} ${expirationMonth}/${expirationYear}`
              : '-'}
          </span>
        );
      },
    },
    {
      csvData: {
        label: `${translate(messages.AMOUNT_MEMBER)}`,
        key: 'amount',
        renderCell: (deposit: Deposit) => {
          if (isRow) {
            return renderNumeral(deposit.amount, deposit.isCrypto!);
          }

          return renderNumeral(deposit.amount);
        },
      },
      title: `${translate(messages.AMOUNT_MEMBER)}`,
      width: 150,
      align: columnAlign.onCenter,
      key: 'depositedAmount',
      render: (deposit: Deposit) => (
        <DepositedAmount amount={deposit.amount} currency={deposit.currency!} />
      ),
    },

    {
      csvData: {
        label: translate(messages.status),
        key: 'status',
        renderCell: (deposit: object) =>
          translate(
            messages[
              `${coercedGet(deposit, 'status', '')}` as keyof typeof messages
            ]
          ),
      },
      title: translate(messages.status),
      key: 'status',
      dataIndex: 'status',
      width: 100,
      align: columnAlign.onCenter,
      render: (status: string) => (
        <Tooltip
          placement="top"
          title={translate({ id: `${status.toLowerCase()}.text` })}
        >
          <div>
            <Circle size={10} color={getStatusColor(status)} filled />
          </div>
        </Tooltip>
      ),
    },
    {
      csvData: {
        label: translate(messages.LABELS_REMARKS),
        key: 'remarks',
        renderCell: (deposit: Deposit) => deposit.remarks,
      },
      title: translate(messages.LABELS_REMARKS),
      key: 'remarks',
      width: 150,
      align: columnAlign.onCenter,
      render: (deposit: Deposit) => <MemberLabelRemarks deposit={deposit} />,
    },
    {
      ...(isRow &&
        adminAccount?.multiCurrencyEnabled && {
          csvData: {
            label: translate(messages.AMOUNT_BASE),
            key: 'amountInBaseCurrency',
            renderCell: (deposit: HexoPayDeposit) =>
              `${
                deposit.baseCurrency ? currencies[deposit.baseCurrency] : ''
              } ${renderNumeral(deposit.amountInBaseCurrency)}` ?? '-',
          },
          title: `Amount (${
            currencies[baseCurrency! as keyof typeof currencies]
          }) (Base)`,
          key: 'amountInBaseCurrency',
          dataIndex: 'amountInBaseCurrency',
          width: 150,
          align: columnAlign.onCenter,
          render: (amountInBaseCurrency: string) => (
            <DepositedAmount
              amount={amountInBaseCurrency}
              currency={baseCurrency!}
            />
          ),
        }),
    },
    {
      ...(isRow && {
        csvData: {
          label: translate(messages.CURRENCY),
          key: 'currency',
          renderCell: (deposit: HexoPayDeposit) => deposit.currency ?? '-',
        },
        title: translate(messages.CURRENCY),
        key: 'currency',
        dataIndex: 'currency',
        width: 150,
        align: columnAlign.onCenter,
        render: (currency: string) => <span>{currency ?? '-'}</span>,
      }),
    },
    {
      ...(isRow &&
        adminAccount.multiCurrencyEnabled && {
          csvData: {
            label: translate(messages.EXCHANGE_RATE),
            key: 'exchangeRate',
            renderCell: (deposit: HexoPayDeposit) =>
              deposit.exchangeRate ?? '-',
          },
          title: translate(messages.EXCHANGE_RATE),
          key: 'exchangeRate',
          dataIndex: 'exchangeRate',
          width: 150,
          align: columnAlign.onCenter,
          render: (exchangeRate: string) => <span>{exchangeRate ?? '-'}</span>,
        }),
    },
    {
      csvData: {
        label: translate(messages['request-datetime.text']),
        key: 'dateTimeCreated',
        renderCell: (deposit: Deposit) =>
          `${moment(deposit?.dateTimeCreated).format(DATE_FORMAT)} - ${moment(
            deposit?.dateTimeCreated
          ).format('HH:mm:ss')}`,
      },
      title: translate(messages['request-datetime.text']),
      align: columnAlign.onCenter,
      key: 'dateTimeCreated',
      dataIndex: 'dateTimeCreated',
      width: 200,
      render: (dateTimeCreated: string) => (
        <span>
          {moment(dateTimeCreated).format(DATE_FORMAT)}
          <br />
          {moment(dateTimeCreated).format('HH:mm:ss')}
        </span>
      ),
    },
    {
      csvData: {
        label: translate(messages['processing-time.text']),
        key: 'processingTime',
        renderCell: (deposit: object) => getProcessingTime(deposit),
      },
      title: translate(messages['processing-time.text']),
      key: 'processingTime',
      align: columnAlign.onCenter,
      width: 100,
      render: (deposit: any) => <span>{getProcessingTime(deposit)}</span>,
    },

    {
      title: translate(messagesLocal['actions.text']),
      key: 'actions',
      fixed: 'right',
      columnData: {
        name: 'actions',
      },
      align: columnAlign.onCenter,
      width: 100,
      hidden: true,
      render: (item: Deposit) => {
        const isProcessing = item.status === DepositStatus.Processing;
        const isPending = item.status === DepositStatus.Pending;

        const isDisabled = !isProcessing && !isPending;

        return (
          ALLOWED_PROCESS && (
            <Dropdown
              overlay={
                <Menu>
                  {ALLOWED_PROCESS && ALLOWED_APPROVE && (
                    <Menu.Item disabled={isDisabled}>
                      <ApproveDeposit
                        id={item.id}
                        status={item.status}
                        refetchVariables={refetchVariables}
                        disabled={isDisabled}
                      />
                    </Menu.Item>
                  )}
                  {ALLOWED_PROCESS && ALLOWED_REJECT && (
                    <Menu.Item disabled={isDisabled}>
                      <RejectDeposit
                        id={item.id}
                        remarks={item.remarks!}
                        refetchVariables={refetchVariables}
                        disabled={isDisabled}
                        isProcessing={isProcessing}
                      />
                    </Menu.Item>
                  )}
                  {ALLOWED_PROCESS && (
                    <Menu.Item disabled={!isProcessing}>
                      <StopProcessing
                        id={item.id}
                        refetchVariables={refetchVariables}
                        disabled={!isProcessing}
                      />
                    </Menu.Item>
                  )}
                </Menu>
              }
              placement="bottomRight"
            >
              <div>
                <EllipsisOutlined
                  className="cursor-pointer font-weight-bold"
                  style={{ fontSize: '25px' }}
                />
              </div>
            </Dropdown>
          )
        );
      },
    },
  ];

  useEffect(() => {
    setTableColumns(columns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { filterColumns } = useCustomColumnsV2(
    'deposit-requests-internal',
    columns as OriginalColumn[]
  );

  return (
    <div data-testid="deposit-table">
      {state.isViewDepositDetails &&
        (isActiveTab === undefined || isActiveTab) && (
          <DepositDetails
            disabled
            id={state.depositId}
            toggleModal={toggleView}
            refetchVariables={refetchVariables}
            refetchDepositRequests={refetchDepositRequests}
          />
        )}
      {ALLOWED_LIST && (
        <StyledTable
          data-testid="container"
          loading={loading}
          rowSelection={rowSelection}
          columns={filterColumns(columns as OriginalColumn[])}
          size="small"
          dataSource={data}
          pagination={false}
          scroll={{
            y: 'calc(100vh - 268px)',
            x: 1600,
          }}
          rowKey="serialCode"
        />
      )}
    </div>
  );
};

export default DepositsTable;
