import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Tabs, Tooltip } from 'antd';
import messages from 'messages';
import EditBalance from 'pages/components/NewMemberManagement/components/Content/components/Members/components/MembersTable/components/ChangeBalance';
import useTranslate from 'utils/useTranslate';
import ALL_PERMISSIONS from 'constants/permissions3';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import { usePermissions } from 'store/accountState';
import useMemberCurrency from 'utils/useMemberCurrency';
import isRow from 'utils/isRow';
import { StyledAmountTitle, StyledTabs } from '../../styles';
import { CashBalanceCards } from '../../utils';
// import { VIPBalance } from './components/VipBalance/VIPBalance';
// import { PromoBalance } from './components/PromoBalance/PromoBalance';
import { RebatesBalance } from './components/RebatesBalance/RebatesBalance';
import { useNewMemberDetailContext } from '../../../../context';
import PromoBalanceNext from './components/PromoBalanceNext/PromoBalanceNext';
import { NetDepositCardContent } from './components/NetDepositCardContent';

const { TabPane } = Tabs;

type Props = {
  member: any;
  refetch: () => void;
  balances: {
    totalDepositAmount: number;
    totalDepositCount: number;
    totalWithdrawalAmount: number;
    totalWithdrawalCount: number;
    winloss: number;
    netDeposits: {
      LIFETIME: number;
      _30_DAYS: number;
      _365_DAYS: number;
    };
    effectiveBet: number;
    betsCount: number;
    ggr: number;
    totalPromoAmount: number;
    ngr: number;
  };
};

export const AccountBalance = ({ member, refetch, balances }: Props) => {
  const { addCurrency, convertToBaseCurrency } = useMemberCurrency();
  const { role, permissions } = usePermissions();
  const { ALLOWED_EDIT_BALANCE } = collectPermissions(
    role,
    permissions,
    ['EDIT_BALANCE'],
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MANAGEMENT
  );
  const { refreshPage } = useNewMemberDetailContext();
  const translate = useTranslate();

  const winLossBalanceColor = (winlossBalance: number): string => {
    if (winlossBalance === 0) {
      return 'rgba(0,0,0,0.85)';
    }

    return winlossBalance > 0 ? '#52C41A' : '#dc3545';
  };

  const memberCurrency = member.currency;

  const CashBalCardContent = ({
    label,
    totalAmount,
    totalCount,
    withColor = false,
    withTotalCount = false,
  }: {
    label: string;
    totalAmount: number;
    totalCount?: number | null;
    withColor?: boolean;
    withTotalCount?: boolean;
  }) => {
    const color = withColor ? winLossBalanceColor(totalAmount) : '#000';

    return (
      <>
        {isRow ? (
          <>
            <h4 className="text-muted fs-14">{label}</h4>
            <h4 className="fs-14" style={{ marginBottom: '4px', color }}>
              <FormattedMessage id="total.text" defaultMessage="Total" />{' '}
              {addCurrency(memberCurrency, totalAmount)}
            </h4>
            <h4>({convertToBaseCurrency(totalAmount, member.exchangeRate)})</h4>
          </>
        ) : (
          <>
            <h4 className="text-muted fs-14">{label}</h4>
            <h4 className="fs-14" style={{ marginBottom: '4px', color }}>
              <FormattedMessage id="total.text" defaultMessage="Total" />{' '}
              {addCurrency(memberCurrency, totalAmount)}
            </h4>
          </>
        )}
        {withTotalCount && (
          <>
            <h4 className="text-muted fs-12 mb-1">Count</h4>
            <h3 className="fs-14 mb-0">
              {totalCount}{' '}
              <FormattedMessage
                id="member-detail.times.text"
                defaultMessage="times"
              />
            </h3>
          </>
        )}
      </>
    );
  };

  return (
    <StyledTabs
      tabBarExtraContent={
        ALLOWED_EDIT_BALANCE && (
          <EditBalance
            refetchFunction={() => {
              if (refreshPage) {
                refreshPage();
              }
              refetch();
            }}
            member={member}
            buttonType="primary"
          />
        )
      }
    >
      <TabPane
        tab={
          <div className="text-center">
            <StyledAmountTitle>
              <FormattedMessage
                id="member-detail.tab-cash.text"
                defaultMessage="Cash"
              />
            </StyledAmountTitle>
          </div>
        }
        key="1"
      >
        <div className="d-flex flex-wrap">
          <CashBalanceCards>
            <CashBalCardContent
              label="Deposits"
              totalAmount={balances.totalDepositAmount}
              totalCount={balances.totalDepositCount}
              withTotalCount
            />
          </CashBalanceCards>
          <CashBalanceCards>
            <CashBalCardContent
              label="Withdrawals"
              totalAmount={balances.totalWithdrawalAmount}
              totalCount={balances.totalWithdrawalCount}
              withTotalCount
            />
          </CashBalanceCards>
          {!isRow && (
            <CashBalanceCards style={{ width: 'max-content' }}>
              <NetDepositCardContent
                netDeposits={balances.netDeposits}
                label={'Net Deposits'}
                memberCurrency={memberCurrency}
              />
            </CashBalanceCards>
          )}
          <CashBalanceCards>
            <CashBalCardContent
              totalAmount={balances.winloss}
              totalCount={null}
              withColor
              label="Win/Loss"
            />
          </CashBalanceCards>
          <CashBalanceCards>
            <CashBalCardContent
              totalAmount={balances.ggr}
              totalCount={null}
              label="GGR"
            />
          </CashBalanceCards>

          <div className="d-flex justify-content-start">
            <CashBalanceCards>
              <CashBalCardContent
                label="Bets"
                totalAmount={balances.effectiveBet}
                totalCount={balances.betsCount}
                withTotalCount
              />
            </CashBalanceCards>
            {isRow && (
              <>
                <CashBalanceCards
                  style={{
                    marginLeft: '8px',
                  }}
                >
                  <CashBalCardContent
                    label="Promo"
                    totalAmount={balances.totalPromoAmount}
                    totalCount={null}
                  />
                </CashBalanceCards>
                <CashBalanceCards
                  style={{
                    marginLeft: '8px',
                  }}
                >
                  <CashBalCardContent
                    label="NGR"
                    totalAmount={balances.ngr}
                    totalCount={null}
                  />
                </CashBalanceCards>
              </>
            )}
          </div>
        </div>
      </TabPane>

      {/* <TabPane
        tab={
          <div className="text-center">
            <StyledAmountTitle>
              <FormattedMessage id="vip.text" defaultMessage="VIP" />
            </StyledAmountTitle>
          </div>
        }
        key="6"
      >
        <VIPBalance member={member} />
      </TabPane> */}

      <TabPane
        tab={
          <div className="text-center">
            <StyledAmountTitle>
              <FormattedMessage
                id="member-detail.tab-promo.text"
                defaultMessage="Promo"
              />
            </StyledAmountTitle>
          </div>
        }
        key="2"
      >
        <PromoBalanceNext member={member} />
      </TabPane>

      <TabPane
        tab={
          <div className="text-center">
            <StyledAmountTitle>
              <FormattedMessage id="rebates.text" defaultMessage="Rebates" />
            </StyledAmountTitle>
          </div>
        }
        key="3"
      >
        <RebatesBalance member={member} />
      </TabPane>

      <TabPane
        disabled
        tab={
          <Tooltip title={translate(messages.WORK_IN_PROGRESS)}>
            <div className="text-center">
              <StyledAmountTitle>
                <FormattedMessage
                  id="member-detail.tab-interest.text"
                  defaultMessage="Interest"
                />
              </StyledAmountTitle>
            </div>
          </Tooltip>
        }
        key="4"
      >
        <h1>Hello</h1>
      </TabPane>

      <TabPane
        disabled
        tab={
          <Tooltip title={translate(messages.WORK_IN_PROGRESS)}>
            <div className="text-center">
              <StyledAmountTitle>
                <FormattedMessage
                  id="member-detail.tab-affiliates.text"
                  defaultMessage="Affiliates"
                />
              </StyledAmountTitle>
            </div>
          </Tooltip>
        }
        key="5"
      >
        <h1>Hello</h1>
      </TabPane>
    </StyledTabs>
  );
};
