import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { Button, Checkbox, Input, message, Radio, Select, Spin } from 'antd';
import { useFormik } from 'formik';
import {
  CREATE_MEMBER_LEVEL,
  UPDATE_MEMBER_LEVEL,
} from 'graphql/mutations/memberLevel.mutation';
import { GET_MEMBERS_TOTAL_COUNT } from 'graphql/queries/member.query';
import { GET_MEMBER_LEVELS } from 'graphql/queries/memberLevel.query';
import { get } from 'lodash';
import messages from 'messages';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import coercedGet from 'utils/coercedGet';
import { memberLevelColors } from 'utils/memberLevelColors';
import removeNull from 'utils/removeNull';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import useTranslate from 'utils/useTranslate';
import isRow from 'utils/isRow';
import { useMemberLevel } from '../../context';
import { ColorBox } from './components/ColorBox';
import { CustomSelect } from './components/CustomSelect';
import { StyledForm } from './styles';
import { validationSchema } from './utils';

const { Item } = Form;

const MemberLevelForm = (props: any) => {
  const {
    actionType,
    initialValues,
    toggleModal,
    memberLevel,
    onSubmitForm,
  } = props;

  const translate = useTranslate();
  const { refetchParams } = useMemberLevel()!;
  const [colorSelected, setColorSelected] = useState<Record<
    string,
    any
  > | null>(null);
  const [numberOfMembers, setNumberOfMembers] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const client = useApolloClient();
  const actionTypes = {
    EDIT: 'EDIT',
    CREATE: 'CREATE',
  };
  const { context } = useOperatorHeader();
  const [mutate] = useMutation(
    actionType === actionTypes.CREATE
      ? CREATE_MEMBER_LEVEL
      : UPDATE_MEMBER_LEVEL
  );

  const onSubmit = async (input: Record<string, any>) => {
    setIsLoading(true);
    const response = await client.query({
      query: GET_MEMBER_LEVELS,
      variables: {
        filter: {
          name: {
            eq: input.name,
          },
        },
      },
    });
    const hasExistingName = !!coercedGet(
      response,
      'data.memberLevels.totalCount',
      0
    );

    if (hasExistingName && initialValues.name !== input.name) {
      message.warning(
        translate(messages['name-already-exists.text'], {
          name: input.name,
        })
      );
      setIsLoading(false);
      return;
    }

    const variables = {
      input: removeNull(input),
      ...(actionType === actionTypes.EDIT && { id: memberLevel.id }),
    };

    try {
      await mutate({
        variables,
        refetchQueries: [
          {
            query: GET_MEMBER_LEVELS,
            variables: refetchParams,
          },
        ],
        context,
      });
      message.success(
        actionType === actionTypes.CREATE
          ? translate(messages['member-marker-created.text'])
          : translate(messages['member-marker-updated.text'])
      );
      if (onSubmitForm) {
        onSubmitForm(refetchParams);
      }
      toggleModal();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (initialValues && !initialValues.color) {
      return;
    }
    const color = memberLevelColors.find(
      (memberLevelColor) => memberLevelColor.bgColor === initialValues.color
    );

    setColorSelected(color!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues && initialValues.color]);

  useEffect(() => {
    if (actionType === 'EDIT') {
      client
        .query({
          query: GET_MEMBERS_TOTAL_COUNT,
          variables: {
            filter: {
              memberLevel: {
                in: [memberLevel.id],
              },
            },
          },
        })
        .then(({ data }) => {
          const totalCount = get(data, 'members.totalCount', 0);
          setNumberOfMembers(totalCount);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
    validationSchema: validationSchema(translate),
  });

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    errors,
    touched,
    values,
  } = formik;

  useEffect(() => {
    if (values.default) {
      setFieldValue('status', 'ENABLED');
    }
  }, [setFieldValue, values.default]);

  return (
    <Spin spinning={isLoading}>
      <StyledForm onSubmit={handleSubmit}>
        <div className="p-4" id="sample-bro">
          <>
            <Item
              colon={false}
              label={
                <FormattedMessage
                  id="member-marker-name.text"
                  defaultMessage="Member Marker Name"
                />
              }
              labelCol={{ span: 7 }}
              wrapperCol={{ span: 13 }}
              validateStatus={errors.name && touched.name ? 'error' : ''}
              help={touched.name ? errors.name : null}
            >
              <Input
                name="name"
                onChange={handleChange}
                value={values.name || ''}
                placeholder={translate(
                  messages['member-marker.please-enter.text']
                )}
              />
            </Item>
            <Item
              colon={false}
              label={
                <FormattedMessage id="color.text" defaultMessage="Color" />
              }
              labelCol={{ span: 7 }}
              wrapperCol={{ span: 13 }}
              validateStatus={errors.color && touched.color ? 'error' : ''}
              help={touched.color ? errors.color : null}
            >
              <div>
                {/* May bug dito, pag di mo ginamit open property, hindi bumubukas ang Select sa Edit Member, di ko alam kung bakit */}
                {/* Now pag wala ung OPEN property, medyo mas mabilis sya maopen */}
                <CustomSelect
                  colorSelected={colorSelected}
                  getPopupContainer={() =>
                    document.getElementById('sample-bro')
                  }
                  mode="tags"
                  value="all"
                  dropdownRender={() => (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                    <div className="p-1" style={{ padding: '4px 8px' }}>
                      <div
                        className="d-flex flex-column flex-wrap"
                        style={{
                          justifyContent: 'space-evenly',
                          height: '120px',
                        }}
                      >
                        {memberLevelColors.map((color) => (
                          <ColorBox
                            onClick={() => {
                              setFieldValue('color', color.bgColor);
                              setColorSelected(color);
                            }}
                            key={color.boxColor}
                            checked={values.color === color.bgColor}
                            colorBg={color.boxColor}
                            colorBorder={color.boxColor}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                >
                  <Select.Option
                    value="all"
                    key={1}
                    style={{ display: 'none' }}
                  >
                    <FormattedMessage
                      id="please-select.text"
                      defaultMessage="Please select"
                    />
                  </Select.Option>
                </CustomSelect>
              </div>
            </Item>
            <Item
              colon={false}
              label={
                <FormattedMessage id="default.text" defaultMessage="Default" />
              }
              labelCol={{ span: 7 }}
              wrapperCol={{ span: 13 }}
            >
              <Radio
                disabled={initialValues.default}
                checked={values.default}
                onChange={() => setFieldValue('default', true)}
              >
                <FormattedMessage id="yes.text" defaultMessage="Yes" />
              </Radio>
              <Radio
                disabled={initialValues.default}
                checked={!values.default}
                onChange={() => setFieldValue('default', false)}
              >
                <FormattedMessage id="no.text" defaultMessage="No" />
              </Radio>

              <div style={{ marginTop: '-16px' }}>
                <small>
                  {translate(
                    messages['member-marker.only-one-member-marker-is-allowed']
                  )}
                </small>
              </div>
            </Item>
            <Item
              colon={false}
              label={
                <FormattedMessage id="status.text" defaultMessage="Status" />
              }
              labelCol={{ span: 7 }}
              wrapperCol={{ span: 13 }}
              help={
                numberOfMembers > 0 &&
                translate(
                  messages[
                    'member-marker-management.deactivate-if-zero-members.text'
                  ]
                )
              }
            >
              <Radio.Group
                value={values.status}
                name="status"
                onChange={handleChange}
                disabled={numberOfMembers > 0 || values.default}
              >
                <Radio value="ENABLED">
                  <FormattedMessage
                    id="enabled.text"
                    defaultMessage="Enabled"
                  />
                </Radio>
                <Radio value="DISABLED">
                  <FormattedMessage
                    id="disabled.text"
                    defaultMessage="Disabled"
                  />
                </Radio>
              </Radio.Group>
            </Item>
            {!isRow && (
              <Item
                colon={false}
                label="Set for Brand Linkage"
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 13 }}
              >
                <Checkbox
                  checked={values.brandLinkage}
                  name="brandLinkage"
                  onChange={handleChange}
                />
              </Item>
            )}
          </>
        </div>
        <div className="bt-1 p-4 text-right">
          <Button className="mr-2" onClick={toggleModal}>
            <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
          </Button>
          <Button type="primary" htmlType="submit">
            <FormattedMessage
              id="save-changes.text"
              defaultMessage="Save changes"
            />
          </Button>
        </div>
      </StyledForm>
    </Spin>
  );
};

export default MemberLevelForm;
