import React, { useState } from 'react';
import gql from 'graphql-tag';
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Space,
  Switch,
  Typography,
} from 'antd';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { yupResolver } from '@hookform/resolvers';
import {
  Config,
  MutationUpdateConfigArgs,
} from '../../../../types/graphqlTypes';
import { useOperatorHeader } from '../../../../utils/useOperatorHeader';
import { UPDATE_CONFIG } from '../../../../graphql/mutations/updateConfig.mutation';
import { FinancialLightCheckType, GbgNetConfigSchema } from './validator';

const GBG_LIGHT_CHECK_CONFIG = gql`
  query NetDepositGBGConfig {
    config {
      enableGBGAffordabilityCheck
      gbgNetDepositThreshold
    }
  }
`;

const { Text } = Typography;

export const FinancialLightCheckGBG = () => {
  const { context } = useOperatorHeader();
  const [visible, setVisible] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [thresholdRequired, setThresholdRequired] = useState(true);

  const { control, handleSubmit, setValue, reset, errors } = useForm<
    FinancialLightCheckType
  >({
    defaultValues: {
      enableGBGAffordabilityCheck: true,
      gbgNetDepositThreshold: 0,
      gbgTrigger: 'NET_DEPOSIT',
    },
    resolver: yupResolver(GbgNetConfigSchema),
    mode: 'all',
  });

  const [loadGBGConfig, { loading }] = useLazyQuery<{ config: Config }>(
    GBG_LIGHT_CHECK_CONFIG,
    {
      notifyOnNetworkStatusChange: false,
      fetchPolicy: 'network-only',
      displayName: 'NetDepositGBGConfig',
      onCompleted: ({ config }) => {
        reset({
          enableGBGAffordabilityCheck: config.enableGBGAffordabilityCheck!,
          gbgNetDepositThreshold: config.gbgNetDepositThreshold!,
          gbgTrigger: 'NET_DEPOSIT',
        });
        setEnabled(config.enableGBGAffordabilityCheck!);
        setThresholdRequired(config.enableGBGAffordabilityCheck!);
      },
    }
  );

  const [updateGBGConfig, { loading: mutationLoading }] = useMutation<
    { updateConfig: boolean },
    MutationUpdateConfigArgs
  >(UPDATE_CONFIG, {
    context,
  });

  const handleShow = () => {
    setVisible(true);
    loadGBGConfig();
  };

  const handleModalClose = () => {
    setVisible(false);
  };

  const handleEnableGBGNetConfig = (checked: boolean) => {
    setEnabled(checked);
    setThresholdRequired(checked);
    setValue('enableGBGAffordabilityCheck', checked);
  };

  const onSubmit: SubmitHandler<FinancialLightCheckType> = async (values) => {
    try {
      await updateGBGConfig({
        variables: {
          input: {
            enableGBGAffordabilityCheck: values.enableGBGAffordabilityCheck,
            gbgNetDepositThreshold: +values.gbgNetDepositThreshold!,
          },
        },
      });
      message.success('GBG Configuration updated successfully');
    } catch (error) {
      if (error) message.error('Something went wrong, please try again.');
    } finally {
      handleModalClose();
    }
  };

  return (
    <div>
      <h3>Manage GBG (Financial Light-Check)</h3>
      <Button onClick={handleShow}>Configure GBG Checks</Button>

      <Modal
        visible={visible}
        destroyOnClose
        title="Manage"
        closable
        onCancel={handleModalClose}
        centered
        okButtonProps={{ loading: loading || mutationLoading }}
        cancelButtonProps={{ loading: loading || mutationLoading }}
        onOk={handleSubmit(onSubmit)}
      >
        <div className="d-flex flex-column">
          <div className="d-flex w-50 justify-content-around mb-2">
            <Text strong>Enable GBG: </Text>
            <Controller
              control={control}
              name="enableGBGAffordabilityCheck"
              render={({ value }) => (
                <Switch
                  onChange={handleEnableGBGNetConfig}
                  checked={value}
                  checkedChildren="On"
                  unCheckedChildren="Off"
                />
              )}
            />
          </div>
          {enabled && (
            <>
              <Text strong className="ml-5 mb-2 font-italic">
                GBG Light-touch financial vulnerability check to Trigger at:
              </Text>
              <div className="d-flex flex-column  align-items-center">
                <Controller
                  control={control}
                  name="gbgTrigger"
                  render={({ value }) => (
                    <>
                      <Radio.Group
                        onChange={(e) => setValue('gbgTrigger', e.target.value)}
                        value={value}
                      >
                        <Space direction="vertical">
                          <Radio value="NET_DEPOSIT">Net Deposit</Radio>
                        </Space>
                      </Radio.Group>
                    </>
                  )}
                />
              </div>
              <Text strong className="ml-5 mb-2 font-italic mt-3">
                GBG Threshold Amount:
              </Text>
              <div className="d-flex ml-5">
                <Form.Item
                  validateStatus={errors.gbgNetDepositThreshold && 'error'}
                  help={errors.gbgNetDepositThreshold?.message}
                >
                  <Controller
                    control={control}
                    name="gbgNetDepositThreshold"
                    render={({ value, onChange, onBlur }) => (
                      <Input
                        value={value}
                        onBlur={onBlur}
                        onChange={(e) => {
                          if (e.target.value === '' && enabled) {
                            setThresholdRequired(true);
                          }
                          onChange(e);
                        }}
                        placeholder="Enter an amount"
                        type="number"
                        min={0}
                        disabled={!thresholdRequired}
                      />
                    )}
                  />
                </Form.Item>
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};
