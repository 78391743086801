import React, { useState, useEffect } from 'react';

import { message, Select, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import {
  CheckCircleFilled,
  CloseCircleFilled,
  EditOutlined,
} from '@ant-design/icons';

import { useAvailableCurrencies } from 'hooks/useAvailableCurrencies';
import isRow from 'utils/isRow';
import styled from 'styled-components';

const StyledDiv = styled.div`
  display: flex;
  align-items: center;

  .anticon.anticon-edit.text-info {
    cursor: not-allowed;
    svg {
      path {
        color: gray;
      }
    }
  }
`;

type MemberCurrencyProps = {
  currency: string;
  onUpdateCurrency: (currency: string, cb: Function) => void;
};

const UKCurrencyMapping = {
  GBP: 'Pound £',
  USD: 'USD $',
  EUR: 'Euro €',
};

const MemberCurrency: React.FC<MemberCurrencyProps> = ({
  currency,
  onUpdateCurrency,
}) => {
  const [isEditCurrency, setIsEditCurrency] = useState(false);
  const [currencyValue, setCurrencyValue] = useState<string | null | undefined>(
    null
  );
  const {
    loadAvailableCurrencies,
    availableCurrencies,
  } = useAvailableCurrencies();

  useEffect(() => {
    loadAvailableCurrencies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ROWCurrencyMapping = availableCurrencies.reduce((acc, obj) => {
    acc[obj.code] = `${obj.name} ${obj.symbol}`;
    return acc;
  }, []);

  return (
    <>
      <Typography.Text>
        <FormattedMessage
          id="MEMBER_CURRENCY"
          defaultMessage="Member Currency"
        />
      </Typography.Text>
      <Typography.Text>
        {isEditCurrency && (
          <div className="d-flex align-items-center">
            <Select
              className="mr-2"
              style={{ width: '100px' }}
              value={currencyValue || undefined}
              onChange={(e: string) => setCurrencyValue(e)}
            >
              <Select.Option value="" disabled>
                Select Currency
              </Select.Option>
              {Object.keys(isRow ? ROWCurrencyMapping : UKCurrencyMapping).map(
                (currencyKey) => (
                  <Select.Option value={currencyKey}>
                    {isRow
                      ? ROWCurrencyMapping[
                          currencyKey as keyof typeof ROWCurrencyMapping
                        ]
                      : UKCurrencyMapping[
                          currencyKey as keyof typeof UKCurrencyMapping
                        ]}
                  </Select.Option>
                )
              )}
            </Select>

            <CloseCircleFilled
              onClick={() => setIsEditCurrency(false)}
              className="text-danger mr-2 cursor-pointer"
            />
            <CheckCircleFilled
              onClick={() =>
                onUpdateCurrency(currencyValue!, () => {
                  setIsEditCurrency(false);
                  message.success('Currency updated');
                })
              }
              className="text-success cursor-pointer"
            />
          </div>
        )}
        {!isEditCurrency && (
          <StyledDiv>
            <Typography.Text className="mr-2">
              {isRow
                ? ROWCurrencyMapping[
                    currency as keyof typeof ROWCurrencyMapping
                  ]
                : UKCurrencyMapping[
                    currency as keyof typeof UKCurrencyMapping
                  ] || '-'}
            </Typography.Text>
            <EditOutlined
              // onClick={() => setIsEditCurrency(true)}
              className="text-info"
            />
          </StyledDiv>
        )}
      </Typography.Text>
    </>
  );
};

export default MemberCurrency;
